import { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/_global.scss";
import { useFormik } from "formik";
import SubmitForm from "./SubmitForm";
import * as Yup from "yup";
import { InputAdornment, Icon } from "@material-ui/core";
import { Person, Lock } from "@material-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import usersService from "../../services/UsersService";
import AuthContext from "../../context/auth";
import axios from "axios";
import swal from "sweetalert";
import { Container, Divider, IconButton, OutlinedInput } from "@mui/material";
import { TextField, Button, Typography, makeStyles } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputLabel } from "@mui/material";

const Login = () => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { signed, markAsSigned, markAsNotSigned, storeName, storeRole } =
    useContext(AuthContext);
  const [ totalStudents, setTotalStudents ] = useState<number>(0);
  const navigate = useNavigate();

  const [type, setType] = useState<"password" | "text">("password");

  const location = useLocation();
  const [path, setPath] = useState(location.state?.path);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("E-mail inválido").required("E-mail inválido"),
      password: Yup.string()
        .min(6, "Senha inválida")
        .required("Senha inválida"),
    }),

    onSubmit: (values) => {
      login(values);
    },
  });

  const login = (values: any) => {
    let data = {
      username: values.email,
      password: values.password,
    };

    usersService
      .login(data)
      .then((response) => {
        setLoading(false);
        // navigation.reset({
        //   index: 0,
        //   routes: [{name: "cursos"}]
        // })
        markAsSigned();
        storeName(response.data.name);
        storeRole(response.data.role);

        if (path) {
          navigate(path);
        } else {
          if (response.data.role === "student") {
            navigate("/cursos");            
          } else if (response.data.role === "company") { 
            console.log(response.data.plan)          
            if (!response.data.plan) {
              // Redirecionar a empresa para /consumedCredits
              navigate("/consumedCredits");              
            } else {
              // A empresa tem um plano ativo, redirecionar para /seek-talent
              navigate("/seek-talent");
            }
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        swal({
          title: "Erro",
          text: 'A senha ou e-mail está incorreto. Se não se lembra da sua senha, redefina clicando em "esqueci minha senha"',
          icon: "error",
        });
      });
  };

  const signUp = () => {
    navigate("/cadastro");
  };

  const loadTotalStudents = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/students/count`)
    .then((response) => {
      setTotalStudents(response.data.countUsers - 1)
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const signUpcompany = () => {
    navigate("/cadastroEmpresas", {
      state: {
        path: location.pathname,
      },
    });
  };

  useEffect(() => {
    loadTotalStudents()
  }, []);

  const signUpSponser = () => {
    navigate("/cadastroPadrinho");
  };

  return (
    <div className="container1 bg">
      <div
        className={
          !isSubmitSuccess ? "signin signin_wrapper" : "signin signin_success"
        }
      >
        {isSubmitSuccess ? (
          <SubmitForm />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src="assets/images/logo_IV.png"
                alt="fatecoins"
                style={{ marginBottom: "20px", marginTop: "20px" }}
              />
            </div>

            <div
              style={{
                textAlign: "center",
                marginBottom: "40px"
              }}
            >
              <p style={{margin: 0}}><strong>É Grátis</strong> e <strong>é bom</strong> para quem quer emprego! </p>
              { totalStudents > 0 && 
                <p style={{fontSize: "1.2em"}}>Já são <strong style={{color: "#413c69"}}>+ de {totalStudents} alunos</strong> na plataforma</p>
              }
            </div>

            <>
              
              <input className="login_talent"
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                required
                //autocomplete="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {/* <OutlinedInput 
              style={{marginBottom:'15px'}}
                //id="email"
                required
                placeholder="Email"
                //margin="normal"
                label="Email"
                fullWidth
                name="email"
                type="text"                  
                // autoFocus
                autoComplete="email"
                //className="textField"
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="end">
                //       <IconButton
                //         aria-label="toggle password visibility"
                //         edge="end"
                //       ></IconButton>
                //     </InputAdornment>
                //   ),
                // }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              /> */}
              {formik.touched.email && formik.errors.email ? (
                <div className="error_msg">{formik.errors.email}</div>
              ) : null}
              <OutlinedInput
                style={{ margin: "auto" }}
                required
                name={"password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        if (type === "text") {
                          setType("password");
                        } else {
                          setType("text");
                        }
                      }}
                      edge="end"
                    >
                      {type === "text" ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                type={type}
                placeholder="Senha"
                className="textField"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="error_msg">{formik.errors.password}</div>
              ) : null}
              <br /> <br />
              {!isLoading && (
                <button className="btn_primary full" type="submit">
                  Entrar
                </button>
              )}
              <div
                className="forget-password"
                style={{ textAlign: "center", color: "#2563EB" }}
              >
                <a href="/passwordReset">Esqueceu a senha?</a>
              </div>
              <Typography
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: "1", paddingRight: "10px" }}>
                  <Divider
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                  />
                </div>
                <div className="p_normal">Ainda não tem conta?</div>
                <div style={{ flex: "1", paddingLeft: "10px" }}>
                  <Divider
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                  />
                </div>
              </Typography>
              <br />
              <div className="central-button">
                <button className="btn_tertiary" onClick={signUp}>
                  Cadastre-se como Aluno
                </button>

                <div className="company">
                  <button className="btn_tertiary" onClick={signUpcompany}>
                    Cadastre-se como Empresa
                  </button>
                </div>
              </div>
              <br />
              {/* <div className="company">
                <Button
                  className="button-action"
                  variant="contained"
                  onClick={signUpSponser}
                >
                  Cadastro de Patrocinador
                </Button>
              </div> */}
            </>
            
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
