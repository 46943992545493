import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { useFormik, FormikHelpers as FormikActions } from "formik";
import * as Yup from "yup";
import usersService from "../../services/UsersService";
import swal from "sweetalert";
import AuthContext from "../../context/auth";
import "./modal.scss";
import studentsService from "../../services/StudentsService";
import { Table } from "react-bootstrap";
import ISkills from "../../interfaces/ISkills";
import { IStudent } from "../../interfaces/IStudent";
import IEmployees from "../../interfaces/IEmployees";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(0),
  },
}));

export interface Modal {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function CustomizedDialogs({
  student,
  loadSkills
}: {
  student: IEmployees | undefined,
  loadSkills(studentId: number): Promise<any>
}) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = useState(false);
  const [skills, setSkills] = useState<ISkills[]>([])

  

  const handleClickOpen = () => {
    if (student){
      loadSkills(student.id).then((result) => {
        setSkills(result.data)
        setOpen(true);
      }).catch((error) => {
        alert("Não foi possível carregar as skills")
      })
      
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div className="habilidades">
      <button
        className="btn_secondary "
        //variant="outlined"
        onClick={handleClickOpen}
      >
        Habilidades
      </button>
      </div>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"xl"}
      >
        <DialogContent >
          <div className="container-title">
            <h2 className='title-modal'>Habilidades</h2>
            <div style={{marginBottom:'10px'}}>
            <button
              className="btn_tertiary"style={{ margin:'0 auto'}}
              //variant="outlined"
              onClick={() => setOpen(false)}
            >
              Fechar
            </button>
            </div>
            
          </div>
          
          <Box>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th>Tipo</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {skills && skills?.length > 0 && (
                  skills.map((skill: ISkills) =>
                    <tr>
                      <td>{skill.name}</td>
                      <td>{skill.description}</td>
                      <td>{skill.type}</td>
                      <td>{skill.total}</td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions>
          {/* <Button
            className="button-confirm previous-lesson"
            variant="contained"
            autoFocus
            onClick={handleClose}
          >
            Salvar
          </Button> */}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
