import { Button } from "react-bootstrap";
import "./index.scss";
import { useNavigate } from "react-router-dom";

export default function Terms() {
  const navigate = useNavigate();

  const register = () => {
    navigate("/cadastro");
  };

  return (
    <div className="container_terms">
      <div className="logo_terms">
        <img
          src="./logo_1.png"
          alt="Círculo amarelo com letra F em tom de amarelo no meio"
        />
      </div>
      <h2 className="title">Termos de Uso – APLICAÇÕES DA FATECOINS</h2>
      <p>
        Os “sites, aplicativos, plataformas e futuras aplicações”, da FATECOINS,
        são denominados de “APLICAÇÕES DA FATECOINS”.
      </p>
      <p>
        Os serviços prestados pela{" "}
        <strong>FATECOINS INTERMEDIAÇÃO E AGENCIAMENTO DE SERVIÇOS LTDA</strong>{" "}
        estão sujeitos aos termos e condições contidos neste documento. Ao se
        utilizar de nossos serviços, você estará sujeito às regras, diretrizes,
        políticas, termos e condições, incluindo mas não limitado a Política de
        Privacidade. Nos reservamos ao direito de alterar as{" "}
        <strong>APLICAÇÕES DA FATECOINS</strong> ou esses termos e condições a
        qualquer momento.
      </p>
      <p>
        <strong>
          Acessar, navegar ou usar as APLICAÇÕES DA FATECOINS indica que você,
          através da sua conta de pessoa física ou jurídica, concorda com todos
          os termos e condições deste acordo. Por favor, leia atentamente este
          acordo antes de prosseguir.
        </strong>
      </p>
      <div></div>
      <ol className="list">
        <li>
          <a href="#one">Noções preliminares</a>
        </li>
        <li>
          <a href="#two">Uso das APLICAÇÕES DA FATECOINS</a>
        </li>
        <li>
          <a href="#three">Regras Gerais dos Serviços</a>
        </li>
        <li>
          <a href="#four">
            FUNDO FATECOINS PARA FOMENTO DE PROJETOS ACADÊMICOS
          </a>
        </li>
        <li>
          <a href="#five">Opiniões e Comentários</a>
        </li>
        <li>
          <a href="#six">Direitos Autorais</a>
        </li>
        <li>
          <a href="#seven">Política de Violação de Propriedade Intelectual</a>
        </li>
        <li>
          <a href="#eight">
            Encerramento das atividades com exclusão das APLICAÇÕES DA FATECOINS
          </a>
        </li>
        <li>
          <a href="#nine">Isenção e Limitação de Responsabilidade</a>
        </li>
        <li>
          <a href="#ten">
            Aceitação de Pedidos de Compra de cupons e/ou moedas Fatecoins
          </a>
        </li>
        <li>
          <a href="#eleven">Erros Tipográficos</a>
        </li>
        <li>
          <a href="#twelve">Links</a>
        </li>
        <li>
          <a href="#thirteen">Solução de litígios</a>
        </li>
      </ol>
      <h3 id="one">1. Noções preliminares</h3>
      <p>
        <strong>FATECOINS INTERMEDIAÇÃO E AGENCIAMENTO DE SERVIÇOS LTDA</strong>{" "}
        é uma empresa cujo objetivo é destinar a receita líquida para projetos
        acadêmicos e/ou de pesquisa, após abatidos os custos operacionais, de
        capitalização e reserva. Assim, o lucro líquido formará um fundo que
        será objeto de doação periódica aos projetos inseridos no jogo.
      </p>
      <p>
        O <strong>FATECOINS</strong> se caracteriza por um jogo, na modalidade{" "}
        <strong>mesa de negócios</strong>, onde{" "}
        <strong>ALUNOS PROJETISTAS</strong> de
        <strong>INSTITUIÇÕES DE ENSINO</strong> credenciadas divulgam seus
        projetos acadêmicos e/ou de pesquisa, preferencialmente, mas não
        limitados a atividades de empreendedorismo e novos negócios, nas{" "}
        <strong>APLICAÇÕES DA FATECOINS</strong>. Enquanto desenvolvem o projeto
        acadêmico e/ou de pesquisa, pelo prazo de 01 (um) período letivo
        (normalmente, um semestre), outros alunos, ex-alunos, professores,
        funcionários e colaboradores da instituição de ensino à qual o projeto
        está vinculado poderão <strong>investir</strong> suas moedas virtuais,
        igualmente denominada de <strong>FATECOINS</strong>, nos projetos que
        entendem mais promissores e/ou trocá-las por cupons de desconto em
        estabelecimentos comerciais parceiros, que fornecerão ofertas
        promocionais aos usuários cadastrados nas{" "}
        <strong>APLICAÇÕES DA FATECOINS</strong>.
      </p>
      <p>
        Paralelamente,{" "}
        <strong>FATECOINS INTERMEDIAÇÃO E AGENCIAMENTO DE SERVIÇOS LTDA</strong>
        comercializará moedas virtuais (FATECOINS) e cupons de desconto para
        elegibilidade em cupons de desconto fornecidas por parceiros comerciais,
        criando um fundo financeiro adicional para investir em projetos
        acadêmicos e/ou de pesquisa promissores, que sejam rankeados com base
        nos critérios estabelecidos neste termo e nos contratos adicionais
        rubricados por cada participante.
      </p>
      <h3 id="two">2. Uso das APLICAÇÕES DA FATECOINS</h3>
      <p>
        O usuário declara que é maior de 18 (dezoito) anos ou que realiza seu
        cadastro sob a supervisão de um adulto. A <strong>FATECOINS</strong> lhe
        concede uma licença limitada, revogável, intransferível e não-exclusiva
        para acessar e usar as <strong>APLICAÇÕES DA FATECOINS</strong> para os
        propósitos de cadastro de projetos acadêmicos e/ou de pesquisa,
        aquisição de moedas virtuais <strong>FATECOINS</strong>, aquisição de
        cupons de desconto e participação em promoções promovidas nas{" "}
        <strong>APLICAÇÕES DA FATECOINS</strong>, bem como para participação em
        nosso projeto de fomento educacional, que visa avaliação e financiamento
        de projetos de pesquisa acadêmicos, com foco em inovação, pesquisa,
        desenvolvimento e empreendedorismo.
      </p>
      <p>
        Qualquer violação deste Acordo pode resultar na revogação imediata da
        licença concedida neste parágrafo, sem aviso prévio.
      </p>
      <p>
        É proibido reproduzir, exibir, vender, arrendar, transmitir, criar
        trabalhos derivados, traduzir, modificar, executar engenharia reversa,
        desmontar, descompilar ou de alguma forma explorar as APLICAÇÕES DA
        FATECOINS ou qualquer porção dela, inclusive mas não se limitando aos
        conteúdos de terceiros que sejam publicitados, a não ser que
        expressamente permitido pela{" "}
        <strong>FATECOINS INTERMEDIAÇÃO E AGENCIAMENTO DE SERVIÇOS LTDA</strong>{" "}
        por escrito.
      </p>
      <p>
        É vedado o uso comercial de qualquer informação fornecida nas{" "}
        <strong>APLICAÇÕES DA FATECOINS</strong>, exceto com a permissão
        antecipada do titular da informação ou conteúdo de mídia.
      </p>
      <p>
        É vedado transferir, distribuir ou publicar através das{" "}
        <strong>APLICAÇÕES DA FATECOINS</strong> qualquer conteúdo, informação
        ou outros materiais que (a) violem ou infrinjam direitos autorais,
        patentes, marcas registradas, marcas de serviço, segredos comerciais ou
        outros direitos de propriedade de qualquer pessoa; (b) seja calunioso,
        ameaçador, difamatório, obsceno, indecente, pornográfico,
        discriminatório ou que possa atingir cível ou criminalmente a qualquer
        pessoa, de acordo com as leis brasileiras; (c) contenha algum bug,
        vírus, worms, portas de acesso (trap doors), cavalos de Tróia (Trojan
        horses) ou outro código prejudicial.
      </p>
      <p>
        Você terá um login e uma senha para acesso e identificação de conta, que
        lhe permitirá a utilização de certas partes das
        <strong>APLICAÇÕES DA FATECOINS</strong>. Cada vez que utilizar esta
        senha de identificação, considera-se que está autorizado a acessar e
        usar as <strong>APLICAÇÕES DA FATECOINS</strong> de acordo com os termos
        e condições deste Acordo.
      </p>
      <p>
        VOCÊ É EXCLUSIVAMENTE RESPONSÁVEL POR TODO O ACESSO E USO DAS APLICAÇÕES
        DA FATECOINS POR QUALQUER PESSOA UTILIZANDO A SENHA E IDENTIFICAÇÃO
        ORIGINALMENTE DESIGNADAS PARA VOCÊ, INCLUSO E SEM LIMITAÇÕES, TODA A
        COMUNICAÇÃO, TRANSMISSÃO E OBRIGAÇÕES DECORRENTES DO ACESSO OU USO.
      </p>
      <p>
        Cabe ao usuário, exclusivamente, proteger a segurança e
        confidencialidade da senha e identificação cadastrada e/ou designada. O
        usuário deve comunicar a{" "}
        <strong>FATECOINS INTERMEDIAÇÃO E AGENCIAMENTO DE SERVIÇOS LTDA</strong>{" "}
        imediatamente caso identifique o uso não autorizado de sua senha e
        identificação ou qualquer outra violação e tentativa de violação da
        segurança das <strong>APLICAÇÕES DA FATECOINS</strong>.
      </p>
      <h3 id="three">3. Regras Gerais dos Serviços</h3>
      <ol type="A" className="list2">
        <li className="typeA">
          <strong>Sobre a participação nas APLICAÇÕES DA FATECOINS</strong>
          <p>
            Ao se cadastrar nas APLICAÇÕES DA FATECOINS, cada professor, aluno,
            ex-aluno e colaborador receberá gratuitamente 500 (quinhentas)
            moedas virtuais FATECOINS. As moedas valem para as APLICAÇÕES DA
            FATECOINS, independentemente da instituição de ensino.
          </p>
          <p>
            Além das moedas virtuais recebidas gratuitamente, o cadastrado
            poderá adquirir cupons de desconto para ser elegível em promoções
            ofertadas por empresas parceiras, cujo valor será convertido em
            moedas virtuais FATECOINS, que também poderão ser adquiridas, nos
            mesmos moldes.
          </p>
          <p>
            A aquisição de cupons de desconto e/ou FATECOINS será em Reais
            (BRL), mediante cartão de crédito ou outra modalidade disponível no
            momento da compra. As moedas virtuais FATECOINS não terão nenhum
            indexador e não há limites para sua geração, uma vez que não se
            tratam de criptomoedas.
          </p>
          <p>
            A instituição de ensino cadastrada não receberá a quota de 500
            (quinhentas) moedas virtuais FATECOINS oferecidas aos demais
            usuários, porém, terá direito a uma quantidade de FATECOINS,
            proporcional ao número de alunos matriculados, que poderá utilizar
            para doar / atribuir para jogadores, conforme critérios que poderá
            fixar livremente, dentro do período letivo. Caso não utilize as
            moedas a sua disposição, a quota será zerada para o período letivo
            subsequente.
          </p>
          <p>
            Com as moedas virtuais disponíveis em sua conta de usuário os
            cadastrados poderão: <strong>A-</strong> investir nos projetos da
            instituição onde o cadastrado está vinculado, ainda que atue em mais
            de uma instituição (professores de mais de uma instituição de ensino
            cadastrada, por exemplo), ou <strong>B-</strong>
            Trocar os FATECOINS por cupons de descontos com empresas parceiras.
          </p>
        </li>
        <li>
          <strong>
            INVESTIMENTO DE MOEDAS VIRTUAIS FATECOINS EM PROJETOS DE PESQUISA
          </strong>
          <p>
            O <strong>ALUNO PROJETISTA</strong> e o{" "}
            <strong>COMERCIANTE PARCEIRO</strong> cadastrado nas{" "}
            <strong>APLICAÇÕES DA FATECOINS</strong> poderão investir, até um
            limite de FATECOINS por projeto, sendo pré-estabelecido pela empresa
            FATECOINS , nos projetos acadêmicos e/ou de pesquisa que estiverem
            disponíveis para si. Não existirão requisitos preestabelecidos para
            a seleção do investimento, devendo o investidor buscar projetos que
            entenda como promissores. O <strong>ALUNO PROJETISTA NÃO</strong>{" "}
            poderá investir no seu próprio projeto. Os{" "}
            <strong>COMERCIANTES PARCEIROS</strong> com SELO EMPREENDEDOR OURO
            ou DIAMANTE também poderão investir
            <strong>FATECOINS</strong> nos projetos.
          </p>
          <p>
            O usuário declara que é maior de 18 (dezoito) anos ou que realiza
            seu cadastro sob a supervisão de um adulto. A{" "}
            <strong>FATECOINS</strong> lhe concede uma licença limitada,
            revogável, intransferível e não-exclusiva para acessar e usar as{" "}
            <strong>APLICAÇÕES DA FATECOINS</strong> para os propósitos de
            cadastro de projetos acadêmicos e/ou de pesquisa, aquisição de
            moedas virtuais <strong>FATECOINS</strong>, aquisição de cupons de
            desconto e participação em promoções promovidas nas{" "}
            <strong>APLICAÇÕES DA FATECOINS</strong>, bem como para participação
            em nosso projeto de fomento educacional, que visa avaliação e
            financiamento de projetos de pesquisa acadêmicos, com foco em
            inovação, pesquisa, desenvolvimento e empreendedorismo.
          </p>
          <p>
            Cada projeto acadêmico e/ou de pesquisa será avaliado por uma banca
            de docentes, podendo ser composta por um ou mais docentes e/ou
            pessoas especializadas que sejam convidadas pela instituição,
            estabelecida no âmbito da respectiva instituição de ensino, a qual
            será formada sem qualquer interferência de FATECOINS INTERMEDIAÇÃO E
            AGENCIAMENTO DE SERVIÇOS LTDA , tanto na escolha dos integrantes
            quanto nos critérios que serão utilizados para a avaliação, que
            atribuirá uma nota de execução, de 0 (zero) a 10 (dez), podendo ser
            fracionada. Os docentes da banca <strong>NÃO</strong> poderão
            investir nos projetos que eles avaliam.
          </p>
          <p>
            Ao final de cada período letivo, é sugerido que a INSTITUIÇÃO DE
            ENSINO realize a premiação dos melhores projetos, escolhidos por
            intermédio de um ranking, que será baseado, principalmente, na nota
            de execução dada pela banca de docentes, somada à quantidade de
            moedas virtuais FATECOINS investidas em cada projeto acadêmico e/ou
            de pesquisa. Será considerado reprovado o projeto que obtiver nota
            inferior a 6 (seis). Aqueles que investiram num projeto que foi
            reprovado, perderão os FATECOINS que foram investidos no respectivo
            projeto.
          </p>
          <p>
            Caso o projeto acadêmico e/ou de pesquisa não tenha sido concluído
            ao final do período letivo, poderá ser avaliado, pela banca de
            docentes, pelo estágio em que se encontra. Logo, receberá eventual
            aporte de capital equivalente à avaliação daquele momento e, se
            precisar prosseguir para implementação em outro semestre, terá seus
            FATECOINS investidos e investidores zerados.
          </p>
          <p>
            O rendimento para o usuário que investir em projetos acadêmicos e/ou
            de pesquisa, levará em conta as seguintes variáveis: valor
            investido, quantos investidores o mesmo projeto recebeu, fator de
            risco (cada rodada de investimento cria um fator de risco, avaliado
            quanto ao momento do investimento) e nota de execução.
          </p>
          <p>
            O(s) responsável(eis) pelo projeto acadêmico e/ou de pesquisa
            receberão a repartição do fundo financeiro obtido por meio da venda
            de cupons de desconto e de FATECOINS , por meio das APLICAÇÕES DA
            FATECOINS , cuja destinação deverá ser a continuidade do projeto,
            sua implantação e a transformação em negócio. As variáveis para
            cálculo do valor a ser recebido são: ÍNDICE DE ENGAJAMENTO da
            instituição e o valor da ideia, calculado a partir das moedas
            virtuais investidas no projeto e pela nota de execução atribuída
            pelos docentes na avaliação.
          </p>
        </li>
        <li>
          <strong>
            USO DAS MOEDAS VIRTUAIS FATECOINS PARA AQUISIÇÃO DE CUPONS DE
            DESCONTO
          </strong>
          <p>
            O usuário cadastrado poderá utilizar as moedas FATECOINS que dispõe
            para comprar cupons de desconto dentro das APLICAÇÕES DA FATECOINS,
            os quais contarão com preço expresso em Reais (BRL) e em quantidade
            de FATECOINS;
          </p>
          <p>
            Os cupons serão devidamente validados pelos COMERCIANTES PARCEIROS e
            trarão informações claras sobre o nome do comprador, a data de
            expiração e as regras para que o adquirente possa usufruir do
            desconto. O CUPOM é pessoal e intransferível, portanto não pode ser
            utilizado por outra pessoa que não seja o próprio comprador.
          </p>
          <p>
            FATECOINS INTERMEDIAÇÃO E AGENCIAMENTO DE SERVIÇOS LTDA não tem
            qualquer responsabilidade por eventuais divergências entre a oferta
            e o produto e/ou serviço efetivamente entregues.
          </p>
          <p>
            Ao adquirir um CUPOM ou FATECOINS , o adquirente poderá escolher a
            instituição de ensino que terá os projetos beneficiados com o valor
            pago, após abatidos os custos operacionais, de capitalização e
            reserva, da empresa FATECOINS . Caso não atribua o valor a uma
            instituição, a importância paga comporá o fundo da região em que o
            COMERCIANTE PARCEIRO está localizado, para fomento de projetos
            acadêmicos e/ou de pesquisa, após abatidos os custos operacionais,
            de capitalização e reserva, da empresa FATECOINS , cuja distribuição
            às instituições seguirá o ÍNDICE DE ENGAJAMENTO de cada instituição
            de ensino.
          </p>
        </li>
      </ol>
      <h3 id="four">4. FUNDO FATECOINS PARA FOMENTO DE PROJETOS ACADÊMICOS</h3>
      <p>
        O lucro líquido da FATECOINS formará um fundo que será objeto de doação
        periódica aos participantes do jogo.
      </p>
      <p>
        <strong>Como é feita a doação:</strong> Haverá um ranking, público, em
        que cada projeto terá uma quantidade de FATECOINS investidos e o “valor
        da ideia”, assim, haverá uma distribuição proporcional do fundo, na
        ordem direta do “valor da ideia” nos respectivos projetos rankeados. O
        “valor da ideia” só é obtido ao final do período letivo, assim, durante
        o período letivo, em que o “valor da ideia” ainda não está definido, o
        ranking será baseado na quantidade de FATECOINS investidos em cada
        projeto.
      </p>
      <p>
        <strong>Distribuição do fundo:</strong> Em localidades que tenham apenas
        uma instituição de ensino participante, todas as vendas de cupons de
        desconto/ FATECOINS irão para essa instituição, levando em consideração
        o ÍNDICE DE ENGAJAMENTO de cada instituição.
      </p>
      <p>
        Nas localidades que tenham mais de uma instituição, a distribuição
        daquele valor não atribuído no momento da compra será proporcional ao
        ÍNDICE DE ENGAJAMENTO , que corresponderá à frequência de participação
        de alunos e professores nas APLICAÇÕES DA FATECOINS , cadastrando
        projetos acadêmicos e/ou de pesquisa, publicando informações sobre os
        projetos e investindo nestes.
      </p>
      <p>
        O conceito de localidade para efeitos de cálculo poderá ser cidade,
        distrito, microrregião ou região administrativa.
      </p>
      <p>
        A doação será feita para um único aluno do projeto, ainda que haja um
        grupo, o qual deverá ser habilitado como representante e será
        responsável pela prestação de contas. Para o caso de menores de idade, a
        doação será feita para o responsável legal de um único aluno do projeto,
        mesmo que haja um grupo.
      </p>
      <p>
        Para cada projeto, a FATECOINS estabelecerá se o valor será doado uma
        única vez ou em parcelas a partir do plano de execução orçamentária
        apresentado pelos integrantes do projeto. As liberações das parcelas
        irão ocorrer após a prestação de contas de cada projeto.
      </p>
      <p>
        A doação estará regida pela legislação do <strong>ITCMD</strong> , logo,
        o valor líquido doado poderá sofrer descontos e ter impostos retidos.
      </p>
      <h3 id="five">5. Opiniões e Comentários</h3>
      <p>
        Os comentários públicos ou privados encaminhados para as APLICAÇÕES DA
        FATECOINS , quer sejam moderados ou não, mas que sejam submetidos e/ou
        fornecidos por outros meios à FATECOINS, ainda que incluam ideias,
        know-how, técnicas, questões, críticas, comentários e sugestões serão
        tratadas como informações não confidenciais e, ao submeter ou postar,
        concorda em licenciar irrevogavelmente todos os direitos de propriedade
        intelectual relacionados ao conteúdo entregue à FATECOINS, sem custos e
        livre de royalties, mundialmente, perpetuamente, irrevogavelmente e
        transferível para uso, cópia, distribuição, exibição, publicação,
        realização, venda, arrendamento, transmissão, adaptação, criação de
        trabalhos derivados, além de poder traduzir, modificar, executar
        engenharia reversa, desmontar ou descompilar tais conteúdos submetidos.
        Todas as Submissões tornam-se automaticamente e exclusivamente
        propriedade da FATECOINS.
      </p>
      <p>
        O usuário garante que todos os conteúdos submetidos, em todo ou por
        parte, são seguros e livres de violação de direitos de propriedade
        intelectual, litígios ou crédito de terceiros. FATECOINS não assume
        qualquer responsabilidade por abuso de direitos autoriais ou de direitos
        de terceiros implicados pelo usuário.
      </p>
      <p>
        Além dos direitos aplicáveis, havendo a postagem de comentários ou
        críticas nas APLICAÇÕES DA FATECOINS , o usuário concede à FATECOINS o
        direito de usar o nome que submeteu em qualquer opinião, comentário ou
        outro conteúdo associado a eles. O usuário representa e garante que o
        mesmo ou de alguma forma controla os direitos nas opiniões, comentários
        e outros conteúdos que posta nas APLICAÇÕES DA FATECOINS ; e que o uso
        das suas opiniões, comentários e outros conteúdos pela FATECOINS não irá
        violar os direitos de terceiros.
      </p>
      <p>
        O usuário não deve se utilizar de um endereço de e-mail falso, assumir a
        identidade de terceira pessoa ou, de alguma forma, enganar a FATECOINS
        ou terceiros quanto a origem dos conteúdos submetidos. FATECOINS pode,
        porém não obrigatoriamente, remover ou editar todos os conteúdos
        submetidos antes de postá-los (inclusive comentários e críticas) por
        qualquer razão.
      </p>
      <h3 id="six">6. Direitos Autorais</h3>
      <p>
        Todos os textos, gráficos, fotografias ou outras imagens, slogans,
        termos comerciais, softwares e outros conteúdos nas APLICAÇÕES DA
        FATECOINS , pertencem exclusivamente a FATECOINS INTERMEDIAÇÃO E
        AGENCIAMENTO DE SERVIÇOS LTDA ou seus fornecedores de conteúdo.
      </p>
      <p>
        FATECOINS INTERMEDIAÇÃO E AGENCIAMENTO DE SERVIÇOS LTDA garantirá aos
        titulares de projetos acadêmicos disponibilizados em sua plataforma
        todos os direitos autorais, respeito a marcas e patentes, jamais se
        apropriando de qualquer inovação que estiver sob seus cuidados,
        garantindo ainda a confidencialidade acerca de segredos industriais
        eventualmente derivados dos projetos acadêmicos apresentados.
      </p>
      <p>
        É expressamente proibido ao usuário usar, reproduzir, copiar, modificar,
        transmitir, exibir, publicar, vender, licenciar, executar publicamente,
        distribuir ou explorar comercialmente qualquer conteúdo sem autorização
        escrita da titular FATECOINS . A visualização de conteúdos deve se
        limitar apenas para propósitos de informação pessoal e requisições nas
        APLICAÇÕES DA FATECOINS.
      </p>
      <p>
        Todos os softwares utilizados nas APLICAÇÕES DA FATECOINS são de
        propriedade da FATECOINS e/ou de seus fornecedores de software, sendo
        protegido por leis de direitos autorais estatais, nacionais e
        internacionais.
      </p>
      <h3 id="seven">7. Política de Violação de Propriedade Intelectual</h3>
      <p>
        As características da prestação de serviços de FATECOINS implica
        necessariamente no uso de marcas de terceiros parceiros, que serão
        apresentadas nas APLICAÇÕES DA FATECOINS . Bem como, na divulgação de
        ranking de usuários e outros dados referente as parcerias comerciais.
        Todas as marcas exibidas nas APLICAÇÕES DA FATECOINS declaram ter
        assinado contrato constando a cessão de uso para fins promocionais, bem
        como terem informado previamente todos os requisitos para apresentação
        da marca, conforme a linguagem visual preestabelecida, conforme manual
        de marca e/ou recomendação específica. Bem como, o uso da marca
        FATECOINS por qualquer usuário das APLICAÇÕES DA FATECOINS somente será
        realizada mediante cessão de uso para fins comerciais e/ou promocionais,
        por escrito, devendo todas as partes respeitarem os limites contratuais
        e deste termo.
      </p>
      <p>
        Caso seja proprietário de direitos de propriedade intelectual e acredita
        que a FATECOINS está vendendo, oferecendo por oferta ou disponibilizando
        produtos e/ou serviços que violam seus direitos de propriedade
        intelectual, favor enviar as seguintes informações em sua integridade
        para
        <strong>fatecoins@gmail.com</strong>.
      </p>
      <h3 id="eight">
        8. Encerramento das atividades com exclusão das APLICAÇÕES DA FATECOINS
      </h3>
      <p>
        Além de qualquer outra solução legal ou equitativa, FATECOINS pode, sem
        aviso prévio, encerrar suas atividades e retirar as APLICAÇÕES DA
        FATECOINS da rede mundial de computadores, das lojas de aplicativos e de
        circulação em geral. Em consequência, você deverá cessar imediatamente
        todos os acessos e usos das APLICAÇÕES DA FATECOINS. A FATECOINS poderá
        ainda revogar imediatamente todas as senhas e identificações de contas
        que foram expedidos para você e negar o seu acesso e uso das APLICAÇÕES
        DA FATECOINS por completo ou em parte. O encerramento das atividades e
        consequente invalidação do presente termo não irá afetar os respectivos
        direitos e obrigações (incluso sem limitação, obrigações financeiras)
        das partes antecedentes a data do encerramento.
      </p>
      <h3 id="nine">9. Isenção e Limitação de Responsabilidade</h3>
      <p>
        As ofertas (cupons de descontos) apresentadas por meio das APLICAÇÕES DA
        FATECOINS são de inteira responsabilidade dos COMERCIANTES PARCEIROS
        (FORNECEDORES), que deverão honrar com a oferta proposta, pelo prazo
        descrito no momento da aquisição. A desconformidade de quantidades,
        apresentação, qualidade ou especificações não poderão ser imputadas a
        FATECOINS, cuja única atividade comercial é de intermediação de ofertas,
        no que toca a venda de cupons de descontos.
      </p>
      <p>
        A FATECOINS não garante que o conteúdo das APLICAÇÕES DA FATECOINS será
        ininterrupto ou livre de erros. À máxima extensão permitida pela lei e
        não será responsável por danos de qualquer tipo pelo uso das APLICAÇÕES
        DA FATECOINS, incluso mas não limitado a dano indireto, acidental,
        punitivo, exemplar, especial ou conseqüente.
      </p>
      <h3 id="ten">
        10. Aceitação de Pedidos de Compra de cupons e/ou moedas Fatecoins
      </h3>
      <p>
        Poderá haver certos pedidos que não poderão ser aceitos ou que
        precisarão ser cancelados. Nos reservamos ao direito de recusar ou
        cancelar qualquer pedido por qualquer razão. Algumas situações que podem
        resultar no cancelamento do pedido são, por exemplo: limitações de
        quantidades disponíveis para compra, imprecisões ou erros nas
        informações do produto ofertado ou preço; problemas identificados que
        possa suscitem a suspeita de fraude ou mal uso das APLICAÇÕES DA
        FATECOINS . Poderemos também solicitar verificações ou informações
        adicionais antes de aceitar qualquer pedido. Iremos contatá-lo caso seu
        pedido tenha que ser cancelado ou se necessitarmos solicitar informações
        adicionais.
      </p>
      <h3 id="eleven">11. Erros Tipográficos</h3>
      <p>
        Embora nos esforcemos para fornecer informações precisas das ofertas,
        erros tipográficos podem ocorrer. Na eventualidade de haver cupom
        listado com informações ou preço incorretos devido à erro tipográfico,
        poderemos recusar ou cancelar qualquer pedido efetuado para tal item.
        Nessa ocasião, a FATECOINS irá, a seu critério, tanto contatá-lo para
        instruções ou cancelar o pedido e notificá-lo de tal cancelamento.
      </p>
      <h3 id="twelve">12. Links</h3>
      <p>
        As APLICAÇÕES DA FATECOINS poderão conter links a outros sites e
        aplicativos na Internet que são pertencentes e operados por terceiros.
      </p>
      <h3 id="thirteen">13. Solução de litígios</h3>
      <p>
        As relações jurídicas que se derem se limitarem a aquisição de cupons e
        comercialização de FATECOINS, serão solucionadas no domicílio do
        consumidor, se residente no Brasil, ou na Capital do Estado de São Paulo
        para residentes no exterior.
      </p>
      <p>
        As relações jurídicas que disserem respeito a fornecedores, parceiros e
        estudantes que cadastrem projetos para a serem submetidos a avaliação e
        investimento serão solucionadas na sede da empresa FATECOINS
        INTERMEDIAÇÃO E AGENCIAMENTO DE SERVIÇOS LTDA, em detrimento de qualquer
        outro, por mais privilegiado que seja.
      </p>

      <Button className="button" variant="contained" onClick={register}>
        LI E CONCORDO COM OS TERMOS DE USO
      </Button>
    </div>
  );
}
