import MaskedInput from "react-text-mask";
import PropTypes from 'prop-types';

export const CNPJMaskInput = (props: any) => {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={[/[1-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/ ]}
        placeholderChar={'\u2000'}        
        showMask={false}
      />
    );
  }
  
  CNPJMaskInput.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };