import React, { useEffect, useState, useCallback } from "react";
import quizQuestionService from "../../services/QuizQuestionService";
import lessonService from "../../services/LessonService";
import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  Modal,
  Box,
  TextField,
} from "@mui/material";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./index.scss";

export interface Course {
  id: number;
  name: string;
}

export interface Lesson {
  id: number;
  name: string;
  course: Course;
}

export interface User {
  id: number;
  name: string;
}

export interface IComment {
  id: string;
  text: string;
  user: User;
}

export interface Question {
  id: string;
  title: string;
  text: string;
  lesson: Lesson;
  user: User;
  lessonAnswers: any[];
  comments?: IComment[];
}

const QuestionCollaboration: React.FC = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState<Question | null>(null);
  const [responseText, setResponseText] = useState<string>("");
  const [courses, setCourses] = useState<Course[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  const limit = 10;
  const navigate = useNavigate();

  // Carregar todos os cursos
  useEffect(() => {
    lessonService
      .getAllCourses()
      .then((response) => {
        if (response.data.courses && Array.isArray(response.data.courses)) {
          setCourses(response.data.courses);
          setSelectedCourse(response.data.courses[0]);
        } else {
          console.error("Formato inesperado de cursos:", response.data);
        }
      })
      .catch((err) => {
        setError("Erro ao carregar os cursos.");
      });
  }, []);

  // Função para carregar mais perguntas
  const loadMoreQuestions = useCallback(() => {
    if (loadingMore || !selectedCourse?.id || !hasMore) return;

    setLoadingMore(true);

    quizQuestionService
      .getUnansweredQuestionsByCoursePaginated(
        selectedCourse.id,
        currentPage,
        limit
      )
      .then((response) => {
        if (response.length < limit) {
          setHasMore(false);
        }
        setQuestions((prevQuestions) => {
          if (currentPage === 1) {
            return response.filter(
              (question: Question) =>
                question.lesson.course.id === selectedCourse.id
            );
          } else {
            return [
              ...prevQuestions,
              ...response.filter(
                (question: Question) =>
                  question.lesson.course.id === selectedCourse.id
              ),
            ];
          }
        });

        setCurrentPage((prevPage) => prevPage + 1);
        setLoadingMore(false);
        setFadeIn(true);
        setTimeout(() => setFadeIn(false), 500);
      })
      .catch(() => {
        setError("Erro ao carregar mais perguntas.");
        setLoadingMore(false);
      });
  }, [selectedCourse, currentPage, limit, hasMore, loadingMore]);

  // Carregar as perguntas ao trocar o curso
  useEffect(() => {
    if (selectedCourse?.id) {
      setLoading(true);
      quizQuestionService
        .getUnansweredQuestionsByCoursePaginated(selectedCourse.id, 1, limit)
        .then((response) => {
          setQuestions(response);
          setHasMore(response.length === limit);
          setLoading(false);
          setFadeIn(true);
          setTimeout(() => setFadeIn(false), 500);
        })
        .catch(() => {
          setError("Erro ao carregar perguntas.");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [selectedCourse]);

  // Listener para scroll infinito
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        loadMoreQuestions();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadMoreQuestions]);

  // Função para selecionar curso
  const handleCourseSelect = (course: Course) => {
    setSelectedCourse(course);
    setQuestions([]);
    setCurrentPage(1);
    setHasMore(true);
    loadQuestionsForSelectedCourse(course.id);
  };

  const loadQuestionsForSelectedCourse = (courseId: number) => {
    setLoading(true);
    quizQuestionService
      .getUnansweredQuestionsByCoursePaginated(courseId, 1, limit)
      .then((response) => {
        const filteredQuestions = response.filter(
          (question: Question) => question.lesson.course.id === courseId
        );
        setQuestions(filteredQuestions);
        setHasMore(filteredQuestions.length === limit);
        setLoading(false);
      })
      .catch(() => {
        setError("Erro ao carregar perguntas.");
        setLoading(false);
      });
  };

  // Abrir modal para responder pergunta
  const handleRespondClick = (question: Question) => {
    setCurrentQuestion(question);
    setIsModalOpen(true);
  };

  // Fechar modal
  const handleCloseModal: () => void = () => {
    setIsModalOpen(false);
    setCurrentQuestion(null);
  };

  // Submeter a resposta
  const handleSubmitResponse = () => {
    if (!currentQuestion || !responseText) {
      swal("Erro", "Por favor, escreva uma resposta antes de enviar.", "error");
      return;
    }

    const commentData = {
      questionId: Number(currentQuestion.id),
      text: responseText,
    };

    quizQuestionService
      .saveCommentAfterQuestion(commentData)
      .then((response: any) => {
        // Transforma a resposta em um IComment
        const newComment: IComment = {
          id: response.id, // Mapeia o id corretamente
          text: response.text, // Mapeia o texto da resposta
          user: response.user, // Mapeia o usuário associado
        };

        swal(
          "Sucesso",
          "Resposta enviada com sucesso. Obrigado por colaborar!",
          "success"
        );

        // Atualiza a pergunta com o novo comentário
        setQuestions((prevQuestions) =>
          prevQuestions.map((question) =>
            question.id === currentQuestion.id
              ? {
                  ...question,
                  comments: [...(question.comments || []), newComment], // Garante que o tipo seja IComment[]
                }
              : question
          )
        );

        handleCloseModal();
      })
      .catch(() => {
        swal(
          "Erro",
          "Não foi possível enviar sua resposta. Tente novamente",
          "error"
        );
      });
  };

  if (loading) return <div>Carregando...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <nav
        className="breadcrumb-talent"
        style={{
          marginTop: 0,
          marginLeft: "25%",
          marginRight: 0,
          maxWidth: "50%",
        }}
      >
        <ol style={{ display: "flex", alignItems: "center" }}>
          <li
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <FaHome style={{ marginRight: "5px" }} />
            <a className="p_normal" href="/cursos">
              Início
            </a>
          </li>

          <li>
            <a className="p_normal" href="/cursos">
              Meus Cursos
            </a>
          </li>
          <li>
            <a className="p_normal" href="/minhas-skills">
              Minhas Skills
            </a>
          </li>
          <li>
            <a className="p_normal" href="/minhas-metrics">
              Minhas Métricas
            </a>
          </li>
          <li>
            <a className="p_normal" href="">
              Colaboração
            </a>
          </li>
        </ol>
      </nav>

      <h3 className="title" style={{ marginLeft: "25%", marginTop: "50px" }}>
        Colabore com quem está aprendendo
      </h3>

      {courses.length > 0 ? (
        <div
          className="dropdown"
          style={{ marginLeft: "25%", marginTop: "50px", width: "50%" }}
        >
          <button
            className="btn_secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedCourse
              ? `Curso selecionado: ${selectedCourse.name}`
              : "Selecione um Curso"}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {courses.map((course) => (
              <li key={course.id}>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => handleCourseSelect(course)}
                >
                  {course.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>Carregando cursos...</p>
      )}

      <div className="space"></div>

      <Divider style={{ width: "100%", color: "#FFFFFF" }} />

      {questions.length > 0 ? (
        <div className={`fade-in ${fadeIn ? "show" : ""}`}>
          {questions.map((question) => (
            <div style={{ width: "100%" }} key={question.id}>
              <QuestionDetails
                question={question}
                onRespond={() => handleRespondClick(question)}
              />
            </div>
          ))}
        </div>
      ) : (
        <p>Não há perguntas para este curso.</p>
      )}


      {loadingMore && <div>Carregando mais perguntas...</div>}

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1500,
            bgcolor: "background.paper",
            border: "2px solid rgba(0, 0, 0, 0.3)",
            borderRadius: "20px",
            boxShadow: 24,
            p: 6,
          }}
        >
          <h2 className="title">Colabore com quem está aprendendo</h2>
          <div className="space"></div>
          {currentQuestion && (
            <div>
              <h3>{currentQuestion.title}</h3>
              <p>{currentQuestion.text}</p>
              <TextField
                label="Sua resposta"
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={(e) => setResponseText(e.target.value)}
              />
              <div style={{ marginTop: "20px" }}>
                <button
                  className="btn_secondary"
                  onClick={handleSubmitResponse}
                >
                  Enviar Resposta
                </button>
                <button
                  className="btn_tertiary"
                  onClick={handleCloseModal}
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

const QuestionDetails: React.FC<{
  question: Question;
  onRespond: () => void;
}> = ({ question, onRespond }) => {
  const [areAnswersVisible, setAreAnswersVisible] = useState(false);

  const toggleAnswersVisibility = () => {
    setAreAnswersVisible(!areAnswersVisible);
  };

  return (
    <List className="forum">
      <ListItem>
        <div
          className="duvidas-students"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.1)",
            padding: "10px 10px",
            borderRadius: "10px",
            marginLeft: "25%",
            width: "50%",
            background: "#ffffff",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "35px",
            }}
          >
            <p style={{ margin: 0 }}>
              <strong>Curso:</strong> {question.lesson.course.name}
            </p>
            <p style={{ margin: "0 0 0 10px" }}>
              <strong>Aula:</strong> {question.lesson.name}
            </p>
          </div>
          <div
            style={{
              border: "1px solid rgba(0, 0, 0, 0.1)",
              padding: "10px",
              borderRadius: "10px",
              width: "96%",
              background: "#e5e7eb",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
              <div>
                <div className="subtitle">{question.user.name}</div>
              </div>
            </div>
            <div
              className="p_normal"
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "200px",
                overflowY: "auto",
                background: "#fff",
                display: "flex",
                padding: "10px",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
                marginTop: "10px",
              }}
            >
              {question.text}
            </div>
          </div>

          <div style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
            <button className="btn_tertiary" onClick={toggleAnswersVisibility}>
              {areAnswersVisible ? "Fechar" : "Ver respostas"}
            </button>
            <button className="btn_secondary" onClick={onRespond}>
              Responder
            </button>
          </div>

          {areAnswersVisible &&
          question.comments &&
          question.comments.length > 0 ? (
            <List style={{ marginTop: "10px" }}>
              {question.comments.map((comment) => (
                <ListItem key={comment.id}>
                  <div
                    className="avarta-resposta"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      background: "#e5e7eb",
                      padding: "10px",
                      borderRadius: "8px",
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "5px",
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar />
                      </ListItemAvatar>
                      <div className="p_bold" style={{ marginLeft: "10px" }}>
                        {comment.user.name}
                      </div>
                    </div>

                    <div
                      className="p_normal"
                      style={{
                        background: "#fff",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        width: "100%",
                        height: "auto",
                        maxHeight: "200px",
                        overflowY: "auto",
                        marginTop: "5px",
                      }}
                    >
                      {comment.text}
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          ) : (
            areAnswersVisible && <p>Nenhuma resposta até o momento.</p>
          )}
        </div>
      </ListItem>
    </List>
  );
};

export default QuestionCollaboration;
