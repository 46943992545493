import Axios from "../shared/axios"
import api from "./api"

class QuizQuestionsAnswersService {


  async create(data: any): Promise<any> {
    return Axios.post("/quiz-question-answers/create-answer", data)
      .then((result) => {
        return Promise.resolve(result)
      }).catch((error) => {
        return Promise.reject(error)
      })
  }

  async getCountAnswers(idLesson: number): Promise<any> {
    return Axios.get(`/quiz-question-answers/count-answer/${idLesson}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async createAnswerByExams(data: any[]): Promise<any> {
    return Axios.post("/quiz-question-answers/create-answer-exams", data)
      .then((result) => {
        return Promise.resolve(result)
      }).catch((error) => {
        return Promise.reject(error)
      })
  }

}

const quizQuestionAnswersService = new QuizQuestionsAnswersService()
export default quizQuestionAnswersService;
