import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./index.scss";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import SubmitForm from "../Login/SubmitForm";
import paymentsService from "../../services/PaymentsService";
import { CurrencyMaskInput } from "../../components/CurrencyMaskInput/CurrencyMaskInput";
import { CardMaskInput } from "../../components/CardMaskInput/CardMaskInput";
import AuthContext from "../../context/auth";
import { MaskedInput } from "../../components/MaskedInput/phoneMaskInput";
import IPlan from "../../interfaces/IPlan";
import { planIdEnum } from "../../enums/plan-id.enum";
import SubscriptionContext from "../../context/Subscription";


// Aqui existe um tipo(IValuesForm), o objeto que vai ser enviado para o back.
//Por convenção deverá ter sempre o I maisculo quando fizer uma interface.
interface IValuesForm {
  valor: string | undefined;
  numeroCartao: string;
  nomeTitular: string;
  cpf: string;
  mes: string;
  ano: string;
  codigoSeguranca: string;
  street: string;
  number: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
}

const CardRegex: {
  visa: RegExp;
  master: RegExp;
  amex: RegExp;
  hipercard: RegExp;
} = {
  visa: /^4\d{4,15}$/,
  master: /^5\d{4,15}$/,
  amex: /^3[47]\d{4,13}$/,
  hipercard: /^6062\d{4,12}$/,
};

interface ICepData {
  bairro: string;
  cep: string;
  localidade: string;
  logradouro: string;
  uf: string;
}

function Payments() {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingAddress, setLoadingAddress] = useState<null | true | false>(
    null
  );
  // const { getId } = useContext(AuthContext);

  const { updateSubscription } = useContext(SubscriptionContext);
  const { id } = useParams();

  const navigate = useNavigate();

  const [plan, setPlan] = useState<IPlan>();

  const getPlan = () => {
    paymentsService
      .getPlan(Number(id) === 1 ? planIdEnum.HUNTER : planIdEnum.HUNTER_MNG)
      .then((response: any) => {
        setPlan(response.data);
      });
  };

  //abaixo é a validação do formik, com preenchimento de valores pelo usuario.
  // na linha 58 (as IValuesForm) estaos dizendo que esse objeto é do tipo initialValues, se o valor declarado é numerico"number", deverá ser preenchido com
  //numero, se o valor declarado for uma "string", deverá ser com aspas vazia.
  // O initialValues indica os valores ou string, que o que o usuario verá quando abrir a pagina.
  const formik = useFormik({
    initialValues: {
      valor: "89",
      numeroCartao: "",
      nomeTitular: "",
      cpf: "",
      mes: "",
      ano: String(new Date().getFullYear()),
      codigoSeguranca: "",
      street: "",
      number: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
    } as IValuesForm,

    validationSchema: Yup.object({
      valor: Yup.string().required("Preencha o valor"),
      nomeTitular: Yup.string().required(
        "Preencha o nome do Titular do Cartão"
      ),
      numeroCartao: Yup.string().required("Preencha o número do cartão"),
      cpf: Yup.string().required("Preencha seu CPF"),
      mes: Yup.string().required("Preencha o mês de validade"),
      ano: Yup.string().required("Preencha o ano de validade"),
      codigoSeguranca: Yup.string().required("Preencha o Código de Segurança"),
      street: Yup.string().required("Preencha o nome da rua"),
      number: Yup.string().required("Preencha o número do endereço"),
      city: Yup.string().required("Preencha a Cidade"),
      state: Yup.string().required("Preencha a UF"),
      zipCode: Yup.string().required("Preencha o CEP"),
      phone: Yup.string().required("Preencha o número de Telefone"),
    }),

    //quando for enviado o formulario, irá imprimir os valores do formulario.
    onSubmit: (values) => {
      // o create é uma função e essa variavel 'values' possue valores do formulario, essa variavel 'values' possue os
      //valores das linhas 48 a 53.
      create(values);
    },
  });

  useEffect(() => {
    if (formik.values.zipCode && formik.values.zipCode.length >= 8) {
      const cep = formik.values.zipCode.replace("-", "");
      setLoadingAddress(true);
      paymentsService
        .getCep(cep)
        .then((result: any) => {
          setLoadingAddress(false);
          const data: ICepData = result.data;
          formik.values.city = data.localidade;
          formik.values.state = data.uf;
          formik.values.street = data.logradouro;
          // console.log();
        })
        .catch((error) => {
          setLoadingAddress(false);
          console.log(error);
        });
    }
  }, [formik.values.zipCode]);

  //const create é uma função chamada, quando se clik no botão para salvar
  // Na variavel values pode estar recebendo 'any' (aceita quarquer coisa ou valor), mas nesse caso aqui, essa variavel so pode aceitar,
  //o tipo IValuesForm.
  // Observar que quando no codar, o VS oferece o preenchimento do valor da variavel.

  const create = (values: IValuesForm) => {
    setLoading(true);

    let issuer: string;
    if (values.numeroCartao.match(CardRegex.master)) {
      issuer = "master";
    } else if (values.numeroCartao.match(CardRegex.visa)) {
      issuer = "visa";
    } else {
      issuer = "amex";
    }

    let data = {
      transaction_amount: Number(
        values.valor?.replace("R$ ", "").replace(".", "").replace(",", ".")
      ),
      description: "curso_Talent",
      payer: {
        street: values.street,
        number: values.number,
        city: values.city,
        zipCode: values.zipCode,
        phone: values.phone,
        state: values.state,
      },
      payment_method_id: issuer,
      cardTokenBody: {
        card_number: values.numeroCartao,
        expiration_month: values.mes,
        expiration_year: values.ano,
        security_code: values.codigoSeguranca,
        cardholder: {
          name: values.nomeTitular,
          identification: {
            type: "CPF",
            number: values.cpf,
          },
        },
      },
      plan: plan ? plan.id : "",
    };

    paymentsService
      .createSignature(data)
      .then((response) => {
        // console.log(response);
        setLoading(false);
        swal({
          title: "Assinatura realizada com sucesso!",
          text: "Agora você já pode buscar talentos para a sua empresa.",
          icon: "success",
        }).then((value: any) => {
          updateSubscription().then(() => {
            navigate("/consumedCredits");
          });
        });
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: "Não foi possível realizar sua assinatura!",
          text: "Confira os dados do seu cartão, por favor.",
          icon: "error",
        });
        setLoading(false);
      });
  };

  const cancel = () => {
    navigate("/consumedCredits");
  };

  useEffect(() => {
    paymentsService
      .findSignature()
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getPlan();
  }, []);

  const getYears = () => {
    let years = [];
    for (let i = 0; i <= 12; i++) {
      years.push(new Date().getFullYear() + i);
    }
    return years;
  };

  return (
    <div
      className="container-payment"
      style={{ float: "left", marginLeft: "20%" }}
    >
      {isSubmitSuccess ? (
        <SubmitForm />
      ) : (
        <form
          style={{
            borderRadius: 20,
            border: '2px solid #DFE4EA',
            marginTop: 30,
            width: "250%",
          }}
          className="containerWhite-payments"
          onSubmit={formik.handleSubmit}
        >
          <h2 className="title" style={{ display: 'flex', marginTop: '3px', alignItems: "center", justifyContent: "center" }}>Assinatura do FATECOINS Talent</h2>

          <Divider />
          <div
            className="valor"
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: '50px'

            }}
          >
            <p>
              Por apenas{" "}
              <strong>
                R${" "}
                {plan && (plan.items[0].pricing_scheme.price / 100).toFixed(2)}
                /mês
              </strong>
              , você pode buscar talentos para a sua empresa.
            </p>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                name="numeroCartao"
                fullWidth
                style={{ width: "97%", textAlign: "left" }}
                type="text"
                placeholder="Número do Cartão"
                className="textField"
                InputProps={{
                  // inputComponent: CardMaskInput,

                  startAdornment: <></>,
                }}
                onChange={formik.handleChange}
                onBlur={() =>
                (formik.values.numeroCartao =
                  formik.values.numeroCartao.replace(/\s/g, ""))
                }
                value={formik.values.numeroCartao}
              />
              {formik.touched.numeroCartao && formik.errors.numeroCartao ? (
                <div className="error_msg">{formik.errors.numeroCartao}</div>
              ) : null}
            </Grid>

            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                name="nomeTitular"
                style={{ width: "97%", textAlign: "left" }}
                type="text"
                placeholder="Titular do Cartão"
                className="textField"
                InputProps={{
                  startAdornment: <></>,
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nomeTitular}
              />
              {formik.touched.nomeTitular && formik.errors.nomeTitular ? (
                <div className="error_msg">{formik.errors.nomeTitular}</div>
              ) : null}
            </Grid>

            <Grid item xs={3}>
              <TextField
                variant="outlined"
                required
                name="cpf"
                style={{ width: "97%", textAlign: "left" }}
                type="text"
                placeholder="CPF do Titular"
                className="textField"
                InputProps={{
                  // inputComponent:,

                  startAdornment: <></>,
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cpf}
              />
              {formik.touched.cpf && formik.errors.cpf ? (
                <div className="error_msg">{formik.errors.cpf}</div>
              ) : null}
            </Grid>

            <Grid item xs={3}>
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "12px" }}
              >
                <NativeSelect
                  defaultValue={30}
                  input={
                    <OutlinedInput name="mes" id="outlined-mes-native-simple" />
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ width: "93%" }}
                  value={formik.values.mes}
                >
                  <option value={"mes"}>Mês</option>
                  <option value={"01"}>01</option>
                  <option value={"02"}>02</option>
                  <option value={"03"}>03</option>
                  <option value={"04"}>04</option>
                  <option value={"05"}>05</option>
                  <option value={"06"}>06</option>
                  <option value={"07"}>07</option>
                  <option value={"08"}>08</option>
                  <option value={"09"}>09</option>
                  <option value={"10"}>10</option>
                  <option value={"11"}>11</option>
                  <option value={"12"}>12</option>
                </NativeSelect>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl variant="outlined" style={{ marginTop: "12px" }}>
                <NativeSelect
                  defaultValue={30}
                  input={
                    <OutlinedInput name="ano" id="outlined-mes-native-simple" />
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ width: "18rem" }}
                  value={formik.values.ano}
                >
                  <option value="ano">Ano</option>
                  {getYears().map((year) => {
                    const lastTwoDigits = String(year).slice(-2);
                    return (
                      <option key={year} value={String(lastTwoDigits)}>
                        {formik.values.ano === "ano" ? "Ano" : year}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <TextField
                variant="outlined"
                name="codigoSeguranca"
                style={{ width: "94%", textAlign: "left" }}
                type="text"
                placeholder="CVV"
                className="textField"
                inputProps={{ maxLength: 3 }}
                InputProps={{
                  startAdornment: <></>,
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.codigoSeguranca}
              />
              {formik.touched.codigoSeguranca &&
                formik.errors.codigoSeguranca ? (
                <div className="error_msg">{formik.errors.codigoSeguranca}</div>
              ) : null}
            </Grid>



            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="phone"
                style={{ width: "97%", textAlign: "left" }}
                type="text"
                placeholder="Telefone"
                className="textField"
                InputProps={{
                  startAdornment: <></>,
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className="error_msg">{formik.errors.phone}</div>
              ) : null}
            </Grid>

            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="zipCode"
                style={{ width: "97%", textAlign: "left" }}
                type="text"
                placeholder="CEP"
                className="textField"
                InputProps={{
                  startAdornment: <></>,
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.zipCode}
              />
              {formik.touched.zipCode && formik.errors.zipCode ? (
                <div className="error_msg">{formik.errors.zipCode}</div>
              ) : null}
            </Grid>


            {isLoadingAddress == false && (
              <>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    name="street"
                    style={{ width: "97%", textAlign: "left" }}
                    type="text"
                    placeholder="Rua"
                    className="textField"
                    InputProps={{
                      startAdornment: <></>,
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.street}
                  />
                  {formik.touched.street && formik.errors.street ? (
                    <div className="error_msg">{formik.errors.street}</div>
                  ) : null}
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    name="number"
                    style={{ width: "97%", textAlign: "left" }}
                    type="text"
                    placeholder="Número"
                    className="textField"
                    InputProps={{
                      startAdornment: <></>,
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.number}
                  />
                  {formik.touched.number && formik.errors.number ? (
                    <div className="error_msg">{formik.errors.number}</div>
                  ) : null}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    name="city"
                    style={{ width: "97%", textAlign: "left" }}
                    type="text"
                    placeholder="Cidade"
                    className="textField"
                    InputProps={{
                      startAdornment: <></>,
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="error_msg">{formik.errors.city}</div>
                  ) : null}
                </Grid>

                <Grid item xs={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: "12px" }}
                  >
                    <Select
                      label="UF"
                      name="state"
                      placeholder="Estado"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value={"UF"}>UF</MenuItem>
                      <MenuItem value={"AC"}>Acre</MenuItem>
                      <MenuItem value={"AL"}>Alagoas</MenuItem>
                      <MenuItem value={"AP"}>Amapá</MenuItem>
                      <MenuItem value={"AM"}>Amazonas</MenuItem>
                      <MenuItem value={"BA"}>Bahia</MenuItem>
                      <MenuItem value={"CE"}>Ceará</MenuItem>
                      <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                      <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                      <MenuItem value={"GO"}>Goiás</MenuItem>
                      <MenuItem value={"MA"}>Maranhão</MenuItem>
                      <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                      <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                      <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                      <MenuItem value={"PA"}>Pará</MenuItem>
                      <MenuItem value={"PB"}>Paraíba</MenuItem>
                      <MenuItem value={"PR"}>Paraná</MenuItem>
                      <MenuItem value={"PE"}>Pernambuco</MenuItem>
                      <MenuItem value={"PI"}>Piauí</MenuItem>
                      <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                      <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                      <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                      <MenuItem value={"RO"}>Rondônia</MenuItem>
                      <MenuItem value={"RR"}>Roraima</MenuItem>
                      <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                      <MenuItem value={"SP"}>São Paulo</MenuItem>
                      <MenuItem value={"SE"}>Sergipe</MenuItem>
                      <MenuItem value={"TO"}>Tocantins</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.state && formik.errors.state ? (
                    <div className="error_msg">{formik.errors.state}</div>
                  ) : null}
                </Grid>
              </>
            )}
          </Grid>
          <br />

          <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>


            {!isLoading && (


              <button
                className="btn_primary"
                style={{ marginRight: '31px' }}
                type="submit"
              >
                Assinar agora
              </button>
            )}




            {isLoading && (
              <Button className="button-confirm" type="submit" disabled>
                Assinando...
              </Button>
            )}

            <button className="btn_tertiary"
              style={{ marginRight: '5%' }}
              onClick={cancel}
            >
              Cancelar
            </button>

          </div>

        </form>
      )}
    </div>
  );
}

export default Payments;
