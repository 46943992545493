import Axios from "../shared/axios";

class CourseService {
  async getAll(): Promise<any> {
    return Axios.get("/courses/all")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async start(data: any) {
    let token = localStorage.getItem("TOKEN");
    return Axios.post("/students-courses/create", data)
      .then((result) => {
        let courses = JSON.stringify(result.data.course);
        localStorage.setItem("CURSOS", courses)
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // }).then((response) => {
    //     localStorage.setItem("TOKEN", response.data.access_token)
    //     return Promise.resolve(response)
    // }).catch((error) => {
    //     return Promise.reject(error)
    // })
  }

  async getPercentage(idCourse: number): Promise<any> {
    return Axios.get(`/courses/percentage/${idCourse}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }
  async getCountCourses(): Promise<any> {
    return Axios.get(`/courses/count`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }


async getCoursesByCareer(idCareer: number): Promise<any> {
  try {
   
    let token = localStorage.getItem("TOKEN");
    if (!token) {
      return Promise.reject({
        message: "TOKEN NÃO ENCONTRADO",
      });
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    }
    const result = await Axios.get(`/career-course/${idCareer}/courses`, {headers});
    return result.data;
  } catch (error: any) {
    if (error.response) {
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject({
        message: "Erro ao enviar dados para o servidor",
      });
    }
  }
}



/* Pega a trilha cadastrada   */
async getCareerPaths(): Promise<any> {
  try {
    // Recupera o token do localStorage
    let token = localStorage.getItem("TOKEN");
    if (!token) {
      return Promise.reject({
        message: "TOKEN NÃO ENCONTRADO",
      });
    }

    // Define os cabeçalhos, incluindo o token de autenticação
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Faz a requisição GET ao endpoint correto para obter todas as trilhas de carreira
    const result = await Axios.get(`/career-path/all`, { headers });
    return result.data; // Retorna os dados recebidos
  } catch (error: any) {
    // Tratamento de erros
    if (error.response) {
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject({
        message: "Erro ao enviar dados para o servidor",
      });
    }
  }
}




}



const courseService = new CourseService();
export default courseService;
