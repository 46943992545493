import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Table } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { FaHome } from 'react-icons/fa';
import interviewService from '../../services/InterviewService';
import studentsService from '../../services/StudentsService';
import swal from 'sweetalert';
import "bootstrap/dist/css/bootstrap.min.css";
//import "./index.scss";

interface ISkills {
    id: number;
    name: string;
    description: string;
    type: string;
    total: number;
}

interface IInterview {
    id: number;
    company: {
        fantasy_name: string;
        user: {
            email: string;
        };
    };
    accepted: 'Y' | 'N' | null;
}

const TabsSkillsInterviews = () => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [studentSkills, setSkills] = useState<ISkills[]>([]);
    const [interviews, setInterviews] = useState<IInterview[]>([]);

    const loadLists = () => {
        setLoading(true);
        Promise.all([
            studentsService.getSkills(),
            interviewService.getByUser(),
        ])
            .then(([skillsResponse, interviewsResponse]) => {
                setSkills(skillsResponse.data);
                setInterviews(interviewsResponse.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };

    useEffect(() => {
        loadLists();
    }, []);

    const toggleInterview = (idInterview: number, type: 'Y' | 'N') => {
        const data = { accepted: type };
        swal({
            title: `Realmente deseja ${type === 'Y' ? 'aceitar' : 'recusar'
                } essa entrevista?`,
            buttons: ['não', 'sim'],
        }).then((response) => {
            if (response) {
                interviewService
                    .toggleInterview(idInterview, data)
                    .then(() => {
                        interviewService.getByUser().then((response) => {
                            setInterviews(response.data);
                            swal({
                                icon: 'success',
                                title: 'Status alterado com sucesso!',
                            });
                        });
                    })
                    .catch(() => {
                        swal({
                            icon: 'error',
                            title: 'Não foi possível aceitar/recusar essa entrevista!',
                        });
                    });
            }
        });
    };

    return (
        <>
            <div className="space"></div>
            <Tabs defaultActiveKey="skills" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="skills" title="Minhas Skills">
                    <div className="my-skills-container">
                        <div className="containerWhite">
                            <Table hover size="sm" className="my-skills">
                                <thead>
                                    <tr>
                                        <th>Skill</th>
                                        <th>Descrição</th>
                                        <th>Tipo</th>
                                        <th>Experiência</th>
                                    </tr>
                                </thead>
                                {isLoading && <img src="/assets/images/loading_II.gif" className="icon-loading" alt="carregando" />}
                                <tbody>
                                    {studentSkills.map((skill: ISkills) => (
                                        <tr key={skill.id}>
                                            <td>{skill.name}</td>
                                            <td>{skill.description}</td>
                                            <td>{skill.type === 'SOFT' ? 'Soft Skill' : 'Hard Skill'}</td>
                                            <td>{skill.total}%</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {!isLoading && studentSkills.length === 0 && <p style={{ padding: 10 }}>Nenhuma skill até o momento.</p>}
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="entrevistas" title="Minhas Entrevistas">
                    <div className="my-interviews-container">
                        <div className="containerWhiteII">
                            <Table hover size="sm" className="my-interviews">
                                <thead>
                                    <tr>
                                        <th>Empresa</th>
                                        <th>Email</th>
                                        <th>Status da entrevista</th>
                                    </tr>
                                </thead>
                                {isLoading && <img src="/assets/images/loading_II.gif" className="icon-loading" alt="carregando" />}
                                <tbody>
                                    {interviews.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.company.fantasy_name}</td>
                                            <td>{row.company.user.email}</td>
                                            <td
                                                style={{
                                                    cursor: row.accepted === null || row.accepted === 'Y' ? 'pointer' : 'auto',
                                                }}
                                                onClick={() => {
                                                    if (row.accepted === null) {
                                                        toggleInterview(row.id, 'Y');
                                                    } else if (row.accepted === 'Y') {
                                                        toggleInterview(row.id, 'N');
                                                    }
                                                }}
                                            >
                                                <Button
                                                    style={{
                                                        borderRadius: '10%',
                                                        width: '100px',
                                                        height: '30px',
                                                        padding: 0,
                                                        backgroundColor:
                                                            row.accepted === 'Y'
                                                                ? 'rgba(0, 128, 0, 0.3)'
                                                                : row.accepted === null
                                                                    ? 'rgba(255, 255, 0, 0.5)'
                                                                    : row.accepted === 'N'
                                                                        ? 'rgba(235, 37, 37, 0.5)'
                                                                        : 'inherit',
                                                    }}
                                                >
                                                    {row.accepted === null
                                                        ? 'Aguardando'
                                                        : row.accepted === 'Y'
                                                            ? 'Confirmado'
                                                            : 'Cancelado'}
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {!isLoading && interviews.length === 0 && <p style={{ padding: 10 }}>Nenhuma entrevista até o momento.</p>}
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </>
    );
};

export default TabsSkillsInterviews;
