import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { InputAdornment, Divider } from "@material-ui/core";
import { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SubmitForm from "../Login/SubmitForm";
import passwordResetService from "../../services/PasswordReset";
import { Typography } from "@mui/material";
import { CancelButton } from "./styles";

// Aqui existe um tipo(IValuesForm), o objeto que vai ser enviado para o back.
//Por convenção deverá ter sempre o I maisculo quando fizer uma interface.
interface IValuesForm {
  passwordReset: string;
}

function PasswordReset() {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  //abaixo é a validação do formik, com preenchimento de valores pelo usuario.
  // na linha 58 (as IValuesForm) estaos dizendo que esse objeto é do tipo initialValues, se o valor declarado é numerico"number", deverá ser preenchido com
  //numero, se o valor declarado for uma "string", deverá ser com aspas vazia.
  // O initialValues indica os valores ou string, que o que o usuario verá quando abrir a pagina.
  const formik = useFormik({
    initialValues: {
      passwordReset: "",
    } as IValuesForm,

    validationSchema: Yup.object({
      passwordReset: Yup.string().required(
        "É necessário preencher com seu email cadastrado"
      ),
    }),

    //quando for enviado o formulario, irá imprimir os valores do formulario.
    onSubmit: (values) => {
      // o create é uma função e essa variavel 'values' possue valores do formulario, essa variavel 'values' possue os
      //valores das linhas 48 a 53.
      create(values);
    },
  });

  //const create é uma função chamada, quando se clik no botão para salvar
  // Na variavel values pode estar recebendo 'any' (aceita quarquer coisa ou valor), mas nesse caso aqui, essa variavel so pode aceitar,
  //o tipo IValuesForm.
  // Observar que quando no codar, o VS oferece o preenchimento do valor da variavel.

  const create = (values: IValuesForm) => {
    let data = {
      email: values.passwordReset,
    };

    passwordResetService
      .create(data)
      .then((response) => {
        setLoading(false);
       // navigate("/");
        swal({
          title: "E-mail enviado",
          text: "Verifique sua caixa de e-mail, enviamos um link para a redefinição de senha",
          icon: "success",
        }).then((value: any) => {
          navigate("/");
        })
      })
      .catch((error) => {
        setLoading(false);
        swal({
          title: "Não existe usuário cadastrado nesse e-mail",
          icon: "error",
        });
      });
  };

  const cancel = () => {
    navigate("/");
  };

  return (
    <div className="container1 bg">
      <div
        className={
          !isSubmitSuccess ? "signin signin_wrapper" : "signin signin_success"
        }
      >
        {isSubmitSuccess ? (
          <SubmitForm />
        ) : (
          // este form é uma tag no HTML.
          // onSubmit é um evento que fica esperando uma ação do usuário e chama o {formik.handleSubmit}
          // e quando declaramos o formik, se define o que vai acontecer no submit
          <form onSubmit={formik.handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "20px",
                marginBottom: "30px",
              }}
            >
              <img src="assets/images/logo_IV.png" alt="fatecoins" />
            </div>
            <p className="p_normal">
              Informe seu e-mail cadastrado para recuperar sua senha
            </p>
            <br />
            <label className="label-cadastro">E-mail</label>
            <TextField
              id="email"
              variant="outlined"
              required
              placeholder="Email"
              margin="normal"
              //autoFocus
              fullWidth
              name="passwordReset"
              type="text"
              //autoFocus
              autoComplete="email"
              //className="textField"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.passwordReset}
            />
            {formik.touched.passwordReset && formik.errors.passwordReset ? (
              <div className="error_msg">{formik.errors.passwordReset}</div>
            ) : null}
            <div className="space"></div>

            <div className="central-button">
              <button className="btn_primary ">Recuperar Senha</button>
            </div>
            <div className="space"></div>

            <Divider
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                marginBottom: 20,
              }}
            />

            {/* <Button className="CancelButton" onClick={cancel}>
              Cancelar
            </Button> */}
               <div className="central-button">
            <button className="btn_tertiary" onClick={cancel}>
              Cancelar
            </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default PasswordReset;
