import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import Table from "react-bootstrap/Table";
import Box from "@mui/material/Box";
import { useContext, useEffect, useState } from "react";
import { Button, Divider, IconButton } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import SubmitForm from "../Login/SubmitForm";
import consumedCreditService from "../../services/ConsumedCreditsService";
import UtilDate from "../../utils/util.date";
import Sidebar from "../../components/sidebar/Sidebar";
import { BiMenu } from "react-icons/bi";
import Modal from "../../components/Modal/modal";
import ModalSkills from "../../components/ModalSkills/modalSkills";
import studentsService from "../../services/StudentsService";
import AuthContext from "../../context/auth";
import { format } from "date-fns";
import { ButtonBase } from "@mui/material";
import ISkills from "../../interfaces/ISkills";
import IEmployees from "../../interfaces/IEmployees";
import ISubscription from "../../interfaces/ISubscripition";
import paymentsService from "../../services/PaymentsService";
import { planNameEnum } from "../../enums/plan-name.enum";
import { planIdEnum } from "../../enums/plan-id.enum";

interface LessonCount {
  id: number;
  count: number;
}

function CompanyEmployess() {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  //abaixo uma lista que armazena os dados.
  //observar que o ICredits é uma lista por isso precisa dos [].
  const [companyEmployees, setCompanyEmployees] = useState<IEmployees[]>([]);

  const [skills, setSkills] = useState<ISkills[]>([]);

  const [createNumber, setcreateNumber] = useState<number>(0); 

  const [subscription, setSubscription] = useState<ISubscription>();

  //oservar que deve ser criado o habito de copiar codigos, esse useEffect foi copiado de outro lugar, é com ele que
  //faz que quando carregar a tela fazer loadList.

  const fetchSubscription = () => {
    paymentsService
      .getSubscription()
      .then((response) => {
        setSubscription(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const cancel = () => {
    navigate("/cadastroEmpresas");
  };

  const alterNumber = () => {
    setcreateNumber(createNumber + 1);
  };

  const loadList = () => {
    setLoading(true);

    return studentsService
      .getAllByCompany()
      .then((response) => {
        fetchSubscription();
        setLoading(false);
        let employees: IEmployees[] = response.data;
        setCompanyEmployees(employees);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const loadSkills = (studentId: number): Promise<any> => {
    return studentsService.getSkillsByStudent(studentId);
  };

  useEffect(() => {
    fetchSubscription();
    loadList();
  }, []);

  return (

    
    <div className="container-employers">
    { isLoading && <img src="/assets/images/loading_II.gif" className="icon-loading" alt="carregando" />}

      {subscription &&
        subscription.status === "active" &&
        subscription.plan.id == planIdEnum.HUNTER_MNG && (
          <>
            <h2 className='title' style={{marginLeft:'20%'}}>Meus colaboradores</h2>
            <div className="space"></div>

            <div className="player-buttons" style={{width:'1140px', margin: '0 auto', padding: '20px', background:'#F9FAFB', border: '1px solid #E9E9E9'}}>
            {!subscription ||
              (subscription && subscription.status === "active" && (
                <>
                  <Modal
                    {...{
                      loadList,
                      disabled: subscription.plan.name === planNameEnum.HUNTER,
                      // disabled:false
                    }}
                  />
                </>
                ))}  

            <Table className="table-colabor" striped bordered hover >
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Último acesso</th>
                  <th >Quantidades de aulas assistidas</th>
                  <th style={{width:'20%'}}>Habilidades</th>
                </tr>
              </thead>
              <tbody>
                {companyEmployees.length == 0 && (
                  <tr>
                    <td colSpan={5}>Nenhum colaborador cadastrado ainda.</td>
                  </tr>
                )}
                {companyEmployees.length > 0 && (
                  <>
                    {companyEmployees.map((student: IEmployees) => (
                      <tr>
                        <td>{student.name}</td>

                        <td>{student.email}</td>

                        <td>
                          {student.lastAccess
                            ? format(
                                new Date(student.lastAccess),
                                "dd/MM/yyyy HH:mm"
                              )
                            : "Nenhum acesso registrado"}
                        </td>

                        <td>
                          {student.countLesson == 0 ||
                          student.countLesson == undefined
                            ? "Nenhuma aula assistida"
                            : `${student.countLesson} aulas assistidas`}
                        </td>
                        <td>
                          {skills && (
                            <ModalSkills
                              loadSkills={loadSkills}
                              student={student}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </Table>
            </div>
          </>
          )}  
    </div>
  );
}
export default CompanyEmployess;
