import { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import SubmitForm from "./SubmitForm";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import companyService from "../../services/CompanyService";
import swal from "sweetalert";
import { RegisterType } from "../../shared/enum";

function Sponsor() {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  // const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      linkedin: "",
      password: "",
      confPassword: "",
      acceptTerms: false,
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("E-mail inválido")
        .required("É necessário preencher o campo e-mail"),
      password: Yup.string()
        .min(6, "Senha deve ter no mínimo 6 caracteres")
        // .matches(
        //   passwordRules,
        //   "Crie uma senha forte! A senha deve conter no mínimo dois números, um caractere especial, uma letra maiúscula, uma letra minúscula"
        // )
        .required("É necessário preencher o campo senha"),
      confPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Senha não corresponde")
        .required("É necessário confirmar a senha"),
      name: Yup.string().required("É necessário preencher o campo nome"),
      linkedin: Yup.string().required("É necessário preencher LinkedIn"),
      acceptTerms: Yup.bool().oneOf(
        [true],
        "É necessário aceitar os termos e condições"
      ),
    }),

    onSubmit: (values) => {
      create(values);
    },
  });

  const create = (values: any) => {
    let data = {
      official_name: values.name,
      linkedin: values.linkedin,
      email: values.email,
      password: values.password,
      type: RegisterType.SPONSOR,
    };
    
    companyService
      .create(data)
      .then((response) => {
        setLoading(false);
        swal({
          title: "Cadastro de patrocinador realizado com sucesso!",
          text: "Você será redirecionado à página de Login",
          icon: "success",
        }).then((value: any) => {
          navigate("/");
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const cancel = () => {
    navigate("/");
  };

  return (
    <div className="container bg">
      <div
        className={
          !isSubmitSuccess ? "signin signin_wrapper" : "signin signin_success"
        }
    
      >
        {isSubmitSuccess ? (
          <SubmitForm />
        ) : (
          <form onSubmit={formik.handleSubmit} >
            <div className="logo">
              <img src="./logo150.png" alt="fatecoins" />
            </div>
            <h2>Cadastro Patrocinador</h2>

            <TextField
              name="name"
              type="text"
              placeholder="Nome"
              className="textField"
              InputProps={{
                startAdornment: <></>,
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error_msg">{formik.errors.name}</div>
            ) : null}

            <TextField
              name="email"
              type="text"
              placeholder="E-mail"
              className="textField"
              InputProps={{
                startAdornment: <></>,
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />

            {formik.touched.email && formik.errors.email ? (
              <div className="error_msg">{formik.errors.email}</div>
            ) : null}

            <TextField
              name="linkedin"
              type="text"
              placeholder="LinkedIn"
              className="textField"
              InputProps={{
                startAdornment: <></>,
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.linkedin}
            />
            {formik.touched.linkedin && formik.errors.linkedin ? (
              <div className="error_msg">{formik.errors.linkedin}</div>
            ) : null}

            <TextField
              name="password"
              type="password"
              placeholder="Senha"
              className="textField"
              InputProps={{
                startAdornment: <></>,
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="error_msg">{formik.errors.password}</div>
            ) : null}

            <TextField
              name="confPassword"
              type="password"
              placeholder="Confirmar senha"
              className="textField"
              InputProps={{
                startAdornment: <></>,
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confPassword}
            />
            {formik.touched.confPassword && formik.errors.confPassword ? (
              <div className="error_msg">{formik.errors.confPassword}</div>
            ) : null}

            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={formik.values.acceptTerms} />}
                label={
                  <a href="/termos-de-uso-empresa">
                    Li e aceito os termos de uso
                  </a>
                }
                name="acceptTerms"
                onChange={formik.handleChange}
              />
              {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                <div className="error_msg">{formik.errors.acceptTerms}</div>
              ) : null}
            </FormGroup>

            <Button className="button-confirm" type="submit">
              Cadastrar
            </Button>

            <div className="cancel">
              <Button className="button-cancel" onClick={cancel}>
                Cancelar
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default Sponsor;
