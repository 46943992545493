import React, { useState, useEffect } from "react";
import { Modal, FormGroup } from "react-bootstrap";
import Swal from "sweetalert2";
import {
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormControl,
} from "@material-ui/core";
import quizQuestionCourseService from "../../services/QuizQuestionCourseService";
import { ICourse } from "../../interfaces/ICourse";
import { QuizQuestionsAlternativeCorrectType } from "../../enums/quiz-questions-alternative.enum";
import { QuizQuestionsLevelType } from "../../enums/quiz-questions-level.enum";
import { QuizQuestionsChoicesType } from "../../enums/quiz-questions-choices.enum";
import "./modalTotalCourseQuestion.scss"; // Importa o CSS
import quizQuestionService from "../../services/QuizQuestionService";
import { IStudent } from "../../interfaces/IStudent";
import { Completed, ExamsCreateDto } from "../../interfaces/IExam";
import quizQuestionAnswersService from "../../services/QuizQuestionsAnswersService";


// interface IQuizQuestion {
//     id: number;
//     image: string | null;
//     description: string;
//     option1: string;
//     option2: string;
//     option3: string;
//     option4: string;
//     correct: QuizQuestionsAlternativeCorrectType | null;
//     correctOption: number;
//     level: QuizQuestionsLevelType;
//     type?: QuizQuestionsChoicesType;
//     file?: string;
// }


interface IQuizQuestion {
    id: number;
    imageName: string | null;
    question: string;
    alternative1: string;
    alternativeCorrect1: QuizQuestionsAlternativeCorrectType;
    alternative2: string;
    alternativeCorrect2: QuizQuestionsAlternativeCorrectType;
    alternative3: string;
    alternativeCorrect3: QuizQuestionsAlternativeCorrectType;
    alternative4: string;
    alternativeCorrect4: QuizQuestionsAlternativeCorrectType;
    difficultyLevel: QuizQuestionsLevelType;
    type: QuizQuestionsChoicesType;
}

interface ModalTotalCourseQuestionProps {
    show: boolean;
    handleClose: () => void;
    course: ICourse | null;
    student: IStudent | null;

}

const ModalTotalCourseQuestion: React.FC<ModalTotalCourseQuestionProps> = ({
    show,
    handleClose,
    course,
    student,

}) => {
    const [questions, setQuestions] = useState<IQuizQuestion[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState<{
        [key: number]: string[];
    }>({});
    const [loading, setLoading] = useState(true);
    const [timerSeconds, setTimerSeconds] = useState(20); // Tempo do timer em segundos
    const [timerActive, setTimerActive] = useState(false); // Estado do timer (ativo ou não)
    const [provaIniciada, setProvaIniciada] = useState(false); // Estado para controlar se a prova foi iniciada
    const [pauseCount, setPauseCount] = useState(0); // Contador de pausas utilizadas
    const [showPauseMessage, setShowPauseMessage] = useState(true); // Estado para mostrar ou ocultar a mensagem de pausa
    const [examStartTime, setExamStartTime] = useState<Date>();
    const [isRequest, setIsRequest] = useState(false);
    const [examCreateId, setExamCreateId] = useState<number>();



    useEffect(() => {
        if (timerActive && timerSeconds > 0) {
            const timer = setTimeout(() => {
                setTimerSeconds(timerSeconds - 1);
            }, 1000);

            return () => clearTimeout(timer);
        } else if (timerActive && timerSeconds === 0) {
            handleNextQuestion(); // Avança para a próxima questão ao fim do timer
        }
    }, [timerActive, timerSeconds]);

    useEffect(() => {
        checkExamStatus();
    }, [course, show]);

    const checkExamStatus = async () => {
        if (course && show) {
            createExam(false);
            getQuestionByCourse();
        }
    }

    const getQuestionByCourse = async () => {
        await quizQuestionService
            .getAllRandomByCourse(course?.id!)
            .then((result) => {
                if (result.data) {
                    let data: IQuizQuestion[] = [];
                    result.data.forEach((question: any) => {
                        data.push({
                            id: question.id,
                            imageName: question.imageName,
                            question: question.question,
                            alternative1: question.alternative1,
                            alternativeCorrect1: question.alternativeCorrect1,
                            alternative2: question.alternative2,
                            alternativeCorrect2: question.alternativeCorrect2,
                            alternative3: question.alternative3,
                            alternativeCorrect3: question.alternativeCorrect3,
                            alternative4: question.alternative4,
                            alternativeCorrect4: question.alternativeCorrect4,
                            difficultyLevel: question.difficultyLevel,
                            type: question.type,
                        });
                    });
                    setQuestions(data);
                    // Setar o tempo inicial baseado na primeira questão
                    setTimerSeconds(getTimerSecondsByDifficulty(data[0].difficultyLevel));
                } else {
                    console.error("Estrutura de dados inesperada:", result.data);
                    Swal.fire({
                        icon: "error",
                        title: "Erro ao carregar questões",
                        text: "Estrutura de dados inesperada",
                    });
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Erro ao carregar questões:", error);
                Swal.fire({
                    icon: "error",
                    title: "Erro ao carregar questões",
                    text: error.message,
                });
                setLoading(false);
            });
    };

    const closeExam = () => {
        Swal.fire({
            title: 'Você deseja realmente encerrar a prova desse curso?',
            text: 'Você não terá outra chance.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#fcb900',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            customClass: {
                confirmButton: 'swal2-confirm',
                cancelButton: 'swal2-cancel'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if (!isRequest) {
                    handleClose();
                }
            }
        });
    };

    const getTimerSecondsByDifficulty = (difficulty: QuizQuestionsLevelType) => {
        if (Object.values(QuizQuestionsLevelType).includes(difficulty)) {
            switch (difficulty) {
                case QuizQuestionsLevelType.FACIL:
                case QuizQuestionsLevelType.FACIL_ACENTO:
                    return 45;
                case QuizQuestionsLevelType.MEDIO:
                case QuizQuestionsLevelType.MEDIO_ACENTO:
                    return 75;
                case QuizQuestionsLevelType.DIFICIL:
                case QuizQuestionsLevelType.DIFICIL_ACENTO:
                    return 90;
            }
        } else {
            throw new Error("Dificuldade da questão não reconhecida.");
        }
    };



    const handleNextQuestion = () => {
        if (currentQuestionIndex < questions.length - 1) {
            const nextIndex = currentQuestionIndex + 1;
            setCurrentQuestionIndex(nextIndex);
            setTimerSeconds(getTimerSecondsByDifficulty(questions[nextIndex].difficultyLevel));
            setTimerActive(true);
        } else {
            finishQuiz(true);
        }
    };

    const handleAnswerQuestion = () => {
        if (!provaIniciada) {
            setProvaIniciada(true); // Marca a prova como iniciada ao responder a primeira questão
            setTimerActive(true); // Inicia o timer ao responder a primeira questão
        }
        if (currentQuestionIndex < questions.length - 1) {
            handleNextQuestion();
        } else {
            if (!isRequest) {
                setIsRequest(true);
                finishQuiz(true);
            }
        }
    };

    const createExam = async (completed: boolean) => {
        try {
            await quizQuestionService.createStudentExam({
                idStudent: student?.id,
                idCourse: course?.id,
                completed: completed ? 'Y' : 'N',
                startedAt: new Date(Date.now()),
            }).then((response) => {
                setExamCreateId(response.data);
            });
        } catch (error: any) {
            Swal.fire({
                icon: "error",
                title: "Erro ao cadastrar a prova",
                text: error.message,
            });
        }
    }

    const finishQuiz = async (completed: boolean) => {
        try {
            Swal.fire({
                icon: "info",
                title: "Cadastrando respostas",
                text: "Registrando respostas. Aguarde...",
                showConfirmButton: false,
            });

            const questionResponses = questions.map((question) => ({
                id_quizQuestion: question.id,
                resposta: selectedOptions[question.id] || [],
            }));

            // await quizQuestionAnswersService.createAnswerByExams(questionResponses)
            const data = {
                id: examCreateId,
                completed: "Y",
                completedAt: new Date(Date.now()),
                answers: questionResponses,
            }

            await quizQuestionService.finishExam(examCreateId!, data)
                .then((result: any) => {
                    console.log('RESULT DATA -----', result.data)
                    // Swal.fire({
                    //     icon: completed ? 'success' : 'error',
                    //     title: completed ? 'Prova concluída' : 'Prova encerrada',
                    //     showConfirmButton: false,
                    //     timer: 1500,
                    // }).then(() => {
                    //     showExamResults(lessonId!);
                    //     handleClose();
                    // });


                    Swal.fire({
                        title: 'Resultados do Exame',
                        html: `
                        <p>Questões respondidas: ${result.data.totalQuizQuestions}</p>
                        <p>Questões corretas: ${result.data.correctAnswers}</p>
                        <p><strong>Habilidades:</strong></p>
                        <p>${result.data.skills}</p>
                        `,
                        icon: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then(() => {
                        handleClose();
                    });
                })
        } catch (error: any) {
            Swal.fire({
                icon: "error",
                title: "Erro ao finalizar a prova",
                text: error.message,
                allowOutsideClick: false
            });
        }

        setSelectedOptions({}); // Limpa as respostas selecionadas ao finalizar a prova
        setTimerActive(false); // Desativa o timer ao finalizar a prova
        setShowPauseMessage(true); // Mostra a mensagem de pausa ao finalizar a prova
        setIsRequest(false);
    };


    const selectAnswer = (
        event: React.ChangeEvent<HTMLInputElement>,
        questionId: number
    ) => {
        const value = event.target.value;
        setSelectedOptions((prevSelectedOptions) => {
            const questionOptions = prevSelectedOptions[questionId] || [];
            if (event.target.type === "checkbox") {
                if (questionOptions.includes(value)) {
                    return {
                        ...prevSelectedOptions,
                        [questionId]: questionOptions.filter((option) => option !== value),
                    };
                } else {
                    return {
                        ...prevSelectedOptions,
                        [questionId]: [...questionOptions, value],
                    };
                }
            } else if (event.target.type === "radio") {
                return {
                    ...prevSelectedOptions,
                    [questionId]: [value],
                };
            } else {
                return prevSelectedOptions;
            }
        });
    };

    const handlePauseTimer = () => {
        if (pauseCount === 0) {
            setTimerActive(false); // Pausa o timer
            setPauseCount(pauseCount + 1); // Incrementa o contador de pausas
            setShowPauseMessage(false); // Esconde a mensagem de pausa
        } else {
            Swal.fire({
                icon: "info",
                title: "Limite de pausas alcançado",
                text: "Você atingiu o limite de pausas permitido.",
            });
        }
    };

    if (loading) {
        return (
            <Modal show={show} onHide={handleClose} size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Carregando questões...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Carregando...</p>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal show={show} onHide={() => closeExam()} size="xl" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Prova do Curso: {course?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column align-items-start">
                {questions.map((question, index) => (
                    <div
                        key={question.id}
                        style={{
                            display: index === currentQuestionIndex ? "block" : "none",
                        }}
                    >
                        <div className="modal-pergunta">
                            <div
                                className="p_normal fw-bold fs-5 lh-sn"
                                dangerouslySetInnerHTML={{ __html: question.question }}
                            />
                        </div>
                        {question.imageName !== null && (
                            <img src={`https://nyc3.digitaloceanspaces.com/test-fatecoins-bucket/${question.imageName}`} alt="Imagem da questão" />
                        )}
                        <FormGroup>
                            {question.type === "MULTIPLA_ESCOLHA" ? (
                                <>
                                    {["A", "B", "C", "D"].map((option, index) => (
                                        <FormControlLabel
                                            key={option}
                                            control={
                                                <Checkbox
                                                    checked={(
                                                        selectedOptions[question.id] || []
                                                    ).includes(option)}
                                                    onChange={(event) => selectAnswer(event, question.id)}
                                                    value={option}
                                                />
                                            }
                                            label={
                                                question[`alternative${index + 1}` as keyof IQuizQuestion]
                                            }
                                        />
                                    ))}
                                </>
                            ) : (
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        value={(selectedOptions[question.id] || [])[0] || ""}
                                        onChange={(event) => selectAnswer(event, question.id)}
                                    >
                                        {["A", "B", "C", "D"].map((option, index) => (
                                            <FormControlLabel
                                                key={option}
                                                value={option}
                                                control={<Radio />}
                                                label={
                                                    question[
                                                    `alternative${index + 1}` as keyof IQuizQuestion
                                                    ]
                                                }
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            )}
                        </FormGroup>
                        <div className="space"></div>
                        {provaIniciada && (
                            <div className="p_bold">
                                <p
                                    style={{
                                        position: "absolute",
                                        bottom: "20px",
                                        right: "20px",
                                        background: "#f3f4f6",
                                        padding: "10px",
                                        borderRadius: "10px",
                                    }}
                                >
                                    {`Questão: ${index + 1} de ${questions.length}`}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "80%",
                                                height: "10px",
                                                background: "#007bff",
                                                borderRadius: "5px",
                                                position: "relative",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: `min(${((timerSeconds / getTimerSecondsByDifficulty(questions[index].difficultyLevel)) * 100)}% , 100%)`,
                                                    height: "100%",
                                                    background: "#28a745",
                                                    borderRadius: "5px",
                                                }}
                                            ></div>
                                        </div>
                                        <span>{timerSeconds}s</span>
                                    </div>
                                </p>
                            </div>
                        )}
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                {showPauseMessage && (
                    <button
                        className="btn_tertiary" // Adiciona a classe de animação
                        onClick={handlePauseTimer}

                    >
                        Pausar Prova
                    </button>
                )}
                <button className="btn_secondary" onClick={handleAnswerQuestion} disabled={isRequest}>
                    {currentQuestionIndex === questions.length - 1
                        ? "Finalizar Prova"
                        : "Responder"}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalTotalCourseQuestion;
