import { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import SubmitForm from "./SubmitForm";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import companyService from "../../services/CompanyService";
import swal from "sweetalert";
import { CNPJMaskInput } from "../../components/CNPJMaskInput/CNPJMaskInput";
import { RegisterType } from "../../shared/enum";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Select,
  
} from "@mui/material";
import { MenuItem } from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";

function Company() {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  // const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const [isLoading, setLoading] = useState(false);

  const location = useLocation();
  const [path, setPath] = useState(location.state?.path);

  const navigate = useNavigate();

  const [type, setType] = useState<"password" | "text">("password");
  const [confPasswordType, setConfPasswordType] = useState<"password" | "text">(
    "password"
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      nameEmpresa: "",
      razaoSocial: "",
      cnpj: "",
      cidade: "",
      estado: "",
      webSite: "",
      linkedin: "",
      confPassword: "",
      acceptTerms: false,
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("E-mail inválido")
        .required("É necessário preencher o campo e-mail"),
      password: Yup.string()
        .min(6, "Senha deve ter no mínimo 6 caracteres")
        // .matches(
        //   passwordRules,
        //   "Crie uma senha forte! A senha deve conter no mínimo dois números, um caractere especial, uma letra maiúscula, uma letra minúscula"
        // )
        .required("É necessário preencher o campo senha"),
      confPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Senha não corresponde")
        .required("É necessário confirmar a senha"),
      nameEmpresa: Yup.string().required("É necessário preencher o campo nome"),
      razaoSocial: Yup.string().required(
        "É necessário preencher a razão social"
      ),
      cnpj: Yup.string().required("É necessário preencher o CNPJ"),
      cidade: Yup.string().required("É necessário preencher com a Cidade"),
      estado: Yup.string().required("É necessário preencher o Estado"),
      webSite: Yup.string(),
      // linkedin: Yup.string().required("É necessário preencher linkedin"),

      acceptTerms: Yup.bool().oneOf(
        [true],
        "É necessário aceitar os termos e condições"
      ),
    }),

    onSubmit: (values) => {
      create(values);
    },
  });

  const create = (values: any) => {
    let data = {
      fantasy_name: values.nameEmpresa,
      official_name: values.razaoSocial,
      official_document: values.cnpj,
      city: values.cidade,
      state: values.estado,
      country: "BRASIL",
      website: values.webSite,
      linkedin: values.linkedin,
      email: values.email,
      password: values.password,
      type: RegisterType.COMPANY,
    };

    companyService
      .create(data)
      .then((response) => {
        setLoading(false);

        swal({
          title: "Cadastro de Empresa realizado com sucesso!",
          text: "Você será redirecionado à página de Login",
          icon: "success",
        }).then((value: any) => {
          navigate("/");       
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const cancel = () => {
    navigate("/");
  };

  return (
    <div className="container-1 bg" >
      <div>
        {isSubmitSuccess ? (
          <SubmitForm />
        ) : (
          <form className="container-cadastro-empresa"
            onSubmit={formik.handleSubmit}
           
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img className="img-cadastro-empresa"
                src="assets/images/logo_IV.png"
                alt="fatecoins"
               
              />
            </div>
            {/* <div className="logo-animado">
              <h2>
                Empresas podem testar grátis o Talent durante 48 horas para
                buscar talentos
              </h2>
            </div> */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div className="title">Cadastro Empresa</div>
            </div>

            <div className="space"></div>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <label className="label-cadastro">
                  Nome fantasia
                </label>
                <TextField
                  variant="outlined"
                  required
                  focused
                  margin="normal"
                  fullWidth
                  name="nameEmpresa"
                  type="text"
                  placeholder="Nome Fantasia"
                  className="textField"
                  InputProps={{
                    startAdornment: <></>,
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nameEmpresa}
                />
                {formik.touched.nameEmpresa && formik.errors.nameEmpresa ? (
                  <div className="error_msg">{formik.errors.nameEmpresa}</div>
                ) : null}
              </Grid>

              <Grid item xs={6}>
                <label className="label-cadastro">
                  Razão social
                </label>
                <TextField
                  variant="outlined"
                  required
                  margin="normal"
                  fullWidth
                  name="razaoSocial"
                  type="text"
                  placeholder="Razão Social"
                  className="textField"
                  InputProps={{
                    startAdornment: <></>,
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.razaoSocial}
                />
                {formik.touched.razaoSocial && formik.errors.razaoSocial ? (
                  <div className="error_msg">{formik.errors.razaoSocial}</div>
                ) : null}
              </Grid>

              <Grid item xs={6}>
                <label className="label-cadastro">
                  CNPJ
                </label>
                <TextField
                  variant="outlined"
                  required
                  margin="normal"
                  fullWidth
                  name="cnpj"
                  type="text"
                  placeholder="CNPJ"
                  className="textField"
                  InputProps={{
                    inputComponent: CNPJMaskInput,
                    value: formik.values.cnpj,
                    onChange: formik.handleChange,
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnpj}
                />
                {formik.touched.cnpj && formik.errors.cnpj ? (
                  <div className="error_msg">{formik.errors.cnpj}</div>
                ) : null}
              </Grid>

              <Grid item xs={6}>
                <label className="label-cadastro">
                  Cidade
                </label>
                <TextField
                  variant="outlined"
                  required
                  margin="normal"
                  fullWidth
                  name="cidade"
                  type="text"
                  placeholder="Cidade"
                  className="textField"
                  InputProps={{
                    startAdornment: <></>,
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cidade}
                />
                {formik.touched.cidade && formik.errors.cidade ? (
                  <div className="error_msg">{formik.errors.cidade}</div>
                ) : null}
              </Grid>

              <Grid item xs={6}>
                <label className="label-cadastro">
                  Estado
                </label>
                <Select
                  variant="outlined"
                  required
                  id="uncontrolled-native"
                  name="estado"
                  value={formik.values.estado}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  style={{
                    width: "100%",
                   
                    textAlign: "left",
                    marginTop: "9px",
                    height: "60%",
                  }}
                >
                  <MenuItem value={""} disabled>
                    Estado
                  </MenuItem>
                  <MenuItem value={"AC"}>Acre</MenuItem>
                  <MenuItem value={"AL"}>Alagoas</MenuItem>
                  <MenuItem value={"AP"}>Amapá</MenuItem>
                  <MenuItem value={"AM"}>Amazonas</MenuItem>
                  <MenuItem value={"BA"}>Bahia</MenuItem>
                  <MenuItem value={"CE"}>Ceará</MenuItem>
                  <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                  <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                  <MenuItem value={"GO"}>Goiás</MenuItem>
                  <MenuItem value={"MA"}>Maranhão</MenuItem>
                  <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                  <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                  <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                  <MenuItem value={"PA"}>Pará</MenuItem>
                  <MenuItem value={"PB"}>Paraíba</MenuItem>
                  <MenuItem value={"PR"}>Paraná</MenuItem>
                  <MenuItem value={"PE"}>Pernambuco</MenuItem>
                  <MenuItem value={"PI"}>Piauí</MenuItem>
                  <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                  <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                  <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                  <MenuItem value={"RO"}>Rondônia</MenuItem>
                  <MenuItem value={"RR"}>Roraima</MenuItem>
                  <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                  <MenuItem value={"SP"}>São Paulo</MenuItem>
                  <MenuItem value={"SE"}>Sergipe</MenuItem>
                  <MenuItem value={"TO"}>Tocantins</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={6}>
                <label className="label-cadastro">
                  Website
                </label>
                <TextField
                  variant="outlined"
                  required
                  margin="normal"
                  fullWidth
                  name="webSite"
                  type="text"
                  placeholder="Website"
                  className="textField"
                  InputProps={{
                    startAdornment: <></>,
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.webSite}
                />
                {formik.touched.webSite && formik.errors.webSite ? (
                  <div className="error_msg">{formik.errors.webSite}</div>
                ) : null}
              </Grid>

              <Grid item xs={6}>
                <label className="label-cadastro">
                  Linkedin
                </label>
                <TextField
                  variant="outlined"                
                  margin="normal"
                  fullWidth
                  name="linkedin"
                  type="text"
                  placeholder="linkedin"
                  className="textField"
                  InputProps={{
                    startAdornment: <></>,
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.linkedin}
                />
                {/* {formik.touched.linkedin && formik.errors.linkedin ? (
              <div className="error_msg">{formik.errors.linkedin}</div>
            ) : null} */}
              </Grid>

              <Grid item xs={6}>
                <label className="label-cadastro">
                  E-mail
                </label>
                <TextField
                  variant="outlined"
                  required
                  margin="normal"
                  fullWidth
                  name="email"
                  type="text"
                  placeholder="e-mail"
                  className="textField"
                  InputProps={{
                    startAdornment: <></>,
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="error_msg">{formik.errors.email}</div>
                ) : null}
              </Grid>

              <Grid item xs={6}>
                <label className="label-cadastro">
                  Senha
                </label>
                <OutlinedInput
                  required
                  name={"password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          if (type === "text") {
                            setType("password");
                          } else {
                            setType("text");
                          }
                        }}
                        edge="end"
                      >
                        {type === "text" ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  type={type}
                  placeholder="Senha"
                  className="textField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="error_msg">{formik.errors.password}</div>
                ) : null}
              </Grid>

              <Grid item xs={6}>
                <label className="label-cadastro">
                  Confirme sua senha
                </label>
                <OutlinedInput
                  required
                  name={"confPassword"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          if (confPasswordType === "text") {
                            setConfPasswordType("password");
                          } else {
                            setConfPasswordType("text");
                          }
                        }}
                        edge="end"
                      >
                        {confPasswordType === "text" ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  type={confPasswordType}
                  placeholder="Confirmar senha"
                  className="textField"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confPassword}
                />
                {formik.touched.confPassword && formik.errors.confPassword ? (
                  <div className="error_msg">{formik.errors.confPassword}</div>
                ) : null}
              </Grid>
            </Grid>
            <div className="space"></div>
            <FormGroup>
              <FormControlLabel
                style={{ justifyContent: "center" }}
                control={<Checkbox checked={formik.values.acceptTerms} />}
                label={
                  <a href="/termos-de-uso-empresa">
                    Li e aceito os termos de uso
                  </a>
                }
                name="acceptTerms"
                onChange={formik.handleChange}
              />
              {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                <div className="error_msg">{formik.errors.acceptTerms}</div>
              ) : null}
            </FormGroup>
             <div className="space"></div>
            <button className="btn_primary full" type="submit">
              Cadastrar
            </button>
            <div className="space"></div>
            <button className="btn_tertiary" onClick={cancel}>
              Cancelar
            </button>
          </form>         
        )}
      </div>     
    </div>
  );
}

export default Company;
