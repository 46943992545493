import YouTube, { YouTubeProps } from "react-youtube";
import { ILesson } from "../interfaces/ILesson";
import lessonService from "../services/LessonService";
import { RefObject, useEffect, useRef } from "react";
import consumedCreditService from "../services/ConsumedCreditsService";

interface IReactVideoProps {
  videoId: string | undefined;
  lesson: ILesson | null;
  refreshLesson: any;
}

const ReactVideoPlayer = (props: IReactVideoProps) => {
  const playerRef = useRef<any>(null);
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    //console.log("Pronto");
  };

  const onPause: YouTubeProps["onPause"] = (event) => {
    // console.log("Pausou");
    let newTime = event.target.getCurrentTime();
    // console.log(newTime);

    lessonService.logEvent({
      id_lesson: props.lesson?.id,
      event_time: newTime,
      type: "PAUSE",
    });
  };

  const onPlay: YouTubeProps["onPlay"] = (event) => {
    // console.log("Deu play");
    let newTime = event.target.getCurrentTime();
    console.log(newTime);
    lessonService.logEvent({
      id_lesson: props.lesson?.id,
      event_time: newTime,
      type: "PLAY",
    });

    console.log("newTime: ", newTime)

    if (Math.floor(newTime) == 0){
      lessonService.start({
        id_lesson: props.lesson?.id,
      }).then((result) => {
        //console.log("Iniciou a aula")
      }).catch((error) => {
       // console.log(error, " ao iniciar a aula")
      })
    }
  };

  const onPlaybackRateChange: YouTubeProps["onPlaybackRateChange"] = (
    event
  ) => {
    // console.log("Mudou velocidade");
    // console.log(event.data);
    let newTime = event.target.getCurrentTime();
    // console.log(newTime);
    lessonService.logEvent({
      id_lesson: props.lesson?.id,
      event_time: newTime,
      type: "CHANGE_SPEED",
    });
  };

  const onStateChange: YouTubeProps["onStateChange"] = (event) => {
    console.log(event);
    console.log(event.target.getCurrentTime());
  };

  const onEnd: YouTubeProps["onEnd"] = (event) => {
    // console.log("Terminou o vídeo");
    let newTime = event.target.getCurrentTime();
    // console.log(newTime);
    lessonService.logEvent({
      id_lesson: props.lesson?.id,
      event_time: newTime,
      type: "FINISHED",
    });
    lessonService.completeLesson({
      id_lesson: props.lesson?.id,
    }).then((response) => {
      props.refreshLesson()
    }).catch((error) => {
      console.log(error)
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      playerRef.current.internalPlayer.getPlayerState().then((state: number) => {
        if (state == 1){ //se está tocando o vídeo
          //pega o tempo atual do vídeo e envia pro back
          playerRef.current.internalPlayer.getCurrentTime().then((currentTime: number) => {
            lessonService.logEvent({
              id_lesson: props.lesson?.id,
              event_time: currentTime,
              type: "WATCHING",
            });
          }).catch((error: any) => {
            console.log(error)
          });
        }
      }).catch((error: any) => {
        console.log(error)
      })
      
      
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const maxWidth = window.innerWidth * 0.5;

  const opts: YouTubeProps["opts"] = {
    height: (maxWidth * 9) / 16,
    width: maxWidth,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <YouTube   
      ref={playerRef}
      videoId={props.videoId}
      opts={opts}
      onReady={onPlayerReady}
      onPause={onPause}
      onPlaybackRateChange={onPlaybackRateChange}
      onPlay={onPlay}
      onEnd={onEnd}
      onStateChange={onStateChange}
      
    />
  );
};

export default ReactVideoPlayer;
