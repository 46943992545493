import axios from "axios";

class CompanyService {
  async create(data: any) {
    let API_URL = process.env.REACT_APP_API_URL;
    return axios({
      url: API_URL + "/companies/create",
      method: "POST",
      timeout: 5000,
      data: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
const companyService = new CompanyService();
export default companyService;
