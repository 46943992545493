import React, { createContext, useState, useContext, useCallback } from "react";
import { ICourse } from "../interfaces/ICourse";
import { ILesson } from "../interfaces/ILesson";
import courseService from "../services/CourseService";

interface CourseContextData {
  selectedCourse: ICourse | null;

  markAsSelectedCourse(course: ICourse): void;
  markAsNotSelectedCourse(): void;

  markAsNotSelectedLesson(): void;
  setLesson(lesson: ILesson): void;
  getLesson(): ILesson | null;


}

interface ProviderProps {
  children: any;
}

const CourseContext = createContext<CourseContextData>({} as CourseContextData);

export const CourseProvider: React.FC<ProviderProps> = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [selectedCourse, setSelectedCourse] = useState<ICourse | null>(null);
  const [selectedLesson, setSelectedLesson] = useState<ILesson | null>(null);

  function markAsSelectedCourse(course: ICourse) {
    setSelectedCourse(course);
  }

  function markAsNotSelectedCourse() {
    setSelectedCourse(null);
  }

  function markAsNotSelectedLesson() {
    localStorage.removeItem("lesson");
    setSelectedLesson(null);
  }

  function setLesson(lesson: ILesson) {
    setSelectedLesson(lesson);
    localStorage.setItem("lesson", JSON.stringify(lesson));
  }

  function getLesson(): ILesson | null {
    if (selectedLesson) {
      return selectedLesson;
    } else {
      const lesson = JSON.parse(localStorage.getItem("lesson") || "{}");
      setSelectedLesson(lesson);
      return lesson;
    }
  }


  return (
    <CourseContext.Provider
      value={{
        selectedCourse,
        markAsSelectedCourse,
        markAsNotSelectedCourse,
        setLesson,
        getLesson,
        markAsNotSelectedLesson,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

export default CourseContext;
