import Axios from "../shared/axios";


class SkillsService {
  async getAll(): Promise<any> {
    return Axios
      .get("skill")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
const skillsService = new SkillsService();
export default skillsService;
