import React, { useEffect, useState } from "react";
import "./index.scss";
import Table from "react-bootstrap/Table";
import interviewService from "../../services/InterviewService";
import { IInterview } from "../../interfaces/IInterview";
import Swal from "sweetalert2";
import { Button } from "@material-ui/core";

function ListInterviewsCompany() {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [interviews, setInterviews] = useState<IInterview[]>([]);

  useEffect(() => {
    interviewService.getByCompany()
      .then((response) => {
        setInterviews(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleStatusChange = (interviewId: number, newStatus: string) => {
    Swal.fire({
      title: "Deseja realmente alterar o status da entrevista?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#4caf50",
      cancelButtonColor: "#f44336",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleInterview(interviewId, newStatus === "pendente" ? "Y" : "N");
      }
    });
  };

  const toggleInterview = (idInterview: number, type: "Y" | "N") => {
    const data = { accepted: type };

    // Atualiza o status apenas da entrevista correspondente
    interviewService
      .toggleInterview(idInterview, data)
      .then(() => {
        setInterviews((prevInterviews) =>
          prevInterviews.map((interview) =>
            interview.id === idInterview
              ? { ...interview, accepted: type }
              : interview
          )
        );

        Swal.fire({
          title: "Status alterado com sucesso!",
          icon: "success",
        });
      })
      .catch(() => {
        Swal.fire({
          title: "Não foi possível alterar o status da entrevista!",
          icon: "error",
        });
      });
  };

  return (
    <div className="container-classes">
      <>
        <div
          className="player-buttons"
          style={{
            width: "1140px",
            margin: "0 auto",
            padding: "20px",
            background: "#F9FAFB",
            border: "1px solid #E9E9E9",
            marginTop: "5%",
          }}
        >
          <h2 className="title">Entrevistas marcadas</h2>
          <div className="space"></div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th scope="col">Candidato</th>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            {isLoading && (
              <img
                src="/assets/images/loading_II.gif"
                className="icon-loading"
                alt="carregando"
              />
            )}
            {interviews.length > 0 && (
              <tbody>
                {interviews.map((row) => (
                  <tr key={row.id}>
                    <td>{row.student.user.name}</td>
                    <td>{row.student.user.email}</td>
                    <td
                      style={{
                        cursor:
                          row.accepted === null || row.accepted === "Y"
                            ? "pointer"
                            : "auto",
                      }}
                      onClick={() => {
                        if (row.accepted === null) {
                          handleStatusChange(row.id, "pendente");
                        } else if (row.accepted === "Y") {
                          handleStatusChange(row.id, "cancelado");
                        }
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          style={{
                            borderRadius: "10%",
                            width: "100px",
                            height: "30px",
                            padding: 0,
                            backgroundColor:
                              row.accepted === "Y"
                                ? "rgba(0, 128, 0, 0.3)"
                                : row.accepted === null
                                ? "rgba(255, 255, 0, 0.5)"
                                : row.accepted === "N"
                                ? "rgba(235, 37, 37, 0.5)"
                                : "inherit",
                          }}
                        >
                          {row.accepted === null
                            ? "Aguardando"
                            : row.accepted === "Y"
                            ? "Confirmado"
                            : "Cancelado"}
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
            {interviews.length === 0 && !isLoading && (
              <p style={{ margin: 20 }}>Nenhuma entrevista solicitada.</p>
            )}
          </Table>
        </div>
      </>
    </div>
  );
}

export default ListInterviewsCompany;
