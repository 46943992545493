import React, { createContext, useState } from "react";
import ISubscription from "../interfaces/ISubscripition";
import paymentsService from "../services/PaymentsService";

interface SubscriptionData {
  subscription: ISubscription | null |undefined;  
  updateSubscription(): Promise<any>;
}

interface ProviderProps {
  children: any;
}

const SubscriptionContext = createContext<SubscriptionData>({} as SubscriptionData);

export const SubscriptionProvider: React.FC<ProviderProps> = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [subscription, setSubscription] = useState<ISubscription|null>();
  

  function updateSubscription() {
     return paymentsService
        .getSubscription()
        .then((response) => {
          setSubscription(response.data)
          return response.data
        })
        .catch((error) => {
          console.log(error);
         setSubscription(null)
        });
  }

 
  return (
    <SubscriptionContext.Provider
      value={{
        subscription, updateSubscription
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionContext;
