import { Fade } from 'react-awesome-reveal';

const SubmitForm = () => {
    return (
        <Fade delay={500}>
            <div className="submit-form">
            <h1>Sucesso!</h1>
            <p>Empresa cadastrada com sucesso!</p>
            </div>
        </Fade>
    )
}

export default SubmitForm
