import IPlan from "../interfaces/IPlan";
import ISubscription from "../interfaces/ISubscripition";
import Axios from "../shared/axios";

class LikeService {
  async findAll() {
    return Axios.get("like/find-all")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getOne(id: number) {
    return Axios.get("like/find/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        console.log(error);
        return Promise.reject(error);
      });
  }

  async getByUser(id: number) {
    return Axios.get("like/find-user/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  async create(data: any) {
    return Axios.post("/like/create", data)
      .then((response) => {
        // localStorage.setItem("TOKEN", response.data.access_token);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async changeLike(id: number) {
    return Axios.patch("like/change-like/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async delete(id: number) {
    return Axios.delete("like/like/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const likeService = new LikeService();
export default likeService;
