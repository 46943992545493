import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import Table from "react-bootstrap/Table";
import Box from "@mui/material/Box";
import { useContext, useEffect, useState } from "react";
import { Button, Divider } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import SubmitForm from "../Login/SubmitForm";
import consumedCreditService from "../../services/ConsumedCreditsService";
import UtilDate from "../../utils/util.date";
import Sidebar from "../../components/sidebar/Sidebar";
import { BiMenu } from "react-icons/bi";
import paymentsService from "../../services/PaymentsService";
import swal from "sweetalert";
import { planIdEnum } from "../../enums/plan-id.enum";
import ISubscription from "../../interfaces/ISubscripition";
import SubscriptionContext from "../../context/Subscription";

//month (mês), consumed_credit(cunsumo de creditos), bought_credit(crédito comprado).
interface ICredits {
  month: number;
  year: number;
  consumed_credit: number;
  bought_credit: number;
}

function ComsumedCredit() {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const { subscription, updateSubscription } = useContext(SubscriptionContext)
  //abaixo uma lista que armazena os dados.
  //observar que o ICredits é uma lista por isso precisa dos [].
  const [consumedCredits, setConsumedCredits] = useState<ICredits[]>([]);
  const [currentBalance, setCurrentBalance] = useState<number>(0);


  const PLAN_HUNTER = 1;
  const PLAN_HUNTER_MANAGER = 2;

  //oservar que deve ser criado o habito de copiar codigos, esse useEffect foi copiado de outro lugar, é com ele que
  //faz que quando carregar a tela fazer loadList.

  const getSubscription = () => {
    setLoading(true)
    updateSubscription().catch(() => setLoading(false))
  }

  useEffect(() => {
    // console.log(subscription)
    if (subscription !== undefined) {
      setLoading(false)
    }
  }, [subscription]);

  useEffect(() => {
    getSubscription()
    // loadList();
  }, []);

  const cancel = () => {
    navigate("/cadastroEmpresas");
  };

  const creditos = () => {
    swal({
      title: "Você realmente deseja trocar a assinatura?",
      buttons: ["sim", "não"],
    }).then((result) => {
      // console.log(result);
      if (result.isConfirmed) {
        paymentsService.getLastOneByUser().then((result) => {
          let id: number;
          if (result.data.plan === planIdEnum.HUNTER) {
            id = PLAN_HUNTER_MANAGER;
          } else {
            id = PLAN_HUNTER;
          }
          navigate("/payments/" + id);
        });
      } else if (result.isDenied) {
        return;
      }
    });
  };

  const createSignature = (idPlan: number) => {
    navigate("/payments/" + idPlan);
  };

  const loadList = () => {
    setLoading(true);
    consumedCreditService
      .getAll()
      .then((response) => {
        let credits: ICredits[] = response.data;
        //abaixo um codigo usando reduce, para subtrair consumed_credit - bought_credit, resultando o saldo de credito.

        let sum = credits.reduce(function (acc, obj) {
          return acc + (obj.bought_credit - obj.consumed_credit);
        }, 0);
        setCurrentBalance(sum);
        setConsumedCredits(response.data);
        updateSubscription().then(() => {
          setLoading(false)
        })
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const cancelSubscription = () => {
    swal({
      title: "Deseja realmente cancelar sua assinatura?",
      buttons: ["não", "sim"],
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result) {
        setLoading(true);
        return paymentsService
          .cancelSubscription()
          .then((result) => {
            swal({
              title: "Assinatura cancelada",
              text: "Sua assinatura foi cancelada com sucesso!",
              icon: "success",
            }).then((value: any) => {
              loadList();
              setLoading(false);
            });
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      } else {
        return;
      }
    });
  };

  const SignatureDetails = (props: { planId: string }) => {
    return (
      <div className="container-signature-details">
        {subscription && props.planId == subscription.plan.id && subscription.status === "active" &&
          <>
            <div className="container-details" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <div className="details">
                <strong>Assinado em: </strong>
                <span>{UtilDate.stringToDMY(subscription.start_at)}</span>
              </div>
              <div className="details">
                <strong>Próxima cobrança: </strong>
                <span>{UtilDate.stringToDMY(subscription.next_billing_at)}</span>
              </div>
            </div>
          </>
        }
      </div>
    )
  }


  return (
    <>

      <div id="02" className="container-consumed-credits" style={{ marginBottom: '25%' }}>
        <div className="assinatura" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <h1 className='title' style={{ color: '#413C69', fontSize: '40px' }}>Assinatura do Talent</h1>
          <p className="p_normal" style={{ textAlign: 'center' }}>Assine e tenha acesso a conteúdos váriados de tecnologia, desde<br /> a lógica de programação até o backend.</p>
        </div>
        {/* <h2 >+ de <span style={{fontWeight: 'bold', color:'#403c68'}}>1.500 profissionais</span> na plataforma</h2> */}
        <div className="space"></div>
        <div className="space"></div>
        {isLoading && <img src="/assets/images/loading_II.gif" className="icon-loading" alt="carregando" />}


        {!isLoading && (
          <div className="container-plans">
            <div className="container-plan">
              <h2 className="title">Plano Hunter</h2>
              <p className="p_normal"><span>R$ 89,00</span>/ mês</p>
              <p className='p_normal'>Para quem quer encontrar profissionais bem qualificados de acordo com sua demanda</p>
              <hr />
              <div className="container-description">
                <ul>
                  <li>
                    Encontre Talentos de acordo com as necessidades da sua
                    empresa;
                  </li>
                  <li>
                    <strong>Filtro inteligente</strong> de <strong>hard</strong> e <strong>soft skills</strong> baseado na
                    aprendizagem e no comportamento dos alunos na plataforma.
                  </li>
                </ul>
              </div>
              {/* <p className="price">R$ 89,00 / mês</p> */}

              {subscription && subscription.status === "active" && subscription.plan.id == planIdEnum.HUNTER && !isLoading && (
                <>
                  <button
                    className="btn_tertiary"
                    onClick={cancelSubscription}
                    style={{ width: "250px", marginBottom: 10, backgroundColor: "#c00", color: "#fff" }}
                  >
                    Cancelar assinatura
                  </button>
                </>
              )}

              {(!subscription || subscription.status !== "active") && !isLoading && (
                <button
                  className="btn_tertiary"
                  onClick={() => createSignature(PLAN_HUNTER)}
                  style={{ width: "250px" }}
                >
                  Escolher plano
                </button>
              )}

              <SignatureDetails planId={planIdEnum.HUNTER} />
            </div>
            <div className="container-plan">
              <h2 className="title">Plano Hunter + Manager</h2>
              <p className="p_normal"><span>R$ 189,00</span>/ mês</p>
              <p className='p_normal'>Para encontrar talentos e manter sua equipe sem capacitada e atualizada com as novas tendências do mercado tecnológico</p>
              <hr />
              <div className="container-description">
                <ul>
                  <li>
                    Encontre Talentos com um <strong>filtro inteligente</strong> de <strong>hard</strong> e <strong>soft
                      skills</strong>;
                  </li>
                  <li>Cadastre <strong>todos os colaboradores</strong> da sua empresa;</li>
                  <li>
                    Gerencie a aprendizagem dos colaboradores da sua empresa;
                  </li>
                  <li>
                    Acompanhe a <strong>evolução das habilidades</strong> de seus
                    colaboradores.
                  </li>
                </ul>
              </div>


              {subscription && subscription.status === "active" && subscription.plan.id == planIdEnum.HUNTER_MNG && !isLoading && (
                <>
                  <button
                    className="btn_tertiary"
                    style={{ backgroundColor: "#c00", color: "#fff" }}
                    onClick={cancelSubscription}
                  >
                    Cancelar assinatura
                  </button>
                </>
              )}
              <div className="space"></div>
              {(!subscription || subscription.status !== "active") && !isLoading && (
                <button
                  className="btn_primary"
                  onClick={() => createSignature(PLAN_HUNTER_MANAGER)}
                //style={{ width: "250px" }}
                >
                  Escolher plano
                </button>
              )}

              <SignatureDetails planId={planIdEnum.HUNTER_MNG} />
            </div>
          </div>
        )}

      </div>

    </>


  );
}

export default ComsumedCredit;
