import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useContext } from "react";
import "./index.scss";
import { ICourse } from "../../interfaces/ICourse";
import { ICareerPath } from "../../interfaces/ICareerPath";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import courseService from "../../services/CourseService";
import careerPathService from "../../services/CareerPathService";
import AuthContext from "../../context/auth";
import CourseContext from "../../context/CourseContext";
import ProgressBar from "@ramonak/react-progress-bar";
import SearchIcon from "@mui/icons-material/Search";
import { Grid } from "@material-ui/core";
import Container from "@mui/material/Container";
import { FaHome, FaPlay, FaRoute } from "react-icons/fa";
import studentsService from "../../services/StudentsService";
import Swal from "sweetalert2";
import LinkedinModal from "../../components/LinkedinModal/linkedinModal";
import AnswerQuestions from "@mui/icons-material/ContactSupport";
import ModalTotalCourseQuestion from "../../components/ModalTotalCourseQuestion/modalTotalCourseQuestion";
import { Button } from "react-bootstrap";
import { ExamsCreateDto } from "../../interfaces/IExam";
import quizQuestionAnswersService from "../../services/QuizQuestionsAnswersService";
import quizQuestionService from "../../services/QuizQuestionService";

function TelaCurses() {
  const [search, setSearch] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [careerPaths, setCareerPaths] = useState<ICareerPath[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [coursesFiltered, setCoursesFiltered] = useState<ICourse[]>([]);
  const { signed } = useContext(AuthContext);
  const [hasLinkedin, setHasLinkedin] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const { selectedCourse, markAsSelectedCourse, markAsNotSelectedCourse } =
    useContext(CourseContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedCourseForModal, setSelectedCourseForModal] =
    useState<ICourse | null>(null);
  const [selectedCourseValidateExam, setSelectedCourseValidateExam] =
    useState<ICourse | null>(null);
  const [student, setStudent] = useState(null);
  const [checkStudent, setCheckStudent] = useState<any>(null);

  const iniciar = () => {
    navigate("/Aula");
  };

  const loadList = () => {
    setLoading(true);
    courseService
      .getAll()
      .then((result) => {
        const student = result.data.student;
        if (student) {
          setIsStudent(true);
          setHasLinkedin(student.linkedin != null);
          if (!student.linkedin) {
            setShow(true);
          }
        } else {
          setIsStudent(false);
        }

        const coursesWithLoadedFlag: ICourse[] = result.data.courses;

        setCourses(coursesWithLoadedFlag);
        setCoursesFiltered(coursesWithLoadedFlag);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        console.log("Não foi possível carregar a lista de cursos");
      });
  };

  const loadCareerPaths = () => {
    careerPathService
      .getAllCareerWithPercentageByStudent()
      .then((result) => {
        setCareerPaths(result);
      })
      .catch((error) => {
        console.log("Erro ao carregar as trilhas de carreira:", error);
      });
  };

  useEffect(() => {
    loadList();
    loadCareerPaths();
  }, []);

  useEffect(() => {
    if (!showModal) {
      loadList();
    }
  }, [showModal, setShowModal]);

  useEffect(() => {
    let filtered: ICourse[] = [];

    if (!isLoading) {
      filtered = courses.filter((course: ICourse) => {
        // Normaliza a string removendo acentuação e convertendo para minúsculas
        const courseNameNormalized = course.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""); // Remove acentuação

        const searchNormalized = search
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""); // Remove acentuação da busca

        return courseNameNormalized.includes(searchNormalized);
      });
    }

    setCoursesFiltered(filtered);
  }, [search, courses, isLoading]);


  const access = (course: ICourse) => {
    let data: any = {
      id_course: course.id,
    };

    courseService
      .start(data)
      .then((result: any) => {
        // handle success
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        markAsSelectedCourse(course);
        navigate("/aula", {
          state: {
            course: course,
          },
        });
      });
  };

  const navigateToCareerPath = (id: number) => {
    console.log(id)
    navigate("/trilhas", {
      state: {
        idCareer: id,
      },
    });
  };

  const createLinkedin = () => {
    studentsService
      .createLinkedin(linkedin)
      .then(() => {
        setHasLinkedin(true);
        Swal.fire({
          icon: "success",
          title: "LinkedIn cadastrado com sucesso!",
          showConfirmButton: false,
          timer: 2000,
        });
        console.log("LinkedIn atualizado com sucesso");
      })
      .catch((error) => {
        console.log(error);
        console.log("Erro ao atualizar LinkedIn");
      });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleExamAlert = async (course: ICourse) => {
    let checkExam: any;

    await quizQuestionService
      .getByStudentAndCourseExam(course.id)
      .then((response) => {
        checkExam = response.data;
      });

    Swal.fire({
      title: "Você deseja realmente fazer a prova desse curso?",
      text: "Você só tem uma chance.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#fcb900",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      customClass: {
        confirmButton: "swal2-confirm",
        cancelButton: "swal2-cancel",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (!checkExam) {
          setSelectedCourseForModal(course);
          setShowModal(true);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Você já realizou a prova desse curso!",
            text: "Não é possível realizar a prova novamente.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  const checkStudentExam = async (id: number) => {
    await quizQuestionService.getByStudentAndCourseExam(id).then((response) => {
      return response.data;
    });
  };

  const getPercentageColor = (percentage: number) => {
    if (percentage === 100) return "#090";
    if (percentage >= 70) return "#c0eb00";
    if (percentage >= 50) return "#fcb900";
    if (percentage === 0) return "#ccc";

    return ""; // Cor padrão para outros valores
  };

  return (
    <Container className="mb-3" maxWidth="lg" id="page-courses" style={{ marginTop: 0 }}>
      <nav className="breadcrumb-talent" style={{ marginTop: 0 }}>
        <ol style={{ display: "flex", alignItems: "center" }}>
        <li style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
  <FaHome style={{ marginRight: "5px" }} />
  <a className="p_normal" href="/cursos">
    Início
  </a>
</li>

          <li>
            <a className="p_normal" href="">
              Meus cursos
            </a>
          </li>
          <li>
            <a className="p_normal" href="/minhas-skills">
              Minhas Skills
            </a>
          </li>
          <li>
            <a className="p_normal" href="/minhas-metrics">
              Minhas Métricas
            </a>
          </li>
          <li>
            <a className="p_normal" href="/student-collaboration">
              Colaboração
            </a>
          </li>
          
        </ol>
      </nav>

      <div className="space"></div>

      {/* Adicionado condicional para que somente apareça a Trilha se estiver cadastrada */}
      {careerPaths.length > 0 && (
        <div>
          <h2 className="title" style={{ marginLeft: "40px" }}>
            Trilhas profissionais
          </h2>
          <Grid container spacing={2}>
            {careerPaths.map((item: ICareerPath) => (
              <Grid item xs={12} sm={6} key={item.id}>
                <Card
                  className={`trilhas-profissionais ${item.title
                    .replace(/\s+/g, "-")
                    .toLowerCase()}`}
                >
                  <CardContent className="trilha-content">
                    <div className="title" style={{ textAlign: "center" }}>
                      {item.title}
                    </div>
                    <div className="space"></div>
                    <ProgressBar
                      width="80%"
                      margin="auto"
                      height="10px"
                      bgColor={item.percentage! >= 100 ? "#00FF00" : "#FFA500"}
                      completed={
                        item.percentage ? Math.round(item.percentage) : 0
                      }
                      labelColor="rgba(0, 0, 0, 0.5)"
                      labelSize="10px"
                    />
                    <div className="space"></div>
                    <div
                      className="trilha-button"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <button
                        className="btn_primary"
                        style={{ width: "80%" }}
                        onClick={() => navigateToCareerPath(item.id)} // id dinâmico, convertido para string
                      >
                        Ver Trilha
                        <FaRoute
                          style={{ marginLeft: "8px", fontSize: "20px" }}
                        />
                      </button>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      <div className="space"></div>

      <div className="App">
        {signed && isStudent && !hasLinkedin && (
          <LinkedinModal show={show} handleClose={handleClose} />
        )}

        <Grid container className="container-courses">
          <h2 className="title">Todos os cursos</h2>
          <div className="container-search">
            <TextField
              variant="outlined"
              fullWidth
              name="pesquisa"
              className="textField"
              placeholder="Buscar por curso"
              type="text"
              value={search}
              onChange={(ev) => setSearch(ev.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon style={{ fontSize: "40px" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <main className="list-cursos" style={{ justifyContent: "center" }}>
            {isLoading && (
              <img
                src="/assets/images/loading_II.gif"
                width="50px"
                height="50px"
                className="icon-loading"
                alt="carregando"
              />
            )}
            {coursesFiltered?.map((course: ICourse) => {
              return (
                <Card
                  key={course.id}
                  style={{
                    maxWidth: 300,
                    marginBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardActionArea
                    style={{
                      pointerEvents: "none",
                      cursor: "default",
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <CardMedia
                      style={{
                        margin: "auto",
                        width: "auto",
                        textAlign: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      component="img"
                      alt={course.name}
                      height="140"
                      image={course.image}
                      title={course.name}
                    />
                    <CardContent style={{ flex: 1 }}>
                      <div className="p_bold">
                        {course.name} -
                        {course.totalDuration !== undefined
                          ? ` ${course.totalDuration} horas`
                          : " Carregando..."}
                      </div>
                    </CardContent>
                  </CardActionArea>
                  {course.studentsCourses.length === 0 && (
                    <div className="start-button">
                      <button
                        className="btn_primary"
                        onClick={() => access(course)}
                      >
                        Iniciar curso
                      </button>
                    </div>
                  )}

                  <div className="start">
                    {course.studentsCourses.length > 0 &&
                      course.percentage !== undefined && (
                        <>
                          <ProgressBar
                            width="80%"
                            margin="auto"
                            height="10px"
                            bgColor={
                              course.percentage >= 100 ? "#00FF00" : "#FFA500"
                            }
                            completed={
                              course.percentage
                                ? Math.round(course.percentage)
                                : 0
                            }
                            labelColor="rgba(0, 0, 0, 0.5)"
                            labelSize="10px"
                          />

                          <div className="space"></div>
                          <div className="central-button">
                            <button
                              className="btn_secondary"
                              onClick={() => access(course)}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {course.percentage && course.percentage >= 100
                                ? "Rever o curso"
                                : "Continuar curso"}
                              <FaPlay style={{ marginLeft: "5px" }} />
                            </button>
                          </div>

                          <div className="space"></div>
                        </>
                      )}

                    <div className="central-button">
                      <button
                        className="btn_primary"
                        onClick={() => handleExamAlert(course)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Prova do curso
                        <AnswerQuestions style={{ marginLeft: "5px" }} />
                      </button>
                      {typeof course.questionExamPercentage === 'number' && (
  <div className="central-button">
    <span style={{ color: getPercentageColor(course.questionExamPercentage), fontWeight: "bold", marginTop: "8px" }}>
      Aproveitamento: {Math.min(100, course.questionExamPercentage).toFixed(2)} %
    </span>
  </div>
)}

                    </div>
                    <div className="space"></div>
                  </div>
                </Card>
              );
            })}
          </main>
        </Grid>
      </div>

      <ModalTotalCourseQuestion
        show={showModal}
        handleClose={() => setShowModal(false)}
        course={selectedCourseForModal}
        student={student}
      />
    </Container>
  );
}

export default TelaCurses;
