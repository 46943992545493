import "./index.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <h4 className="footer_logo">
        <img src="/coin.png" width="45" height="45" alt="logoFatecoins" />
        FATECOINS TALENT
      </h4>
      <p className="direitos-fatecoins">
        Todos os direitos reservados - Talent Fatecoins &copy;2024
      </p>
    </footer>

  );
};

export default Footer;
