import { Fade } from 'react-awesome-reveal';

const SubmitForm = () => {
    return (
        <Fade delay={500}>
            <div className="submit-form">
            <h1> Sejam Bem vindos!! </h1>
            <p> Você foi logado com sucesso. </p>
            </div>
        </Fade>
    )
}
export default SubmitForm
