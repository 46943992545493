import { Col, Row } from "react-bootstrap"
import { Outlet } from "react-router-dom"
import Header from "../header"
import Sidebar from "../sidebar/Sidebar"


const AppLayout = () => {
    return <div style={{}}>
        <Row>
            <Header />
        </Row>

        <Row>
            <Col xs={9}>
                <Outlet />
            </Col>
            <Col xs={3}>
                <Sidebar />
            </Col>
        </Row>
    </div>
}

export default AppLayout