import IPlan from "../interfaces/IPlan";
import ISubscription from "../interfaces/ISubscripition";
import Axios from "../shared/axios";

class PaymentsService {
  async create(data: any) {
    return Axios.post("mp/create-payment", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async createSignature(data: any) {
    return Axios.post("mp/create-signature", data)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((error) => {
      console.log(error)
      return Promise.reject(error);
    });
  }

  async findSignature() {
    return Axios.get("mp/find-signature")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getPlan(id:string) {
    return Axios.get<IPlan>("mp/find-plan/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async cancelSubscription() {
    return Axios.delete("mp/cancel/")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getLastOneByUser() {
    return Axios.get("payment/find-payment")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getSubscription() {
    return Axios.get<ISubscription>("mp/find-signature/")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getCep(cep: string) {
    return Axios.get("payment/cep/" + cep)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

}

const paymentsService = new PaymentsService();
export default paymentsService;
