import { Button, TextField } from "@material-ui/core";
import "./index.scss";
import { useFormik } from "formik";
import ICreateComment from "../../interfaces/ICreateComment";
import { useState } from "react";
import lessonService from "../../services/LessonService";
import swal from "sweetalert";
import {IQuestion} from "../../interfaces/IQuestion";

const CommentForm = (props: {
  question: IQuestion;
  callback: () => void;
  closecomment: () => void;
}) => {
  const [isLoading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      text: "",
    },

    onSubmit: (values: ICreateComment, { resetForm }) => {
      sendComment(values);
      resetForm();
    },
  });

  const sendComment = (values: ICreateComment) => {
    let data = {
      question: props.question?.id,
      text: values.text,
    };

    lessonService
      .comment(data)
      .then((response) => {
        setLoading(false);
        swal({
          title: "Comentário enviado!",
          icon: "success",
        }).then((value: any) => {
          props.callback();
          props.closecomment();
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <form className="form-comments"  onSubmit={formik.handleSubmit} >
      <div className="textArea2">
        <TextField
          name="text"
          type="text"
          placeholder="Escreva sua resposta aqui"
          multiline
          required
          autoFocus
          rows={4}
          variant="outlined"
          className="textField"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.text}
          style={{ backgroundColor: "#E5E7EB" }}
        />
      </div>

      
        <div>
        <button
          className="btn_tertiary"  
          onClick={() => props.closecomment()}
          style={{ marginRight: '15px' }}
        >
          Cancelar
        </button>
      
        <button className="btn_secondary" type="submit">
          Enviar
        </button>
        </div>
    
    </form>
  );
};
export default CommentForm;
