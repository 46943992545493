import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { useFormik, FormikHelpers as FormikActions } from "formik";
import * as Yup from "yup";
import usersService from "../../services/UsersService";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import SubmitForm from "./SubmitForm";
import AuthContext from "../../context/auth";
import "./modal.scss";
import { Modal, Table } from "react-bootstrap";
import { IStudent } from "../../interfaces/IStudent";
import { CheckCircle, Search } from "@material-ui/icons";
import studentsService from "../../services/StudentsService";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import SearchIcon from '@mui/icons-material/Search';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(0),
  },
}));

export interface Modal {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function CustomizedDialogs({
  loadList,
  disabled,
}: {
  loadList: () => Promise<void>;
  disabled: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false); 

  const [search, setSearch] = useState(false);

  const [students, setStudents] = useState<IStudent[]>([]);

  const [value, setValue] = useState<string>("");

  const handleClickOpen = () => {
    setOpen(true);
    
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getStudents = () => {
    studentsService.searchStudent(value).then((response) => {
      setStudents(response.data);
    });
  };
  useEffect(() => {
    if (value.length > 2) {
      getStudents();
     
    }else{
      setStudents([])
    }
  }, [value]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
      confPassword: "",
      acceptTerms: false,
    },

    
    validationSchema: Yup.object({
      email: Yup.string()
        .email("E-mail inválido")
        .required("É necessário preencher o campo e-mail"),
      password: Yup.string()
        .min(6, "Senha deve ter no mínimo 6 caracteres")
        // .matches(
        //   passwordRules,
        //   "Crie uma senha forte! A senha deve conter no mínimo dois números, um caractere especial, uma letra maiúscula, uma letra minúscula"
        // )
        .required("É necessário preencher o campo senha"),
      confPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Senha devem ser iguais")
        .required("É necessário confirmar a senha"),
      name: Yup.string().required("É necessário preencher o campo nome"),
    }),

    

    onSubmit: async (values, { resetForm }) => {
      await create(values);
      resetForm();
    },
    
  });
  const resetForm = () => {     
    formik.resetForm();
    // formik.setStatus("")
    formik.setTouched({}, false);
    // formik.setErrors({})
  };

  const navigate = useNavigate();

  const create = async (values: any) => {
   
    let data = {
      name: values.name,
      email: values.email,
      password: values.password,
      // id_company: id,
    };

    usersService
      .create(data)
      .then((response) => {
        setLoading(false);
        swal({
          title: "Cadastro realizado com sucesso!",
          icon: "success",
        }).then(async (value: any) => {
          loadList().then(() => {
            handleClose();
          });
        });
      })
      .catch((error) => {
        setLoading(false);
        swal({
          title: "Houve um erro no cadastro do colaborador",
          icon: "error",
        }).then(async (value: any) => {
          //
        });
      });
  };

  const addRelation = (id: number) => {
    studentsService.addCompany(id).then(() => {
      setValue("");
      setStudents([])
      loadList().then(() => {
        handleClose();
      })
     
    });
  };
  const handleCancel = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault(); 
    resetForm(); // Limpa o formulário
    handleClose(); // Fecha o modal
  };

  return (
    <div>
      <div>
       
          <button         
            className="btn_primary"
            //variant="contained"
            onClick={handleClickOpen}
            disabled={disabled}
          >
            Novo colaborador
          </button>
          <div className="space"></div>
       

        <>
          <Modal onHide={handleClose} size="lg" show={open} >
            {search && (
              <>
                {/* <Modal.Header>
                  <div>
                    <div>
                      <button
                        className="btn_secondary"
                       // variant="contained"
                        onClick={() => setSearch(false)}
                        disabled={disabled}
                      >
                        Cadastrar
                      </button>
                    </div>
                  </div>
                </Modal.Header> */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px', textAlign: 'justify' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <p className="subtitle">Pesquise o email do seu colaborador,</p>
                      <p className="subtitle">não encontrando, cadastre o colaborador no botão abaixo.</p>
                    </div>
                  </div>
                <Modal.Body className='modal-body modal-custom-width' >
                  <OutlinedInput
                    name="value"
                    type="text"
                    className="textField"
                    placeholder="Email do colaborador"
                    startAdornment={<SearchIcon style={{ marginRight: 8 }} />}
                    onChange={(e) => setValue(e.target.value)}
                    value={value}                
                  />
                    
                  <div className="space"></div>
                  {students && (
                    <Table striped bordered style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th align="center">Email</th>
                          <th align="center">selecionar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {students.map((row) => (
                          <tr>
                            <td>{row.user.name}</td>
                            <td>{row.user.email}</td>
                            <td>
                              <IconButton 
                              
                               onClick={() => addRelation(row.id)}>
                                <CheckCircleTwoToneIcon  />
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>                    
                  )}
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <button
                      className="btn_secondary"
                      onClick={() => setSearch(false)}
                      disabled={disabled}
                    >
                      Cadastrar
                    </button>
                  </Modal.Footer>
              </>
            )}
            {!search && (
              <>
                <Modal.Header>
                  <div>
                    
                      <button
                        className="btn_secondary"
                       // variant="contained"
                        onClick={() => setSearch(true)}
                        disabled={disabled}
                      >
                        Buscar
                      </button>
                 
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div
                    className={
                      !isSubmitSuccess
                        ? "signin signin_wrapper"
                        : "signin signin_success"
                    }
                    style={{ width: "100%" }}
                  >
                    {isSubmitSuccess ? (
                      <SubmitForm />
                    ) : (
                      
                      <form className="form-container" onSubmit={formik.handleSubmit} >
                        
                          <div className="img" style={{display:'flex', justifyContent:'center'}}>
                            <img src="assets/images/logo_IV.png" alt="fatecoins" />
                          </div>
                          <div className="space"></div>
                          <div className="title-container">
                            <h2 className="title">Cadastro de colaborador</h2>
                          </div>

                          <div className="input-container">
                            <div className="input-wrapper">
                              <p className="p_bold" style={{margin:'0 auto'}}>Nome completo</p>
                              <OutlinedInput
                                name="name"
                                type="text"
                                placeholder="Nome completo"
                                className="textField"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="error_msg">{formik.errors.name}</div>
                              ) : null}
                            </div>
                           
                            <div className="input-wrapper">
                            <p className="p_bold" style={{margin:'0 auto'}}>Email</p>
                              <OutlinedInput
                                name="email"
                                type="text"
                                placeholder="E-mail"
                                className="textField"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <div className="error_msg">{formik.errors.email}</div>
                              ) : null}
                            </div>
                          </div>
                           <div className="space"></div>
                          <div className="input-container">
                            <div className="input-wrapper">
                            <p className="p_bold" style={{margin:'0 auto'}}>Senha</p>
                              <OutlinedInput
                                name="password"
                                type="password"
                                placeholder="Senha"
                                className="textField"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                              />
                              {formik.touched.password && formik.errors.password ? (
                                <div className="error_msg">{formik.errors.password}</div>
                              ) : null}
                            </div>

                            <div className="input-wrapper">
                            <p className="p_bold" style={{margin:'0 auto'}}>Confirma a senha</p>
                              <OutlinedInput
                                name="confPassword"
                                type="password"
                                placeholder="Confirmar senha"
                                className="textField"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confPassword}
                              />
                              {formik.touched.confPassword && formik.errors.confPassword ? (
                                <div className="error_msg">{formik.errors.confPassword}</div>
                              ) : null}
                            </div>
                          </div>

                          <div className="space"></div>

                             <div className="bot" style={{ display:'flex', alignItems: 'center', justifyContent: 'center'} }>
                            <div >
                            <button className="btn_secondary" type="submit" style={{marginRight:'15px'}} >
                              Cadastrar
                            </button>
                            </div>
                               <div>
                              <button className="btn_tertiary" onClick={handleCancel} style={{marginRight:'20px'}}>
                                Cancelar
                              </button>
                              </div>
                              </div>
                         
                          
                        </form>

                    )}
                  </div>
                </Modal.Body>
              </>
            )}
          </Modal>
        </>
      </div>
    </div>
  );
}
