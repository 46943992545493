import { AxiosResponse } from "axios";
import { ExamsCreateDto } from "../interfaces/IExam";
import Axios from "../shared/axios";
import api from "./api";
import ICreateComment from "../interfaces/ICreateComment";
import { ICommentCollaborationsCreate } from "../interfaces/ICommentCollaborationsCreate";
import { Question } from "../pages/StudentCollaboration";

class QuizQuestionService {
  async getAll(id: number): Promise<any> {
    return api
      .get(`quiz-questions/all-by-lesson/${id}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async getOne(id: number): Promise<any> {
    return Axios.get(`/quiz-questions/find?lesson=${id}`)
      .then((result) => {
        console.log("LOG RESULT: ", result);
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getAllRandomByCourse(courseId: number): Promise<any> {
    let token = localStorage.getItem("TOKEN");
    const headers = { Authorization: "Bearer " + token };
    return Axios.get(`quiz-questions/${courseId}/random-quiz-questions`, {
      headers,
    })

      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  // async createStudentExam(@Request() req, @Body() createExamDto: ExamsCreateDto): Promise<AxiosResponse> {
  //     createExamDto.idStudent = req.user.id;
  //     return Axios.post('student-exams', createExamDto);
  // }

  async createStudentExam(data: any): Promise<AxiosResponse> {
    return Axios.post("exams/create-student-exams", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async finishExam(id: number, data: any): Promise<AxiosResponse> {
    return Axios.put(`exams/finish-exam/${id}`, data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async createQuestionExam(data: any): Promise<AxiosResponse> {
    return Axios.post("quiz-questions/create-questions-exams", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getStudentExam(id: number): Promise<any> {
    return Axios.get(`exams/${id}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getByStudentAndCourseExam(idCourse: number): Promise<any> {
    let token = localStorage.getItem("TOKEN");
    const headers = { Authorization: "Bearer " + token };

    return Axios.get(`exams/findByStudentAndCourse/${idCourse}`, { headers })

      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  // Método para buscar todas as perguntas e colaborar com respostas
  async getAllUnansweredQuestions(): Promise<AxiosResponse> {
    return Axios.get("/questions/unanswered")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  //Enviarem comentarios.
  async comment(data: ICreateComment) {
    let token = localStorage.getItem("TOKEN");
    return Axios.post("/comments/create", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async saveCommentAfterQuestion(
    data: ICommentCollaborationsCreate
  ): Promise<Comment> {
    let token = localStorage.getItem("TOKEN");
    if (!token) {
      return Promise.reject({
        message: "TOKEN NÃO ENCONTRADO",
      });
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    return Axios.post("/comments/save-comment", data, { headers })
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getUnansweredQuestionsByCoursePaginated(
    courseId: number,
    page: number,
    limit: number
  ): Promise<Question[]> {
    let token = localStorage.getItem("TOKEN");
    if (!token) {
      return Promise.reject({
        message: "TOKEN NÃO ENCONTRADO",
      });
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Modificamos a URL para incluir os parâmetros de página e limite
    return Axios.get(`/questions/unanswered/course/${courseId}`, {
      headers,
      params: { page, limit }, // Parâmetros para paginação
    })
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  
}

const quizQuestionService = new QuizQuestionService();
export default quizQuestionService;
