// services/CareerPathService.ts

import Axios from "../shared/axios";

class CareerPathService {
  async getCareerPaths(): Promise<any> {
    try {
      let token = localStorage.getItem('TOKEN');
      if (!token) {
        return Promise.reject({
          message: 'TOKEN NÃO ENCONTRADO',
        });
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const result = await Axios.get('/career-path/all', { headers });
      return result.data;
    } catch (error: any) {
      console.error('Error fetching career paths:', error);
      if (error.response) {
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject({
          message: 'Erro ao enviar dados para o servidor',
        });
      }
    }
  }

  async getCoursesByCareerPath(idCareerPath: number): Promise<any> {
    try {
      let token = localStorage.getItem('TOKEN');
      if (!token) {
        return Promise.reject({
          message: 'TOKEN NÃO ENCONTRADO',
        });
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const result = await Axios.get(`/career-course/${idCareerPath}/courses`, { headers }); // Corrigido o caminho da rota
      return result.data;
    } catch (error: any) {
      console.error('Error fetching courses for career path:', error);
      if (error.response) {
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject({
          message: 'Erro ao enviar dados para o servidor',
        });
      }
    }
  }


  async getAllCareerWithPercentageByStudent(): Promise<any> {
    try {
      // Recupera o token do localStorage
      let token = localStorage.getItem("TOKEN");
      if (!token) {
        return Promise.reject({
          message: "TOKEN NÃO ENCONTRADO",
        });
      }
  
      // Define os headers com o token de autorização
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      // Faz a requisição para o endpoint do backend
      const result = await Axios.get(`/career-path/all-with-percentege`, { headers });
      return Promise.resolve(result.data); // Retorna os dados no formato esperado
    } catch (error: any) {
      // Lida com erros e rejeita a Promise com a mensagem adequada
      if (error.response) {
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject({
          message: "Erro ao enviar dados para o servidor",
        });
      }
    }
  }
}

export default new CareerPathService();
