import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';
import { Extension } from '@codemirror/state';
import './index.scss';

const CodingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [code, setCode] = useState<string>(location.state?.code || '// Escreva seu código aqui');
  const [question, setQuestion] = useState<string>(location.state?.question || '');
  const [isInitial, setIsInitial] = useState<boolean>(!location.state?.code);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [previousLesson, setPreviousLesson] = useState<number | null>(null); // Armazena a última lição

  const extensions: Extension[] = [javascript()];

  useEffect(() => {
    // Atualiza o código inicial apenas quando montar o componente
    if (isInitial) {
      setIsInitial(false);
      setCode('');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Atualiza a lição anterior quando o estado de localização mudar
    if (location.state?.lesson) {
      setPreviousLesson(location.state.lesson);
    }
  }, [location.state?.lesson]);

  const handleQuestionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestion(e.target.value);
  };

  const handleSubmit = () => {
    // Lógica para enviar código e dúvida
   // console.log('Código:', code);
   // console.log('Dúvida:', question);
    setIsSubmitted(true);
    setTimeout(() => setIsSubmitted(false), 3000); // Feedback visual por 3 segundos
  };

  const handleBack = () => {
    // Volta para a página anterior de forma controlada
    if (previousLesson !== null) {
      navigate(`/aula/${previousLesson}`, {
        state: {
          ...location.state,
          lesson: previousLesson,
        },
      });
    } else {
      navigate(-1); // Navega para a página anterior padrão
    }
  };

  return (
    <div className="coding-page">
      <h2 className="title">Escreva seu código e tire suas dúvidas</h2>
      <CodeMirror
        value={code}
        height="500px"
        theme={oneDark}
        extensions={extensions}
        onChange={setCode}
        className="code-editor"
      />
      <div className="question-container">
        <textarea
          value={question}
          onChange={handleQuestionChange}
          placeholder="Escreva sua dúvida aqui"
          rows={4}
          className="question-textarea"
        />
      </div>
      <div className="submit-container" style={{gap:20, display:'flex', justifyContent:'center'}}>
        <button className="btn_secondary" onClick={handleSubmit}>
          Enviar Código e Dúvida
        </button>
        <button className="btn_tertiary" onClick={handleBack}>
          Voltar
        </button>
        {isSubmitted && <span className="submit-feedback">Enviado com sucesso!</span>}
      </div>
    </div>
  );
};

export default CodingPage;
