import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import { FaHome, FaLinkedin } from "react-icons/fa";
import studentsService from "../../services/StudentsService";
import { useNavigate } from "react-router-dom";

interface ISkills {
  id: number;
  name: string;
  description: string;
  type: string;
  total: number;
}

function MySkills() {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [studentSkills, setSkills] = useState<ISkills[]>([]);
  const navigate = useNavigate();

  const loadList = () => {
    console.log("Iniciando a função loadList");
    setLoading(true);
    studentsService
      .getSkills()
      .then((response) => {
        console.log("Resposta recebida do backend:", response); // Verifica a resposta do backend
        setLoading(false);
        setSkills(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log("Erro ao carregar as skills:", error); // Exibe o erro caso haja algum problema
      });
  };

  useEffect(() => {
    console.log("useEffect chamado - carregando lista de skills");
    loadList();
  }, []);

  const handleDownloadClick = () => {
    console.log(
      "Botão de download clicado - redirecionando para a página de compartilhamento no LinkedIn"
    );
    // Redirecionar para a página que gera a imagem
    navigate("/minhas-skills-linkedin");
  };

  return (
    <>
      <nav className="breadcrumb-talent">
        <ol style={{ display: "flex", alignItems: "center" }}>
          <li
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <FaHome style={{ marginRight: "5px" }} />
            <a className="p_normal" href="/cursos">
              Início
            </a>
          </li>

          <li>
            <a className="p_normal" href="/cursos">
              Meus cursos
            </a>
          </li>
          <li>
            <a className="p_normal" href="/minhas-metrics">
              Minhas Métricas
            </a>
          </li>
          <li>
            <a className="p_normal" href="/student-collaboration">
              Colaboração
            </a>
          </li>
          <li>
            <a className="p_normal" href="">
              Minhas skills
            </a>
          </li>
        </ol>
      </nav>

      <div className="space"></div>
      <div className="my-skills-container">
        <div className="containerWhite">
          <h2 className="title">Minhas Skills</h2>
          <div className="space"></div>
          <Table hover size="sm" className="my-skills">
            <thead>
              <tr>
                <th>Skill</th>
                <th>Descrição</th>
                <th>Tipo</th>
                <th>Experiência</th>
              </tr>
            </thead>

            {isLoading && (
              <img
                src="/assets/images/loading_II.gif"
                className="icon-loading"
                alt="carregando"
              />
            )}

            <tbody>
              {studentSkills.map((skill: ISkills) => (
                <tr key={skill.id}>
                  <td>{skill.name}</td>
                  <td>{skill.description}</td>
                  <td>{skill.type === "SOFT" ? "Soft Skill" : "Hard Skill"}</td>
                  <td>{skill.total ? `${skill.total}%` : "0%"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="space"></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <div
            className="backgraund"
            style={{
              background: "#fff",
              padding: "10px 20px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            <div className="title" style={{ textAlign: "center" }}>
              Baixe a imagem e faça um post no seu LinkedIn
            </div>
          </div>
        </div>

        <div className="space"></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            className="btn_primary"
            onClick={handleDownloadClick}
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <FaLinkedin style={{ marginRight: "1px" }} />
            Baixe e compartilhe no LinkedIn
          </button>
        </div>
      </div>
    </>
  );
}

export default MySkills;
