import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useContext } from "react";
import "./index.scss";
import { ICourse } from "../../interfaces/ICourse";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import courseService from "../../services/CourseService";
import AuthContext from "../../context/auth";
import CourseContext from "../../context/CourseContext";
import ProgressBar from "@ramonak/react-progress-bar";
import SearchIcon from '@mui/icons-material/Search';

export default function CareerCourses() {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const [courses, setCourses] = useState<ICourse[]>([]);

  const [isLoading, setLoading] = useState<boolean>(true);

  const [coursesFiltered, setCoursesFiltered] = useState<ICourse[]>([]);

  const { signed } = useContext(AuthContext);

  const { selectedCourse, markAsSelectedCourse, markAsNotSelectedCourse } =
    useContext(CourseContext);

  const iniciar = () => {
    navigate("/Aula");
  };

  const loadList = () => {
    setLoading(true);
    courseService
      .getAll()
      .then((result) => {
        setCourses(result.data.courses);
        setCoursesFiltered(result.data.courses);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        console.log("Não foi possível carregar a lista de cursos");
      });
  };

  useEffect(() => {
    loadList();
  }, []);

  useEffect(() => {
    const filtered = courses.filter((course: ICourse) => {
      return course.name.toLowerCase().indexOf(search.toLowerCase()) >= 0;
    });
    setCoursesFiltered(filtered);
  }, [search]);

  const access = (course: ICourse) => {
    let data: any = {};
    data = {
      id_course: course.id,
    };

    courseService
      .start(data)
      .then((result: any) => {
        markAsSelectedCourse(course);
        navigate("/aula", {
          state: {
            course: course,
          },
        });
      })
      .catch((error: any) => {
        console.log(error);
        alert("Não foi possível iniciar/continuar o curso");
      });
  };

  return (
    <div className="App">
      <div className="topo">
       
        <Button   
         className="button-confirm todos-cursos"      
         variant="contained"               
         style={{height:50, marginLeft:30}}        
         >
           <SearchIcon style={{ fontSize: "20px" }} />
            Trilha      
        </Button>

        <Button   
         className="button-confirm todos-cursos"      
         variant="contained"               
         style={{height:50, marginLeft:30}}        
         >
           <SearchIcon style={{ fontSize: "20px" }} />
            Todos os curso      
        </Button>       

        
      </div>

     
      
      

      <main>
      
      </main>
    </div>
  );
}
