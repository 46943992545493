import Axios from "../shared/axios";

class InterviewService {
  async create(data: any) {
    return Axios.post("interview/create", data)
      .then((response) => {
        // localStorage.setItem("TOKEN", response.data.access_token);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getByUser(): Promise<any> {
    return Axios.get("interview/get")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async getByCompany(): Promise<any> {
    return Axios.get("interview/get-company")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }


  async toggleInterview(id: number, data:any): Promise<any> {
    return Axios.put("interview/accepted/" + id, data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error:any) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }
}

const interviewService = new InterviewService();
export default interviewService;
