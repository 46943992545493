export default class UtilDate {
  static stringToDMY(stringDate: string) {
    const date = new Date(stringDate);
    if (date) {
      return (
        (date.getDate()+1)?.toString().padStart(2, "0") +
        "/" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date.getFullYear()
      );
    } else {
      return "";
    }
  }

  static dateToYMD(date: Date) {
    if (date) {
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate()?.toString().padStart(2, "0")
      );
    } else {
      return "";
    }
  }

  static stringToDMYHM(stringDate: string) {
    const date = new Date(stringDate);
    if (date) {
      return (
        (date.getDate()+1)?.toString().padStart(2, "0") +
        "/" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date.getFullYear() +
        " às " +
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0")
      );
    } else {
      return "";
    }
  }

  static dateToDMY(date: Date) {
    if (date) {
      return (
        date.getDate()?.toString().padStart(2, "0") +
        "/" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date.getFullYear()
      );
    } else {
      return "";
    }
  }

  static dateToDMYHM(date: Date) {
    if (date) {
      return (
        date.getDate()?.toString().padStart(2, "0") +
        "/" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date.getFullYear() +
        " às " +
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0")
      );
    } else {
      return "";
    }
  }
  static monthAsText(month: number): string {
    const months: { [key: number]: string } = {
      1: "Janeiro",
      2: "Fevereiro",
      3: "Março",
      4: "Abril",
      5: "Maio",
      6: "Junho",
      7: "Julho",
      8: "Agosto",
      9: "Setembro",
      10: "Outubro",
      11: "Novembro",
      12: "Dezembro",
    };
    return months[month];
  }
}
