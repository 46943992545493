import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import studentsService from "../../services/StudentsService";
import Swal from "sweetalert2";

const LinkedinModal = ({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}) => {
  const [error, setError] = useState<string | null>(null);
  const [linkedin, setLinkedin] = useState<string>("");

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();

    if (!linkedin) {
      setError("O campo LinkedIn é obrigatório.");
      return;
    }

    studentsService
      .createLinkedin(linkedin)
      .then((response) => {
        handleClose();
        Swal.fire("Sucesso", "Perfil do LinkedIn enviado com sucesso!", "success");
      })
      .catch(() => {
        setError("Falha ao enviar o perfil do LinkedIn.");
        Swal.fire("Erro", "Falha ao enviar o perfil do LinkedIn.", "error");
      });
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LinkedInIcon style={{ marginRight: '8px' }} />
            Informe seu LinkedIn
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="linkedinInput">
            <Form.Label>
              Informe o link do seu perfil no LinkedIn para ter mais chances de
              entrevistas
            </Form.Label>
            <Form.Control
              type="text"
              value={linkedin}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLinkedin(e.target.value);
                setError(null); // Limpa o erro ao digitar
              }}
              isInvalid={!!error}
            />
            {error && (
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button> */}
        <button className="btn_secondary" onClick={handleClick}>
          Enviar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default LinkedinModal;
