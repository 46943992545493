import { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import SubmitForm from "./SubmitForm";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import usersService from "../../services/UsersService";
import swal from "sweetalert";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Cadastro() {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const [type, setType] = useState<"password" | "text">("password");
  const [confPasswordType, setConfPasswordType] = useState<"password" | "text">(
    "password"
  );

  // const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
      confPassword: "",
      acceptTerms: false,
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("E-mail inválido")
        .required("É necessário preencher o campo e-mail"),
      password: Yup.string()
        .min(6, "Senha deve ter no mínimo 6 caracteres")
        // .matches(
        //   passwordRules,
        //   "Crie uma senha forte! A senha deve conter no mínimo dois números, um caractere especial, uma letra maiúscula, uma letra minúscula"
        // )
        .required("É necessário preencher o campo senha"),
      confPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Senha devem ser iguais")
        .required("É necessário confirmar a senha"),
      name: Yup.string().required("É necessário preencher o campo nome"),
      acceptTerms: Yup.bool().oneOf(
        [true],
        "É necessário aceitar os termos e condições"
      ),
    }),

    onSubmit: (values) => {
      create(values);
    },
  });

  const create = (values: any) => {
    let data = {
      name: values.name,
      email: values.email,
      password: values.password,
    };

    usersService
      .create(data)
      .then((response) => {
        setLoading(false);
        swal({
          title: "Cadastro realizado com sucesso!",
          text: "Você será redirecionado à página de Login",
          icon: "success",
        }).then((value: any) => {
          navigate("/");
        });
      })
      .catch((error) => {
        swal({
          title: "Houve um erro ao cadastrar!",
          text: error.response.data.errorMessage,
          icon: "error",
        }).then((value: any) => {
          setLoading(false);
        });
      });
  };

  const cancel = () => {
    navigate("/");
  };

  return (
    <div className="container1 bg">
      <div
        className={
          !isSubmitSuccess ? "signin signin_wrapper" : "signin signin_success"
        }
      >
        {isSubmitSuccess ? (
          <SubmitForm />
        ) : (
          <form onSubmit={formik.handleSubmit} style={{ borderRadius: 20 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img className="img-cadastro-aluno"
                src="assets/images/logo_IV.png"
                alt="fatecoins"
                
              />
            </div>
            <div className='title textCenter' >Cadastro de aluno</div>

            <div className='space'></div>

            <label className="label-cadastro">
              Nome completo
            </label>
            <TextField
              variant="outlined"
              required
              margin="normal"
              fullWidth
              name="name"
              type="text"
              placeholder="Seu nome completo"
              className="textField"
              InputProps={{
                startAdornment: <></>,
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error_msg">{formik.errors.name}</div>
            ) : null}

            <label className="label-cadastro">
              E-mail
            </label>
            <TextField
              variant="outlined"
              required
              name="email"
              type="text"
              placeholder="e-mail"
              className="textField"
              InputProps={{
                startAdornment: <></>,
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />

            {formik.touched.email && formik.errors.email ? (
              <div className="error_msg">{formik.errors.email}</div>
            ) : null}

            <label className="label-cadastro">
              Senha
            </label>
            <OutlinedInput
              required
              name={"password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      if (type === "text") {
                        setType("password");
                      } else {
                        setType("text");
                      }
                    }}
                    edge="end"
                  >
                    {type === "text" ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              type={type}
              placeholder="Senha"
              className="textField"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="error_msg">{formik.errors.password}</div>
            ) : null}

            <label className="label-cadastro">
              Confirme sua senha
            </label>
            <OutlinedInput
              required
              name={"confPassword"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      if (confPasswordType === "text") {
                        setConfPasswordType("password");
                      } else {
                        setConfPasswordType("text");
                      }
                    }}
                    edge="end"
                  >
                    {confPasswordType === "text" ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              type={confPasswordType}
              placeholder="Confirmar senha"
              className="textField"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confPassword}
            />
            {formik.touched.confPassword && formik.errors.confPassword ? (
              <div className="error_msg">{formik.errors.confPassword}</div>
            ) : null}

            <FormGroup
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <FormControlLabel
                control={<Checkbox checked={formik.values.acceptTerms} />}
                label={
                  <a href="/termos-de-uso" style={{color:'#2563EB'}}>Li e aceito os termos de uso</a>
                }
                name="acceptTerms"
                onChange={formik.handleChange}
              />
              {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                <div className="error_msg">{formik.errors.acceptTerms}</div>
              ) : null}
            </FormGroup>

            <div className="space"></div>

             <div className='central-button'>
            <button className="btn_primary full" type="submit">
              Cadastrar
            </button>

            <div className="space"></div>

            <button className="btn_tertiary" type="submit" onClick={cancel}>
              Cancelar
            </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default Cadastro;
