import { Col, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Header from "../header";
import Sidebar from "../sidebar/Sidebar";
import CompanySidebar from "../CompanySidebar/Sidebar";
import Footer from "../Footer";

const CompanyAppLayout = () => {
  return (
    <div style={{}}>
      <Row>
        <Header />
      </Row>
      <Row>
        <Col xs={10}>
          <Outlet />
        </Col>
        <Col xs={2}>
          <CompanySidebar />
        </Col>
      </Row>
      <Row style={{ marginTop: '17%', overflow: 'hidden' }}>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Footer />
        </div>
      </Row>
    </div>
  );
};

export default CompanyAppLayout;
