import { Col, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Header from "../header";
import Footer from "../Footer";

const AppLayoutNoSidebar = () => {
  return (
    <div>
      <Row>
        <Header />
      </Row>
      <Row>
        <Col xs={12}>
          <Outlet />
        </Col>

      </Row>
      <Row>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Footer />
        </div>
      </Row>
    </div>

  );
};

export default AppLayoutNoSidebar;
