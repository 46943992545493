import axios from "axios";

let API_URL = process.env.REACT_APP_API_URL;

// Axios Config
const Axios = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

Axios.interceptors.request.use(async (config) => {
  let token = await localStorage.getItem("TOKEN");

  if (token && config && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (err) => {
//     return new Promise(async (resolve, reject) => {
//       const originalReq = err.config;
//       // Inverter o if
//       // console.log("status == 401", err.response.status === 401);
//       // console.log("config", err.config);
//       // console.log("retry", !err.config._retry);
//       // console.log("url", !err.config.url.endsWith("login"));
//       if (
//         err.response.status === 401 &&
//         err.config &&
//         !err.config._retry &&
//         !err.config.url.endsWith("login")
//       ) {
//         try{
//         originalReq._retry = true;
//         let token = await localStorage.getItem("TOKEN");
//         let res = axios
//           .put(`${API_URL}/token/refresh`, { oldToken: token })
//           .then((res) => {
//             if (res.data.access_token) {
//               localStorage.setItem("TOKEN", res.data.access_token);
//             }
//             originalReq.headers[
//               "Authorization"
//             ] = `Bearer ${res.data.access_token}`;
//             return resolve(Axios(originalReq));

//           })
//           .catch((error) => {
//             console.log(`Deu erro: ${error}`);
//             reject(error);
//           });
//           console.log(res)
//         resolve(res);
//         }catch(e){
//           reject(e)
//         }
//       } else {
//         console.log(err)
//         reject(err);
//       }
//     });
//   }
// );

Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error.config;

    if (error.response.status === 401 && !config._retry) {
      // we use this flag to avoid retrying indefinitely if
      // getting a refresh token fails for any reason
      config._retry = true;
      let token = localStorage.getItem("TOKEN");
      console.log(token)
      return axios
        .put(`${API_URL}/token/refresh`, { oldToken: token })
        .then((res) => {
          if (res.data.access_token) {
            localStorage.setItem("TOKEN", res.data.access_token);
          }
          config.headers["Authorization"] = `Bearer ${res.data.access_token}`;

          return Axios(config);
        });
    }
    return Promise.reject(error);
  }
);

export default Axios;
