import React, { useState, useEffect, useContext } from 'react';
import { BiMenu } from 'react-icons/bi';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import CourseContext from '../../context/CourseContext';
import { ILesson } from '../../interfaces/ILesson';
import lessonService from '../../services/LessonService';
import './sidebar.scss';
import { Divider } from '@material-ui/core';
import { IStudentLesson } from '../../interfaces/IStudentLesson';
import ArrowNext from '@mui/icons-material/ArrowForwardIos';
import { FaCheck } from 'react-icons/fa';

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [items, setItems] = useState<ILesson[]>([]);
  const { selectedCourse, getLesson, setLesson } = useContext(CourseContext);
  const [studentLesson, setStudentLesson] = useState<IStudentLesson[]>([]);
  const [selectedLesson, setSelectedLesson] = useState<ILesson | null>(null);

  const loadList = () => {
    setLoading(true);
    if (selectedCourse) {
      lessonService
        .getAll(selectedCourse.id)
        .then((response) => {
          setLoading(false);
          let data: ILesson[] = response.data;
          setItems(data);
          lessonService
            .getAllByStudentAndCourse(selectedCourse.id)
            .then((res) => setStudentLesson(res.data));
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    loadList();
  }, [selectedCourse]);

  useEffect(() => {
    if (selectedCourse) {
      lessonService
        .getAllByStudentAndCourse(selectedCourse.id)
        .then((res) => setStudentLesson(res.data));
    }
  }, [getLesson()]);

  useEffect(() => {
    const currentPath = window.location.pathname.split('/')[1];
    const activeItem = items.findIndex((item) => item.id.toString() === currentPath);
    setActiveIndex(currentPath.length === 0 ? 0 : activeItem);
  }, [location, items]);

  const handleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const selectLesson = (lesson: ILesson) => {
    let data: any = { id_lesson: lesson.id };

    lessonService
      .start(data)
      .then((result: any) => {
        setSelectedLesson(lesson); // Define a lição selecionada
        setLesson(lesson);
        navigate('/aula', {
          state: {
            course: selectedCourse,
            lesson: lesson,
            code: location.state?.code || '// Escreva seu código aqui',
            question: location.state?.question || '',
            previousLessons: location.state?.previousLessons || [],
          },
        });
      })
      .catch((error: any) => {
        console.log(error);
        alert('Não foi possível iniciar/continuar a aula');
      });
  };

  return (
    <div
      className="sidebar"
      style={{
        right: collapsed ? '-300px' : 0,
        background: '#FFFFFF',
        marginTop: '10px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        height: '90vh',
        overflowY: 'scroll',
      }}
    >
      <div className="barra-aulas">
        <a
          style={{
            background: '#413C69',
            marginTop: '10px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            padding: '10px',
            margin: '0',
            justifyContent: 'space-between',
          }}
          href="javascript: void(0)"
          onClick={() => handleSidebar()}
          className="link-collapse"
        >
          <BiMenu style={{ color: 'white' }} />
          <p className="p_bold" style={{ color: 'white', marginTop: '10px' }}>
            Ver todas as aulas
          </p>
          <ArrowNext style={{ color: 'white' }} />
        </a>
      </div>

      <div className="sidebar__menu">
        <>
          <div className="sidebar__menu__indicator"></div>

          {items.map((item, index) => (
            <a href="#" onClick={() => selectLesson(item)} key={index}>
              <Divider
                style={{
                  background: '#E9E9E9',
                  marginTop: '5px',
                  marginBottom: '5px',
                  border: 'solid 1px',
                }}
              />
              <div
                className={`sidebar__menu__item ${selectedLesson?.id === item.id ? 'active' : ''
                  }`}
              >
                <div className="sidebar__menu__item__icon">
                  {studentLesson.find((lesson) => lesson.lesson.id === item.id) !==
                    undefined &&
                    studentLesson.find((lesson) => lesson.lesson.id === item.id)
                      ?.completed === "Y" && (
                      <FaCheck style={{ marginRight: '20px', color: 'green' }} />
                    )}
                  {studentLesson.find((lesson) => lesson.lesson.id === item.id) ===
                    undefined ||
                    studentLesson.find((lesson) => lesson.lesson.id === item.id)
                      ?.completed === "N" ? (
                    <BsFillPlayCircleFill
                      style={{ color: 'goldenrod', marginRight: '20px' }}
                    />
                  ) : null}
                </div>
                <div className="sidebar__menu__item__text">{item.name}</div>
              </div>
              <Divider />
            </a>
          ))}
        </>
      </div>
    </div>
  );
};

export default Sidebar;
