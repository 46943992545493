import "./index.scss";
import { useEffect, useRef, useState } from "react";
import CommentForm from "./CommentForm";
import {IQuestion} from "../../interfaces/IQuestion";
import QuestionAnswerTwoToneIcon from "@mui/icons-material/QuestionAnswerTwoTone";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

function Answers({
  pergunta,
  loadList,
}: {
  pergunta: IQuestion;
  loadList: () => void;
}) {
  const [showResposta, setShowResposta] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showResposta){
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [showResposta]);

  return (

    
    <div ref={ref} >
      <div className="responder">
        
        <a
        style={{flexDirection:'row', display: 'flex', alignItems: 'center'}}
          href="javascript: void(0)"
          onClick={() => {
            setShowResposta(!showResposta);
          }}
         
        >
          <div className="icon" style={{display:'flex', alignItems: 'center', marginLeft:'55px'}}>
          <ReplyAllIcon style={{ fontSize: '30px', fill: '#474273' }} />
          <h6 style={{marginBottom:0, marginLeft:'8px'}} >Responder</h6>
          </div>
        </a>
      </div>

      {showResposta && (
       
        <>
          <CommentForm           
            question={pergunta}
            callback={loadList}
            closecomment={() => setShowResposta(!showResposta)}
          />
        </>
      )}
    </div>
  );
}
export default Answers;
