import { useEffect, useState, useContext } from "react";
import { BiMenu } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import "./companysidebar.scss";
import { Icon } from "@material-ui/core";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Description } from "@material-ui/icons";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Sidebar from "../sidebar/dist/Sidebar";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ISubscription from "../../interfaces/ISubscripition";
import paymentsService from "../../services/PaymentsService";
import SubscriptionContext from "../../context/Subscription";
import { planIdEnum } from "../../enums/plan-id.enum";
import { FaSearch, FaSearchLocation } from "react-icons/fa";
import { GrGroup } from "react-icons/gr";
import { MdOutlinePlayLesson } from "react-icons/md";
const CompanySidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { subscription, updateSubscription } = useContext(SubscriptionContext);
  const [isLoading, setLoading] = useState<boolean>(true);
  const handleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const navigate = useNavigate();
  const creditos = () => {
    navigate("/payments");
  };

  const relCred = () => {
    navigate("/consumedCredits");
  };

  const compEmploy = () => {
    navigate("/companyEmployees");
  };

  const search = () => {
    navigate("/seek-talent");
  };

  const courses = () => {
    navigate("/cursos");
  };

  const listInterviews = () => {
    navigate("/list-interviews-company");
  };

  const fetchSubscription = () => {
    updateSubscription()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchSubscription();
  }, []);

  return (
    <div className="sidebar" style={{ right: collapsed ? "-280px" : 0 }}>
      <a
        href="javascript: void(0)"
        onClick={() => handleSidebar()}
        className="link-collapse"
      >
        <BiMenu />
      </a>

      {isLoading && (
        <div className="sidebar-loading">
          <div className="spinner-border text-primary" role="status" style={{ fontSize: "50px" }}>
            <span className="visually-hidden">Carregando...</span>
          </div>
        </div>
      )}

      <div className="sidebar__menu">
        {!collapsed ? (
          <>
            <br />
            <>
              <div className="sidebar-payment">
                <Icon>
                  <Description style={{ fontSize: "20px" }} />
                </Icon>
              </div>

              
              <div className="link-comprarCreditos">
                <a href="javascript: void(0)" onClick={relCred}>
                  Minha Assinatura
                </a>
              </div>
              <div className="space"></div>
                   <div className="sidebar-payment">
                    <Icon>
                      <MdOutlinePlayLesson style={{ fontSize: "20px" }} />
                    </Icon>
                  </div>                  
                  <div className="link-comprarCreditos">
                    <a href="javascript: void(0)" onClick={courses}>
                      Cursos na plataforma
                    </a>
                  </div>
            </>
           
            <div className="space"></div>
            {subscription &&
              subscription.status === "active" &&
              subscription.plan.id == planIdEnum.HUNTER_MNG && (
                <>
                  <div className="sidebar-payment">
                    <Icon>
                      <FaSearch style={{ fontSize: "20px" }} />
                    </Icon>
                  </div>
                  
                  <div className="link-comprarCreditos">
                    <a href="javascript: void(0)" onClick={search}>
                      Buscar Talentos
                    </a>
                  </div>
                 
                  <div className="space"></div>
                 

                  <div className="sidebar-payment">
                    <Icon>
                      <GrGroup style={{ fontSize: "20px" }} />
                    </Icon>
                  </div>
                  <div className="link-comprarCreditos">
                    <a href="javascript: void(0)" onClick={listInterviews}>
                      Entrevistas
                    </a>
                  </div>
                </>
              )}

              {subscription &&
              subscription.status === "active" &&
              subscription.plan.id == planIdEnum.HUNTER && (
                <>
                  <div className="sidebar-payment">
                    <Icon>
                      <FaSearch style={{ fontSize: "20px" }} />
                    </Icon>
                  </div>                  
                  <div className="link-comprarCreditos">
                    <a href="javascript: void(0)" onClick={search}>
                      Buscar Talentos
                    </a>
                  </div>
                 
                  <div className="space"></div>
                 

                  <div className="sidebar-payment">
                    <Icon>
                      <GrGroup style={{ fontSize: "20px" }} />
                    </Icon>
                  </div>
                  <div className="link-comprarCreditos">
                    <a href="javascript: void(0)" onClick={listInterviews}>
                      Entrevistas
                    </a>
                  </div>

                  <div className="space"></div>

                  
                </>
              )}
              <div className="space"></div>

           
            {subscription &&
              subscription.status === "active" &&
              subscription.plan.id == planIdEnum.HUNTER_MNG && (
                <>
                  <div className="sidebar-payment">
                    <Icon>
                      <Diversity3Icon style={{ fontSize: "20px" }} />
                    </Icon>
                  </div>
                  <div className="link-comprarCreditos">
                    <a href="javascript: void(0)" onClick={compEmploy}>
                      Meus Colaboradores
                    </a>
                  </div>
                  <div className="space"></div>
                  
                  
                 
                </>
              )}
          </>
        ) : (
          <>
            <>
              <div className="iconSidebar">
                <Icon>
                  <Description
                    onClick={relCred}
                    style={{ fontSize: "30px", cursor: "pointer" }}
                  />
                </Icon>
              </div>
              <div className="iconSidebar">
                <Icon>
                  <MdOutlinePlayLesson
                    onClick={courses}
                    style={{ fontSize: "30px", cursor: "pointer" }}
                  />
                </Icon>
              </div>
              
             
            </>

            {subscription && subscription.status === "active" && (
              <>
                <div className="iconSidebar">
                  <Icon>
                    <Diversity3Icon
                      onClick={compEmploy}
                      style={{ fontSize: "30px", cursor: "pointer" }}
                    />
                  </Icon>
                </div>
                
              </>
            )}

            {subscription && subscription.status === "active" && (
              <>
                <div className="iconSidebar">
                  <Icon>
                    <GrGroup
                      onClick={listInterviews}
                      style={{ fontSize: "30px", cursor: "pointer" }}
                    />
                  </Icon>
               
                <div className="iconSidebar">
                  <Icon >
                    <FaSearch
                      onClick={search}
                      style={{ fontSize: "30px", cursor: "pointer"}}
                    />
                  </Icon>
                </div>
               
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default CompanySidebar;
