import ProgressBar from "@ramonak/react-progress-bar";
import { ICourse } from "../../interfaces/ICourse";
import { ILesson } from "../../interfaces/ILesson";
import { useEffect, useState } from "react";
import courseService from "../../services/CourseService";
import "./index.scss";

interface IHeaderCourseProps {
  course: ICourse | null;
  lesson?: ILesson | null;
  updateProgress?: number;
}

const HeaderCourse = (props: IHeaderCourseProps) => {
  const [percentage, setPercentage] = useState(0);

  const progress = (course: ICourse) => {
    courseService
      .getPercentage(course.id)
      .then((response) => {
        setPercentage(Math.floor(Number(response.data)));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (props.course) {
      progress(props.course);
    }
  }, []);

  useEffect(() => {
    if (props.course) {
      progress(props.course);
    }
  }, [props.updateProgress]);

  return (
    <>
      {props.course && (
        <div className="curso">
          {/* <img src={props.course.image} alt="" className="logo_curso" /> */}
          
            <h2 className="title" >
              {props.course.name}     
             
            </h2>

            {/* <h4 className="subtitle">{props.lesson?.name}</h4> */}
         
          {/* <div className="evolucao">
            <p>Sua evolução</p>
            <ProgressBar className="progresso" completed={percentage} />
          </div> */}
        </div>
      )}
    </>
  );
};

export default HeaderCourse;
