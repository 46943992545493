import { Fade } from 'react-awesome-reveal';
import { Modal } from '@material-ui/core';

const SubmitForm = () => {
    return (
        <Fade delay={500}>
            <div className="submit-form">
            <h1>Sucesso!</h1>
            <p>Funcionário cadastrado com sucesso!</p>
            </div>
        </Fade>
    )
}

export default SubmitForm
