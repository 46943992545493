import Avatar from '@mui/material/Avatar';
import { useRef, useState, useEffect, useContext } from "react";
import { Image } from "react-bootstrap";
import AuthContext from '../../context/auth';
import { MdFileUpload } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';

export const ImagePicker: React.FC<{
    handleFileChange: (file: File | null) => void;
    initialImage?: string | null;
    disabled?: boolean;
    accept?: string; // Adicionando a propriedade accept
}> = ({ handleFileChange, initialImage, disabled, accept }) => {
    const inputFile = useRef<HTMLInputElement | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | undefined | null>(initialImage);
    const { storePhotoProfile } = useContext(AuthContext);
    const [isSendDisabled, setIsSendDisabled] = useState<boolean>(false); // Controla o botão "Enviar"

    const nameUser = localStorage.getItem("name");

    useEffect(() => {
        if (!selectedFile) {
            setPreview(initialImage ?? undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);
        setIsSendDisabled(true); // Desabilita o botão "Enviar" após o carregamento da imagem
        setSelectedFile(null); // Resetar o estado após o carregamento
        return () => URL.revokeObjectURL(objectUrl);
    }, [initialImage, selectedFile]);

    const onSelectFile = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(null);
            return;
        }
        const file = e.target.files[0];
        setSelectedFile(file);
        handleFileChange(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result) {
                storePhotoProfile(reader.result as string);
            }
        };
        reader.readAsDataURL(file);
    };

    const removeImage = () => {
        setPreview(null);
        handleFileChange(null); // Notificar que a imagem foi removida
        storePhotoProfile(""); // Limpa a imagem no contexto
        setIsSendDisabled(false); // Reabilitar o botão "Enviar" após a remoção
    };

    const handleClick = () => {
        if (inputFile.current) {
            inputFile.current.click();
        }
    };

    function stringToColor(string: string) {
        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }

        return color;
    }

    function stringAvatar(name: string) {
        if (name.indexOf(" ") >= 0) {
            name = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
        } else {
            name = `${name.substring(0, 1)}`;
        }
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: 200,
                height: 200,
                fontSize: 48,
            },
            children: `${name}`,
        };
    }

    return (
        <>
            {!disabled && (
                <input
                    style={{ display: "none" }}
                    type="file"
                    accept={accept || "image/*"} // Utilizando a propriedade accept
                    ref={inputFile}
                    onChange={onSelectFile}
                />
            )}
            <div
                style={{
                    maxHeight: "250px",
                    maxWidth: "250px",
                    minHeight: "200px",
                    minWidth: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: disabled ? "default" : "pointer",
                    flexDirection: "column"
                }}
                onClick={handleClick}
            >
                <div className="p-2">
                    {!preview || preview === "" || preview === null ? (
                        <Avatar {...stringAvatar(nameUser!)} />
                    ) : (
                        <Image
                            className="rounded-circle"
                            src={preview!}
                            fluid
                            rounded
                        />
                    )}
                </div>

                {!disabled && (
                    <div className="p-2" style={{ width: "100%", display: "flex", justifyContent: "center", gap: "10px" }}>
                        <button type="button" className="btn btn-warning" disabled={isSendDisabled}>
                            <MdFileUpload /> Enviar
                        </button>
                        {preview && (
                            <button type="button" className="btn btn-danger" onClick={removeImage}>
                                <FaTrash /> Remover
                            </button>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}
