import axios from "axios";
import Axios from "../shared/axios";

class ConsumedCreditService {
  async create(data: any) {
    let token = localStorage.getItem("TOKEN");
    return Axios.post("payment/create", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async consumeCredit() {
    let token = localStorage.getItem("TOKEN");
    return Axios.post("consumed-credits/create")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getAll(): Promise<any> {
    return Axios.get("/consumed-credits/all")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }
}

const consumedCreditService = new ConsumedCreditService();
export default consumedCreditService;
