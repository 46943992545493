import React, { useEffect, useState } from "react";
import usersService from "../../services/UsersService";
import skillsLinkedinService from "../../services/SkillsLinkedinService";
import "./style.scss";
import { FaLinkedin } from "react-icons/fa";
import html2canvas from "html2canvas";

type CircularProgressProps = {
  percentage: number;
  title: string;
  description?: string;
};

interface ISkills {
  id: number;
  name: string;
  description: string;
  type: string;
  total: number | null;
}

const CircularProgress: React.FC<CircularProgressProps> = ({
  percentage,
  title,
  description,
}) => {
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <div className="d-flex align-items-center mb-4">
      <div className="circular-progress me-3">
        <svg width="120" height="120">
          <circle
            cx="60"
            cy="60"
            r={radius}
            stroke="#e0e0e0"
            strokeWidth="10"
            fill="transparent"
          />
          <circle
            cx="60"
            cy="60"
            r={radius}
            stroke="#6a59c8"
            strokeWidth="10"
            fill="transparent"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            strokeLinecap="round"
          />
        </svg>
        <div className="circular-text">
          <h4>{percentage}%</h4>
        </div>
      </div>

      <div className="skill-info">
        <h4>{title}</h4>
        {description && <p>{description}</p>}
      </div>
    </div>
  );
};

type SkillsCardProps = {
  title: string;
  value: string;
  description?: string;
};

const SkillsCard: React.FC<SkillsCardProps> = ({
  title,
  value,
  description,
}) => {
  return (
    <div className="skills-card" style={{ border: "1px solid #2095eb" }}>
      <div className="skills-header">
        <h4>{title}</h4>
        <span className="percentage" style={{ border: "1px solid #2095eb" }}>
          {value}
        </span>
      </div>
      <div className="progress-bar" style={{ border: "1px solid #6d5cf5", height: "15px" }}>
        <div className="progress" style={{ width: value }}></div>
      </div>
      {description && <p className="description">{description}</p>}
    </div>
  );
}

function SkillsLinkedin() {
  const [student, setStudent] = useState<{
    name: string;
    profileImage: string;
  } | null>(null);
  const [skills, setSkills] = useState<ISkills[]>([]);
  const [error, setError] = useState<string | null>(null);
  const studentId = 3; // ID do estudante que você quer buscar

  useEffect(() => {
    const fetchStudentInfo = async () => {
      try {
        const data = await usersService.getStudentInfo();
        setStudent(data);
      } catch (err) {
        const error = err as Error;
        setError(error.message);
      }
    };

    const fetchSkills = async () => {
      try {
        const result = await skillsLinkedinService.getSkillsL();
        const normalizedSkills = result.data.map((skill: ISkills) => ({
          ...skill,
          total: skill.total ? parseFloat(skill.total.toString()) : 0,
          type: skill.type.toLowerCase(),
        }));
        console.log("Normalized Skills:", normalizedSkills);
        setSkills(normalizedSkills);
      } catch (err) {
        const error = err as Error;
        console.error("Error fetching skills:", error);
        setError(error.message);
      }
    };

    fetchStudentInfo();
    fetchSkills();
  }, []);

  const renderSkillsByType = (type: string) => {
    const filteredSkills = skills.filter((skill) => skill.type === type);

    if (filteredSkills.length === 0) {
      return <p>Nenhuma habilidade encontrada.</p>;
    }

    return filteredSkills.map((skill) => {
      if (skill.name === "Colaboração") {
        return (
          <div
            className="colaboracao-skill"
            key={skill.id}
            style={{
              flex: 1,
              boxSizing: "border-box",
              background: '#eef2ff',
              padding: '5%',
              border: "1px solid #2095eb",
              borderRadius: "10px",
            }}
          >
            <CircularProgress
              percentage={skill.total ? skill.total : 0}
              title={skill.name}
              description={skill.description}
            />
          </div>
        );
      }

      if (skill.name === "Regularidade") {
        return (
          <div
            className="regularidade-skill"
            key={skill.id}
            style={{
              flex: 1,
              boxSizing: "border-box",
              background: '#eef2ff',
              padding: '8%',
              border: "1px solid #2095eb",
              borderRadius: "10px",
            }}
          >
            <CircularProgress
              percentage={skill.total ? skill.total : 0}
              title={skill.name}
              description={skill.description}
            />
          </div>
        );
      }

      return (
        <div
          style={{
            flex: "0 0 calc(50% - 20px)",
            boxSizing: "border-box",
          }}
          key={skill.id}
        >
          <SkillsCard
            title={skill.name}
            value={`${skill.total}%`}
            description={skill.description}
          />
        </div>
      );
    });
  };

  const generateImage = () => {
    const element = document.getElementById('skillsContainer') as HTMLElement;
    html2canvas(element).then((canvas) => {
      const image = canvas.toDataURL('image/png');
      downloadImage(image, 'skills.png');
    });
  };

  const downloadImage = (dataUrl: string, filename: string) => {
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = filename;
    link.click();
  };

  return (
    <div className="app">
      <header className="header">
        {student ? (
          <>
            <img
              className="profile-image"
              src={`https://nyc3.digitaloceanspaces.com/test-fatecoins-bucket/${student.profileImage}`}
              alt="Profile"
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
                padding: "10px",
              }}
            />
            <h1>{student.name}</h1>
          </>
        ) : (
          <h1>Carregando...</h1>
        )}
      </header>
      {error && <p>{error}</p>}
     
      
      <div id="skillsContainer" style={{ textAlign: "center", paddingTop: "40px", paddingBottom: "40px"}}>
        {student && (
          <div
            id="userInfo"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '20px',
            }}
          >
            <img
              src={`https://nyc3.digitaloceanspaces.com/test-fatecoins-bucket/${student.profileImage}`}
              alt="Profile"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                marginBottom: '10px',
              }}
            />
            <h3 style={{ textAlign: 'center' }}>{student.name}</h3>
          </div>
        )}
        <h1 className="title" style={{ textAlign: "center", marginTop: "20px" }}>
          Minhas habilidades desenvolvidas na FATECOINS Talent
        </h1>

        <div className="skills">
          <div className="soft-skills" style={{ marginLeft: "15%" }}>
            <h3 style={{ marginLeft: "35%" }}>Soft skills</h3>
            <div className="skills-percentage" style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "35%",
                width: "50%",
                border: "1px solid #eef2ff",
                borderRadius: "10px",
                gap: '30px'
              }}>
              {renderSkillsByType("soft")}
            </div>
          </div>

          <div className="hard-skills" style={{ marginRight: "20%" }}>
            <h3>Hard skills</h3>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
              {renderSkillsByType("hard")}
            </div>
          </div>
        </div>
      </div>

      <div className="space"></div>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <button className="btn_primary" onClick={generateImage} style={{ justifyContent: 'center', alignItems: 'center', display:'flex'}}>
          <FaLinkedin style={{ marginRight: "1px" }} />
          Baixe e compartilhe no LinkedIn
        </button>
      </div>
    </div>
  );
}

export default SkillsLinkedin;
