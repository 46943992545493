import React, { createContext, useState } from "react";

interface AuthContextData {
  signed: boolean;
  markAsSigned(): void;
  markAsNotSigned(): void;
  role: string | null;
  storeRole(permission: string): void;
  getRole(): string | null;
  name: string | null;
  storeName(username: string): void;
  getName(): string | null;
  getPhotoProfile(): string | null;
  storePhotoProfile(photo: string): void;
  reloadLocalStorage(): void;
}

interface ProviderProps {
  children: any;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<ProviderProps> = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [signed, setSigned] = useState(false);
  const [role, setRole] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [profileImage, setProfileImage] = useState<string | null>(null);

  // Função para carregar dados do localStorage, útil após o login ou logout
  function reloadLocalStorage() {
    setProfileImage(null);
    setName(null);
    getPhotoProfile();
    getName();
  }

  // Marcar como logado
  function markAsSigned() {
    setSigned(true);
  }

  // Função de logout, limpa o estado e localStorage
  function markAsNotSigned() {
    setSigned(false);
    setName(null);
    setProfileImage(null);
    setRole(null);

    // Limpa o localStorage completamente para evitar dados do usuário anterior
    localStorage.removeItem("USER");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
  }

  // Armazena o papel (role) no contexto e localStorage
  function storeRole(permission: string) {
    setRole(permission);
    localStorage.setItem("role", permission);
  }

  // Recupera o papel (role) do usuário, ou do localStorage se não estiver no contexto
  function getRole(): string | null {
    if (role) {
      return role;
    } else {
      const roleFromStorage = localStorage.getItem("role");
      setRole(roleFromStorage);
      return roleFromStorage;
    }
  }

  // Armazena o nome no contexto e localStorage
  function storeName(username: string) {
    setName(username);
    localStorage.setItem("name", username);
  }

  // Recupera o nome do usuário, ou do localStorage se não estiver no contexto
  function getName(): string | null {
    if (name !== null) {
      return name;
    } else {
      const userData = localStorage.getItem("USER");
      if (userData) {
        const user = JSON.parse(userData);
        setName(user.name);
        return user.name;
      }
      return null; // Caso não haja dados no localStorage
    }
  }

  // Recupera a foto de perfil do usuário, ou do localStorage se não estiver no contexto
  function getPhotoProfile(): string | null {
    if (profileImage !== null) {
      return profileImage;
    } else {
      const userData = localStorage.getItem("USER");
      if (userData) {
        const user = JSON.parse(userData);
        setProfileImage(user.photoProfile);
        return user.photoProfile;
      }
      return null; // Caso não haja dados no localStorage
    }
  }

  // Armazena a foto de perfil no contexto e localStorage
  function storePhotoProfile(photo: string) {
    setProfileImage(photo);
    const userData = localStorage.getItem("USER");
    if (userData) {
      const user = JSON.parse(userData);
      localStorage.setItem(
        "USER",
        JSON.stringify({ ...user, photoProfile: photo })
      );
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signed,
        markAsSigned,
        markAsNotSigned,
        role,
        storeRole,
        getRole,
        name,
        storeName,
        getName,
        getPhotoProfile,
        storePhotoProfile,
        reloadLocalStorage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
