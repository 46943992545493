import { AxiosError } from "axios";
import ISkillFilter from "../interfaces/ISkillFilter";
import Axios from "../shared/axios";

class SkillsLinkedinService { 
  
  async getSkillsL(): Promise<any> {
    return Axios.get("students-lessons/skills")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  

  
  async getSkills(): Promise<any> {
    return Axios.get("students-lessons/skills")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  
}

const skillsLinkedinService = new SkillsLinkedinService();
export default skillsLinkedinService;
