//import "bootstrap/dist/css/bootstrap.min.css";
import { InputAdornment, OutlinedInput, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import SubmitForm from "../Login/SubmitForm";
import usersService from "../../services/UsersService";

// Aqui existe um tipo(IValuesForm), o objeto que vai ser enviado para o back.
//Por convenção deverá ter sempre o I maisculo quando fizer uma interface.
interface IValuesForm {
  currentPassword: string;
  newPassword: string;
  confPassword: string;
  hash: string;
}

function ChangePassword() {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [hash, setHash] = useState(searchParams.get("hash"));

  const [type, setType] = useState<"password" | "text">("password");
  const [confPasswordType, setConfPasswordType] = useState<"password" | "text">(
    "password"
  );
  const [newPasswordType, setNewPasswordType] = useState<"password" | "text">(
    "password"
  );

  //abaixo é a validação do formik, com preenchimento de valores pelo usuario.
  // na linha 58 (as IValuesForm) estaos dizendo que esse objeto é do tipo initialValues, se o valor declarado é numerico"number", deverá ser preenchido com
  //numero, se o valor declarado for uma "string", deverá ser com aspas vazia.
  // O initialValues indica os valores ou string, que o que o usuario verá quando abrir a pagina.
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confPassword: "",
    } as IValuesForm,

    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Digite a senha atual"),
      newPassword: Yup.string().required("Digite uma nova senha"),
      confPassword: Yup.string()
        .required("Digite a senha novamente")
        .oneOf([Yup.ref("newPassword")], "Senhas devem ser iguais"),
    }),

    //quando for enviado o formulario, irá imprimir os valores do formulario.
    onSubmit: (values) => {
     
      // o create é uma função e essa variavel 'values' possue valores do formulario, essa variavel 'values' possue os
      //valores das linhas 48 a 53.
      create(values);
    },
  });

  //const create é uma função chamada, quando se clik no botão para salvar
  // Na variavel values pode estar recebendo 'any' (aceita quarquer coisa ou valor), mas nesse caso aqui, essa variavel so pode aceitar,
  //o tipo IValuesForm.
  // Observar que quando no codar, o VS oferece o preenchimento do valor da variavel.

  const create = (values: IValuesForm) => {
    let data = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    };

    usersService
      .changePassword(data.currentPassword, data.newPassword)
      .then((response) => {
        setLoading(false);
        //redirecionar para cursos
        swal({
          title: "Senha alterada com sucesso!",
          text: "Você será redirecionado à página anterior",
          icon: "success",
        }).then((value: any) => {
          navigate(-1);
          setLoading(false);
        });
      })
      .catch((error) => {
        
        swal({
          title: "Erro",
          text: error.response?.data?.errorMessage,
          icon: "error",
        });
      });
  };

  return (
    
    <div className="container1 bg">
      
      <div
        className={
          !isSubmitSuccess ? "signin signin_wrapper" : "signin signin_success"
        }
      >
        
        {isSubmitSuccess ? (

          <SubmitForm />
        ) : (
          // este form é uma tag no HTML.
          // onSubmit é um evento que fica esperando uma ação do usuário e chama o {formik.handleSubmit}
          // e quando declaramos o formik, se define o que vai acontecer no submit
          <form onSubmit={formik.handleSubmit}>
            <div className="title textCenter">Alterar senha atual</div>
            <br />
            <div className="lbl_bold">Digite sua senha atual</div>
            <OutlinedInput
              required
              name={"currentPassword"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      if (type === "text") {
                        setType("password");
                      } else {
                        setType("text");
                      }
                    }}
                    edge="end"
                  >
                    {type === "text" ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              type={type}
              placeholder="Senha atual"
              className="textField"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.currentPassword}
            />
            {formik.touched.currentPassword && formik.errors.currentPassword ? (
              <div className="error_msg">{formik.errors.currentPassword}</div>
            ) : null}
            <br />
            <br />
            <div className="lbl_bold">Digite uma nova senha</div>
            <OutlinedInput
              required
              name={"newPassword"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      if (newPasswordType === "text") {
                        setNewPasswordType("password");
                      } else {
                        setNewPasswordType("text");
                      }
                    }}
                    edge="end"
                  >
                    {newPasswordType === "text" ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              type={newPasswordType}
              placeholder="Nova senha"
              className="textField"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newPassword}
            />
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <div className="error_msg">{formik.errors.newPassword}</div>
            ) : null}
            <div className="space"></div>
            <div className="lbl_bold">Confirme a nova senha</div>

            <OutlinedInput
              required
              name={"confPassword"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      if (confPasswordType === "text") {
                        setConfPasswordType("password");
                      } else {
                        setConfPasswordType("text");
                      }
                    }}
                    edge="end"
                  >
                    {confPasswordType === "text" ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              type={confPasswordType}
              placeholder="Confirmar nova senha"
              className="textField"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confPassword}
            />
            {formik.touched.confPassword && formik.errors.confPassword ? (
              <div className="error_msg">{formik.errors.confPassword}</div>
            ) : null}
            <div className="space"></div>

            <button className="btn_secondary full" type="submit">
              Alterar Senha
            </button>
            <div className="space"></div>

            <div className="central-button">
              <button className="btn_tertiary" onClick={() => navigate(-1)}>
                Cancelar
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default ChangePassword;
