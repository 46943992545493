import { AxiosError } from "axios";
import ISkillFilter from "../interfaces/ISkillFilter";
import Axios from "../shared/axios";

class StudentsService {
  async getByUser(id: number): Promise<any> {
    return Axios.get("/students/find/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async getAllByCompany(): Promise<any> {
    return Axios.get("/students/find-by-company")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  async getCount(id: number): Promise<any> {
    return Axios.get("students/find-lesson-count/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async getSkills(): Promise<any> {
    return Axios.get("students-lessons/skills")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }


    //retorna a métrica do aluno
  async getMetrics(): Promise<any> {
    return Axios.get("students/metrics")
      .then((result) => {
        return Promise.resolve(result.data); // Retorna apenas os dados da resposta
      })
      .catch((error) => {
        return Promise.reject(error); // Propaga o erro
      });
  }
  
  
  
  async getSkillsByStudent(studentId: number): Promise<any> {
    return Axios.get("students-lessons/skills-by-student/" + studentId)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getCompaniesLessonsSkills(): Promise<any> {
    return Axios.get("students-lessons/skills-by-company")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getCountStudents(): Promise<any> {
    return Axios.get(`/students/count`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }
  async getCountLesson(): Promise<any> {
    return Axios.get(`lessons/all-lessons`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async getAllBySkills(skillsList: ISkillFilter[]): Promise<any> {
    const data = {
      skills: skillsList,
    };
    return Axios.post("students/filter-skills", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async searchStudent(value: string): Promise<any> {
    return Axios.get("students/search?value=" + value)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async addCompany(id: number): Promise<any> {
    return Axios.patch("students/add-company/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async createLinkedin(linkedinAdress: string): Promise<any> {
    try {
      const data = { linkedinAdress }; // Enviando como objeto
      const result = await Axios.post("students/add-linkedin/", data);
      return result.data; // Retorna os dados diretamente
    } catch (error: any) {
      // Indica ao TypeScript que 'error' é do tipo 'any'
      // Tratamento de erro
      if (error.response) {
        // Resposta do servidor com um código de erro
        return Promise.reject(error.response.data);
      } else {
        // Outros erros
        return Promise.reject({
          message: "Erro ao enviar dados para o servidor",
        });
      }
    }
  }

  async createYoutubeUrl(youtubeAdress: string): Promise<any> {
    try {
      const data = { youtubeAdress }; // Enviando como objeto
      const result = await Axios.post("students/add-link-youtube/", data);
      return result.data; // Retorna os dados diretamente
    } catch (error: any) {
      // Indica ao TypeScript que 'error' é do tipo 'any'
      // Tratamento de erro
      if (error.response) {
        // Resposta do servidor com um código de erro
        return Promise.reject(error.response.data);
      } else {
        // Outros erros
        return Promise.reject({
          message: "Erro ao enviar dados para o servidor",
        });
      }
    }
  }

  // async uploadPhotoProfile(file: File): Promise<any> {
  //   try {
  //     const formData = new FormData();
  //     formData.append("file", file);

  //     let token = localStorage.getItem("TOKEN");
  //     if (!token) {
  //       return Promise.reject({
  //         message: "TOKEN NÃO ENCONTRADO",
  //       });
  //     }

  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //     }


  //     const result = await Axios.put("students/photo-profile/upload/", formData, { headers });
  //     return result.data;
  //   } catch (error: any) {
  //     if (error.response) {
  //       return Promise.reject(error.response.data);
  //     } else {
  //       return Promise.reject({
  //         message: "Erro ao enviar dados para o servidor",
  //       });
  //     }
  //   }
  // }

}

const studentsService = new StudentsService();
export default studentsService;
