import { ResultDto } from "../interfaces/IResult";
import IValuesForm from "../interfaces/IValuesForm";
import Axios from "../shared/axios";

class PasswordResetService {
  async create(data: any) {
    let token = localStorage.getItem("TOKEN");
    return Axios.post("password-resets/send-reset-password-email", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async updatePassword(data: IValuesForm): Promise<ResultDto> {
    return Axios.post("password-resets/change-password", data)
        .then((result) => {
            return {
                status: true,
                message: "Senha alterada com sucesso!"
            } as ResultDto; // Mensagem de sucesso
        })
        .catch((error) => {
            return {
                status: false,
                message: "Erro ao alterar a senha: " + error.message
            } as ResultDto; // Mensagem de erro
        });
}
}

const passwordResetService = new PasswordResetService();
export default passwordResetService;
